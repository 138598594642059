import moment from 'moment';
import PropTypes from 'prop-types';
import { List, Searchbar } from 'framework7-react';
import React, { useEffect, useState, useRef } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ArrowUp } from 'Icons';
import { mockFunction } from 'Helpers';
import { Filter, Refresh } from 'Icons';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { DATE_FORMAT, PAGE_NAMES } from 'Constants';
import { SearchAndSelect, TextField } from 'Components';

import './style.scss';
import { filters } from '../utils/FiltersConfig';

const handleCheckboxInput = ({
  activeQuickFilters = {},
  filter = {},
  getItemsFunction = mockFunction,
  isActive = false,
  meta = {},
  setActiveQuickFilters = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const updatedFilters = { ...activeQuickFilters };
  if (isActive) {
    delete updatedFilters[filter.name];
  } else {
    updatedFilters[filter.name] = true;
  }
  const data = {
    ...updatedFilters,
    page: meta.page
  };

  setActiveQuickFilters(updatedFilters);
  updateTransientProps({ filters: updatedFilters }); //to be able to send the filters to pagination
  getItemsFunction(data);
};

const renderQuickFilters = (
  {
    activeQuickFilters = {},
    filtersType = '',
    getItemsFunction = mockFunction,
    isSelected = false,
    meta = {},
    setActiveQuickFilters = mockFunction,
    setIsSelected = mockFunction,
    updateTransientProps = mockFunction
  },
  filter = {}
) => {
  const selected = isSelected.name === filter.name;
  const baseFilterName = filter.name.replace(/(_start_date|_end_date)$/, '');
  const isActive = Object.keys(activeQuickFilters).some(key => key === filter.name || key.startsWith(baseFilterName));
  const isCheckbox = filter.type === 'checkbox';

  const onClickType = isCheckbox
    ? handleCheckboxInput.bind(null, {
        activeQuickFilters,
        filter,
        getItemsFunction,
        isActive,
        meta,
        setActiveQuickFilters,
        updateTransientProps
      })
    : setIsSelected.bind(null, selected ? {} : filter);

  return (
    <RowView
      key={filter.name}
      width={'auto'}
      height={24}
      paddingLeft={10}
      paddingRight={isCheckbox ? 10 : 4}
      gap={2}
      backgroundColor={selected ? colors.lightBlue : colors.lightGrey}
      borderRadius={24}
      onClick={onClickType}>
      <span className={`filter-label ${selected || isActive ? 'active' : ''}`}>
        {I18n.t(`filters:${filtersType}.${filter.name}`)}
      </span>
      {!isCheckbox && <ArrowUp className={selected ? 'rotate180' : 'rotate0'} />}
    </RowView>
  );
};

const InputType = ({
  activeQuickFilters = {},
  clearKey = null,
  getItemsFunction = mockFunction,
  isSelected = false,
  meta = {},
  searchValue = '',
  selectedOptions = [],
  setActiveQuickFilters = mockFunction,
  setSearchValue = mockFunction,
  setSelectedOptions = mockFunction,
  transient = {},
  updateTransientProps = mockFunction
}) => {
  const { filters = {} } = transient;
  const searchedValue = searchValue[isSelected.name] || '';
  const debounceTimeout = useRef(null);

  const triggerSearch = (filterName, newValue) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const trimmedValue = newValue.trim();
      const updatedFilters = { ...activeQuickFilters };

      if (trimmedValue.length >= 3 || trimmedValue.length === 0) {
        if (trimmedValue) {
          updatedFilters[filterName] = trimmedValue;
        } else {
          delete updatedFilters[filterName];
        }

        const data = {
          ...updatedFilters,
          page: meta.page
        };

        setActiveQuickFilters(updatedFilters);
        updateTransientProps({ filters: updatedFilters, selectedRows: [] });
        getItemsFunction(data);
      }
    }, 500);
  };

  const handleInputChange = (e, fieldType = '') => {
    const newValue = e.target.value;
    const filterName = isSelected.name;

    if (isSelected.type === 'search') {
      setSearchValue(prevValues => ({
        ...prevValues,
        [filterName]: newValue
      }));

      if (newValue.trim().length >= 3 || newValue.trim().length === 0) {
        triggerSearch(filterName, newValue);
      }
    } else if (isSelected.type === 'date') {
      const updatedFilterKey = `${filterName}_${fieldType}_date`;

      setSearchValue(prevValues => ({
        ...prevValues,
        [updatedFilterKey]: newValue
      }));

      const startDate = fieldType === 'start' ? newValue : searchValue[`${filterName}_start_date`] || '';
      const endDate = fieldType === 'end' ? newValue : searchValue[`${filterName}_end_date`] || '';

      const updatedFilters = {
        ...activeQuickFilters,
        [`${filterName}_start_date`]: startDate,
        [`${filterName}_end_date`]: endDate
      };

      setActiveQuickFilters(updatedFilters);
      updateTransientProps({ filters: updatedFilters, selectedRows: [] });
    }
  };

  const handleMultipleSelectChange = selectedValues => {
    const filterName = isSelected.name;
    const updatedFilters = { ...activeQuickFilters };

    if (selectedValues.length > 0) {
      updatedFilters[filterName] = selectedValues;
    } else {
      delete updatedFilters[filterName];
    }

    setSelectedOptions(selectedValues);
    setActiveQuickFilters(updatedFilters);
    updateTransientProps({ filters: updatedFilters, selectedRows: [] });

    getItemsFunction(updatedFilters);
  };

  const handleSingleSelectChange = selectedValue => {
    const filterName = isSelected.name;
    const value = selectedValue[isSelected.selectProperty ? isSelected.selectProperty : isSelected.displayProperty];

    const updatedFilters = {
      ...activeQuickFilters,
      [filterName]: value
    };

    const updatedSearchedValues = {
      ...activeQuickFilters,
      [filterName]: selectedValue[isSelected?.displayProperty]
    };

    getItemsFunction(updatedFilters);
    setActiveQuickFilters(updatedFilters);
    setSearchValue(updatedSearchedValues);
    updateTransientProps({ filters: updatedFilters, selectedRows: [] });
  };

  return (
    <>
      {isSelected.type === 'single' && (
        <div className="w-full">
          <SearchAndSelect
            displayProperty={isSelected.displayProperty}
            fieldName={isSelected.name}
            onClear={clearFilter.bind(null, {
              activeQuickFilters,
              filterName: isSelected.name,
              getItemsFunction,
              setActiveQuickFilters,
              setSearchValue,
              updateTransientProps
            })}
            label={I18n.t('general:search')}
            onChange={handleSingleSelectChange}
            options={transient[isSelected.optionsKey]}
            selectedProperty={isSelected.selectProperty}
            updateTransientProps={updateTransientProps}
            value={searchedValue}
            clearKey={clearKey}
          />
        </div>
      )}

      {isSelected.type === 'multiple' && (
        <div className="w-full">
          <SearchAndSelect
            displayProperty={isSelected.displayProperty}
            fieldName={isSelected.name}
            label={I18n.t('general:search')}
            multiple={true}
            onChange={handleMultipleSelectChange}
            options={transient[isSelected.optionsKey]}
            selectedOptions={selectedOptions}
            transient={transient}
            updateTransientProps={updateTransientProps}
            clearKey={clearKey}
          />
        </div>
      )}

      {isSelected.type === 'search' && (
        <Searchbar
          disableButton={false}
          clearButton={false}
          placeholder={I18n.t('general:search')}
          value={searchedValue}
          onChange={handleInputChange}
        />
      )}

      {isSelected.type === 'date' && (
        <List id="date" className="w-full">
          <RowView gap={16}>
            <TextField
              label={I18n.t('filters:general.start_date')}
              type="date"
              max={
                searchValue[`${isSelected.name}_end_date`]
                  ? searchValue[`${isSelected.name}_end_date`]
                  : moment().format(DATE_FORMAT.INTERNAL)
              }
              onChange={e => handleInputChange(e, 'start')}
              value={filters[`${isSelected.name}_start_date`] || ''}
            />
            <TextField
              label={I18n.t('filters:general.end_date')}
              type="date"
              min={searchValue[`${isSelected.name}_start_date`] || ''}
              max={moment().format(DATE_FORMAT.INTERNAL)}
              onChange={e => handleInputChange(e, 'end')}
              value={filters[`${isSelected.name}_end_date`] || ''}
            />
          </RowView>
        </List>
      )}
    </>
  );
};

const clearFilter = ({
  activeQuickFilters = {},
  getItemsFunction = mockFunction,
  setActiveQuickFilters = mockFunction,
  setSearchValue = mockFunction,
  updateTransientProps = mockFunction,
  filterName = ''
}) => {
  const updatedFilters = { ...activeQuickFilters };
  delete updatedFilters[filterName];

  setActiveQuickFilters(updatedFilters);
  updateTransientProps({ filters: updatedFilters, selectedRows: [], [filterName]: '' });
  getItemsFunction(updatedFilters);
  setSearchValue(prevValues => ({
    ...prevValues,
    [filterName]: ''
  }));
};

const resetFilters = ({
  activeQuickFilters = {},
  getItemsFunction = mockFunction,
  setActiveQuickFilters = mockFunction,
  setClearKey = mockFunction,
  setSearchValue = mockFunction,
  setSelectedOptions = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const activeFiltersKeys = Object.keys(activeQuickFilters);
  activeFiltersKeys.forEach(key => {
    const value = Array.isArray(activeQuickFilters[key]) ? [] : '';
    updateTransientProps({ [key]: value });
  });

  setActiveQuickFilters({});
  setClearKey(moment());
  updateTransientProps({ filters: {}, selectedRows: [] });
  getItemsFunction({});
  setSelectedOptions([]);
  setSearchValue('');
};

const navigateToAdvancedFilters = ({ filtersType = '', updateTransientProps = mockFunction }) => {
  updateTransientProps({ filtersType });
  NavigationService.navigate({ name: PAGE_NAMES.ADVANCED_FILTERS });
};

const QuickFilters = ({
  filtersType = '',
  getItemsFunction = mockFunction,
  meta = {},
  quickFilters = {},
  role = '',
  transient = {},
  updateTransientProps = mockFunction,
  updateUserProps = mockFunction,
  view = ''
}) => {
  const [clearKey, setClearKey] = useState(null);
  const [isSelected, setIsSelected] = useState({});
  const [searchValue, setSearchValue] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeQuickFilters, setActiveQuickFilters] = useState({});

  useEffect(() => {
    const filters = quickFilters[filtersType] || {};
    const hasDefaultValue = Object.keys(filters).some(filterName =>
      filters[filterName].some(filter => filter.defaultDate || filter.defaultActive)
    );

    if (!hasDefaultValue) {
      return;
    }

    const initialFilters = Object.keys(filters).reduce(
      (acc, filterName) => {
        const filterArray = filters[filterName];

        filterArray.forEach(filter => {
          if (filter.type === 'date' && filter.defaultDate) {
            const startDateKey = `${filter.name}_start_date`;
            const endDateKey = `${filter.name}_end_date`;

            acc.activeQuickFilters[startDateKey] = filter.defaultDate;
            acc.activeQuickFilters[endDateKey] = filter.defaultDate;

            acc.searchValue[startDateKey] = filter.defaultDate;
            acc.searchValue[endDateKey] = filter.defaultDate;
          }

          if (filter.type === 'checkbox' && filter.defaultActive) {
            acc.activeQuickFilters[filter.name] = true;
            acc.searchValue[filter.name] = true;
          }
        });

        return acc;
      },
      { activeQuickFilters: {}, searchValue: {} }
    );

    setActiveQuickFilters(initialFilters.activeQuickFilters);
    setSearchValue(initialFilters.searchValue);
    getItemsFunction(initialFilters.activeQuickFilters);

    updateTransientProps({ filters: initialFilters.activeQuickFilters });
  }, [filtersType, quickFilters, updateTransientProps]);

  useEffect(() => {
    setIsSelected({});
  }, [quickFilters]);

  const transformedData = (filterType = '') => {
    const filters = quickFilters[filterType];
    if (filters && typeof filters === 'object') {
      return Object.values(filters).flat();
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (transient.filters && Object.keys(transient.filters).length > 0) {
      setActiveQuickFilters(transient.filters);
    }
  }, [transient.filters]);

  useEffect(() => {
    const transformedFilters = transformedData(filtersType).map(filter => filter.name);
    const filteredActiveQuickFilters = Object.fromEntries(
      Object.entries(activeQuickFilters).filter(([key]) => transformedFilters.includes(key))
    );

    if (Object.keys(filteredActiveQuickFilters).length !== Object.keys(activeQuickFilters).length) {
      setActiveQuickFilters(filteredActiveQuickFilters);
      getItemsFunction(filteredActiveQuickFilters);
    }
  }, [quickFilters, filtersType]);

  useEffect(() => {
    const defaultFilters = Object.keys(filters[filtersType]).reduce((acc, category) => {
      const shouldBeDisplayedIn = filters[filtersType][category].filter(filter => {
        const shouldDisplay = !filter.displayInView || filter.displayInView?.includes(view);

        return shouldDisplay;
      });

      const shouldBeDisplayedFor = shouldBeDisplayedIn.filter(filter => {
        const shouldDisplay = !filter.displayForRole || filter.displayForRole?.includes(role);

        return shouldDisplay;
      });

      const uniqueFilters = new Set([...shouldBeDisplayedFor, ...shouldBeDisplayedIn]);

      const allFilters = Array.from(uniqueFilters);
      const categoryFilters = allFilters.filter(filter => filter.default);

      if (categoryFilters.length > 0) {
        acc[category] = categoryFilters;
      }

      return acc;
    }, {});

    const adminFilters = {
      [filtersType]: defaultFilters
    };

    updateUserProps({ adminFilters });
  }, [filters, filtersType]);

  return (
    <ColumnView paddingTop={30} id={'filters'}>
      <RowView gap={8} height={24} width={'100%'} paddingLeft={20} alignItems={'center'} justifyContent={'flex-start'}>
        <RowView
          gap={6}
          height={24}
          width={'auto'}
          borderRadius={24}
          paddingHorizontal={6}
          backgroundColor={colors.lightGrey}
          onClick={navigateToAdvancedFilters.bind(null, { quickFilters, filtersType, updateTransientProps })}>
          {<Filter />}
          {Object.keys(activeQuickFilters)?.length > 0 && (
            <div className="active-filters">{Object.keys(activeQuickFilters)?.length}</div>
          )}
        </RowView>

        <RowView
          width={'auto'}
          height={24}
          paddingHorizontal={6}
          backgroundColor={colors.lightGrey}
          borderRadius={24}
          {...(Object.keys(activeQuickFilters)?.length && {
            onClick: resetFilters.bind(null, {
              activeQuickFilters,
              clearKey,
              getItemsFunction,
              setActiveQuickFilters,
              setClearKey,
              setSearchValue,
              setSelectedOptions,
              updateTransientProps
            })
          })}>
          {<Refresh />}
        </RowView>

        <div className="filters-container">
          {transformedData(filtersType)?.map(
            renderQuickFilters.bind(null, {
              meta,
              isSelected,
              filtersType,
              setIsSelected,
              getItemsFunction,
              activeQuickFilters,
              updateTransientProps,
              setActiveQuickFilters
            })
          )}
        </div>
      </RowView>

      {!!Object.values(isSelected)?.length && (
        <RowView width={'100%'} paddingHorizontal={16} paddingTop={16}>
          <InputType
            activeQuickFilters={activeQuickFilters}
            clearFilter={clearFilter}
            clearKey={clearKey}
            getItemsFunction={getItemsFunction}
            isSelected={isSelected}
            meta={meta}
            searchValue={searchValue}
            selectedOptions={selectedOptions}
            setActiveQuickFilters={setActiveQuickFilters}
            setSearchValue={setSearchValue}
            setSelectedOptions={setSelectedOptions}
            transient={transient}
            updateTransientProps={updateTransientProps}
          />
        </RowView>
      )}
    </ColumnView>
  );
};

QuickFilters.propTypes = {
  filtersType: PropTypes.string,
  getItemsFunction: PropTypes.func,
  meta: PropTypes.object,
  quickFilters: PropTypes.object,
  role: PropTypes.string,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateUserProps: PropTypes.func,
  view: PropTypes.string
};

export default QuickFilters;
