const shouldHideToolbar = currentRouteName => {
  switch (currentRouteName) {
    case 'Birthday':
    case 'BrokenOrUnauthorized':
    case 'Carousel':
    case 'Finish':
    case 'FullName':
    case 'Login':
    case 'PasswordUpdate':
    case 'RequestPasswordConfirmation':
    case 'ResetPassword':
    case 'SignUp':
    case 'TermsAndConditions':
    case 'ThankYou':
    case 'VersionUpdate':
      return true;
    default:
      return false;
  }
};

export { shouldHideToolbar };
