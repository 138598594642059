// Color palette
const rgbColors = {
  black: '0,0,0', //#000000
  blue: '33, 150, 243', //#2196f3
  lightBlue: '219,238,251', //#DBEEFB
  error: '236, 60, 98', //'#ec3c62'
  darkGrey: '192,192,192', //'#c0c0c0'
  lightGrey: '246,246,246', //'#f6f6f6'
  primary: '70,150,236', //#4696ec
  success: '98, 156, 68', //#629c44
  standard: '255, 255, 255', //'#ffffff'
  white: '255, 255, 255' //'#ffffff'
};

export const colors = {
  black: `rgb(${rgbColors.black})`,
  blue: `rgb(${rgbColors.blue})`,
  lightBlue: `rgb(${rgbColors.lightBlue})`,
  error: `rgb(${rgbColors.error})`,
  darkGrey: `rgb(${rgbColors.darkGrey})`,
  lightGrey: `rgb(${rgbColors.lightGrey})`,
  primary: `rgb(${rgbColors.primary})`,
  success: `rgb(${rgbColors.success})`,
  standard: `rgb(${rgbColors.standard})`,
  white: `rgb(${rgbColors.white})`
};

export const f7Colors = {
  red: 'red', //'#ff3b30',
  green: 'green', //'#4cd964',
  blue: 'blue', //'#2196f3',
  pink: 'pink', //'#ff2d55',
  yellow: 'yellow', // '#ffcc00',
  orange: 'orange', //'#ff9500',
  purple: 'purple', //'#9c27b0',
  deeppurple: 'deeppurple', //'#673ab7',
  lightblue: 'lightblue', //'#5ac8fa',
  teal: 'teal', //'#009688',
  lime: 'lime', //'#cddc39',
  deeporange: 'deeporange', //'#ff6b22',
  gray: 'gray', //'#8e8e93',
  white: 'white', //'#ffffff',
  black: 'black', //'#000000',
  multi: 'multi'
};

export const alphaColor = (color = 'primary', opacity = '1') => `rgba(${rgbColors[color]},${opacity})`;
