import React from 'react';

const CheckMarkCircle = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
      <path fill="#D9D9D9" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#1C1B1F"
        //eslint-disable-next-line no-useless-escape, max-len
        d="m10.58 16.254 6.724-6.723-1.054-1.054-5.67 5.67-2.85-2.85-1.053 1.053 3.904 3.904Zm1.422 5.246a9.255 9.255 0 0 1-3.705-.748 9.596 9.596 0 0 1-3.017-2.03 9.591 9.591 0 0 1-2.032-3.016 9.245 9.245 0 0 1-.748-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 0 1 2.03-3.017 9.592 9.592 0 0 1 3.016-2.032 9.245 9.245 0 0 1 3.704-.748c1.314 0 2.55.25 3.705.748a9.596 9.596 0 0 1 3.018 2.03 9.592 9.592 0 0 1 2.03 3.016 9.247 9.247 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.705a9.596 9.596 0 0 1-2.03 3.018 9.592 9.592 0 0 1-3.016 2.03 9.247 9.247 0 0 1-3.704.749ZM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z"
      />
    </g>
  </svg>
);

export default CheckMarkCircle;
