import React from 'react';

const Refresh = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      //eslint-disable-next-line no-useless-escape, max-len
      d="M9.09375 14.0438C11.775 13.3375 13.75 10.9 13.75 8C13.75 4.55 10.975 1.75 7.5 1.75C3.33125 1.75 1.25 5.225 1.25 5.225M1.25 5.225V2.375M1.25 5.225H2.50625H4.025"
      stroke="#444444"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 8C1.25 11.45 4.05 14.25 7.5 14.25"
      stroke="#444444"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 3"
    />
  </svg>
);

export default Refresh;
