import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle, List } from 'framework7-react';

import { I18n } from 'Locales';
import { Warning } from 'Icons';
import { mockFunction } from 'Helpers';
import { ONBOARDING_FIELDS } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { isRejectedField } from 'Pages/session/Profile/utils/helpers';
import TextFieldComponent from 'Pages/session/Profile/ProfileTextField';
import { profileTextFieldsConfig } from 'Pages/session/Profile/utils/config';

import './style.scss';
import { RejectionReasonModal } from '../Modals';

const areEqual = (prevProps, nextProps) =>
  prevProps.isOffline === nextProps.isOffline &&
  Object.keys(prevProps.transient).every(key => prevProps.transient[key] === nextProps.transient[key]);

const renderBirthCertificateTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig.identity.birthCertificate.map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent key={index} config={config} section={sectionConfig} transient={transient} {...otherProps} />
    ) : null
  );

const renderOfficialDocumentsTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig.identity.officialDocuments.map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent key={index} config={config} section={sectionConfig} transient={transient} {...otherProps} />
    ) : null
  );

const IdentityTab = ({
  isOffline,
  onboarding_profile,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  user
}) => {
  const { showIdentityRejectionModal } = transient;

  const rejectedFields = user?.rejected_fields || [];
  const rejectedIdentityArray = rejectedFields.filter(field => ONBOARDING_FIELDS.IDENTITY.includes(field));

  const { rejected_sections: rejectedSections, accepted_sections: acceptedSections } = onboarding_profile;

  const sections = {
    generalDetails: {
      readOnly:
        user.soft_deactivated || (acceptedSections?.by_mc_admin?.general_details && user.isApplicant) || isOffline,
      rejectedReason: rejectedSections?.general_details?.reason
    }
  };

  return (
    <>
      <ColumnView id={'identity-tab'}>
        <RowView justifyContent={'flex-start'}>
          <BlockTitle className="mb-10 text-md text-dark-gray">
            {I18n.t('profile:identityTab.birthCertificate')}
          </BlockTitle>
        </RowView>

        {!!rejectedIdentityArray.length && (
          <RowView
            justifyContent={'flex-start'}
            paddingHorizontal={16}
            onClick={updateTransientProps.bind(null, { showIdentityRejectionModal: true })}>
            <div className="rejection-wrapper">
              <Warning />
              <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
            </div>
          </RowView>
        )}
        <RowView justifyContent={'flex-start'}>
          <List noHairlines className="w-full mt-8">
            {'email' in transient &&
              renderBirthCertificateTextFields('birthCertificate', transient, user, {
                updateTransientProps,
                updateTransientPropWithValidations,
                updateUser,
                user,
                isReadOnly: sections.generalDetails.readOnly,
                isRejectedField: field => isRejectedField(rejectedFields, field)
              })}
          </List>
        </RowView>
        <RowView justifyContent={'flex-start'}>
          <BlockTitle className="mb-16 text-md text-dark-gray">
            {I18n.t('profile:identityTab.officialDocuments')}
          </BlockTitle>
        </RowView>

        <RowView>
          <List noHairlines className="w-full mt-8">
            {(transient?.first_name || transient?.email) &&
              renderOfficialDocumentsTextFields('officialDocuments', transient, user, {
                updateTransientProps,
                updateTransientPropWithValidations,
                updateUser,
                user,
                isReadOnly: sections.generalDetails.readOnly,
                isRejectedField: field => isRejectedField(rejectedFields, field)
              })}
          </List>
        </RowView>
      </ColumnView>

      <RejectionReasonModal
        isOpen={showIdentityRejectionModal}
        onClose={updateTransientProps.bind(null, { showIdentityRejectionModal: false })}
        rejectedFields={{ fields: rejectedIdentityArray }}
        rejectedReason={sections.generalDetails?.rejectedReason}
        fieldConfig={[
          profileTextFieldsConfig.identity.birthCertificate,
          profileTextFieldsConfig.identity.officialDocuments
        ]}
      />
    </>
  );
};

IdentityTab.propTypes = {
  handleOnChangeWithValidations: PropTypes.func,
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

IdentityTab.defaultProps = {
  handleOnChangeWithValidations: mockFunction,
  isOffline: false,
  onboarding_profile: {},
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  user: {}
};

export default React.memo(IdentityTab, areEqual);
