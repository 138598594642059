const transformData = (data = [], userRole = '') => {
  const role = userRole === 'Admin' ? 'by_admin' : 'by_mc_admin';
  const moreDetailsKeys = ['info_message', 'state'];

  return data.map((item = {}) => {
    const acceptedSections = {
      field_name: 'accepted_sections',
      value:
        item.accepted_sections && item.accepted_sections[role]
          ? Object.values(item.accepted_sections[role]).filter(value => value === false).length
          : 0
    };

    const campaignName = {
      field_name: 'campaign_name',
      value: item?.campaignNames?.join(', ')
    };

    const badgeNumbers = {
      field_name: 'badge_number',
      value: item?.badgeNumbers?.join(', ')
    };

    const documentsWaitingForApproval = {
      field_name: 'documents_waiting_for_approval_count',
      value: item.documents_waiting_for_approval_count !== undefined ? item.documents_waiting_for_approval_count : 0
    };

    const more_details = moreDetailsKeys.reduce((acc, key) => {
      if (key === 'info_message') {
        acc.push({
          value: item[key] !== undefined ? item[key] : null,
          field_name: key
        });
        acc.push(campaignName);
        acc.push(badgeNumbers);
      } else {
        acc.push({
          value: item[key] !== undefined ? item[key] : null,
          field_name: key
        });
      }
      return acc;
    }, []);

    return {
      id: item.id,
      name: item.user_name,
      badge_number: item?.badge?.number,
      email: item.profile_fields.email,
      ...item,
      more_details: [...more_details, acceptedSections, documentsWaitingForApproval]
    };
  });
};

export { transformData };
