import React from 'react';

const Filter = props => (
  <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Filter">
      <path
        id="Vector"
        // eslint-disable-next-line
        d="M13.35 4.91176H1.65C1.47761 4.91176 1.31228 4.8374 1.19038 4.70502C1.06848 4.57264 1 4.39309 1 4.20588C1 4.01867 1.06848 3.83913 1.19038 3.70675C1.31228 3.57437 1.47761 3.5 1.65 3.5H13.35C13.5224 3.5 13.6877 3.57437 13.8096 3.70675C13.9315 3.83913 14 4.01867 14 4.20588C14 4.39309 13.9315 4.57264 13.8096 4.70502C13.6877 4.8374 13.5224 4.91176 13.35 4.91176ZM11.1833 8.20588H3.81667C3.64428 8.20588 3.47895 8.13151 3.35705 7.99913C3.23515 7.86676 3.16667 7.68721 3.16667 7.5C3.16667 7.31279 3.23515 7.13324 3.35705 7.00087C3.47895 6.86849 3.64428 6.79412 3.81667 6.79412H11.1833C11.3557 6.79412 11.5211 6.86849 11.643 7.00087C11.7649 7.13324 11.8333 7.31279 11.8333 7.5C11.8333 7.68721 11.7649 7.86676 11.643 7.99913C11.5211 8.13151 11.3557 8.20588 11.1833 8.20588ZM8.58333 11.5H6.41667C6.24428 11.5 6.07895 11.4256 5.95705 11.2933C5.83515 11.1609 5.76667 10.9813 5.76667 10.7941C5.76667 10.6069 5.83515 10.4274 5.95705 10.295C6.07895 10.1626 6.24428 10.0882 6.41667 10.0882H8.58333C8.75572 10.0882 8.92105 10.1626 9.04295 10.295C9.16485 10.4274 9.23333 10.6069 9.23333 10.7941C9.23333 10.9813 9.16485 11.1609 9.04295 11.2933C8.92105 11.4256 8.75572 11.5 8.58333 11.5Z"
        fill="#444444"
      />
    </g>
  </svg>
);

export default Filter;
