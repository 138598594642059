import { call, delay, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { validations } from 'Helpers';
import { NavigationService } from 'Services';
import { Campaign, User } from 'Repositories';
import { Types as GrowlTypes } from 'Reducers/growl';
import { Types as CampaignTypes, campaigns as campaignsSelector } from 'Reducers/campaign';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { onboardingUserProfile as adminSelector, Types as AdminTypes } from 'Reducers/admin';
import { Types as ApplicationTypes, isOffline as isOfflineSelector } from 'Reducers/application';
import {
  ACCEPTED_IMAGE_TYPES,
  DOCUMENT_STATES,
  DOCUMENT_TYPES,
  FILE_SIZE_LIMITS,
  SHORT_CODE_COUNTRIES
} from 'Constants';
import {
  pendingAttachments as pendingAttachmentsSelector,
  incompleteAttachments as incompleteAttachmentsSelector,
  Types as UserTypes,
  user as userSelector
} from 'Reducers/user';

// EXPORTED
export const setCampaign = function* ({ campaign, displayGrowl }) {
  const isOffline = yield select(isOfflineSelector);
  const user = yield select(userSelector);

  if (campaign?.id && !isOffline) {
    yield put({
      type: UserTypes.UPDATE_API_MODEL,
      props: { data: { relationships: { campaign: { data: { type: 'campaign', id: campaign?.id } } } } },
      displayGrowl
    });
  }
  if (campaign?.id) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: UserTypes.SET_CAMPAIGN_SUCCESS, userId: user.id },
        fail: { type: Api.API_ERROR }
      },
      promise: Campaign.getCampaign(campaign?.id)
    });
  }
};

export const setCampaignSuccess = function* ({ userId, payload }) {
  yield put({
    type: CampaignTypes.SET_SELECTED_CAMPAIGN,
    userId,
    payload
  });

  yield put({
    type: UserTypes.GET_ATTACHMENTS
  });
};

export const updateUser = function* ({ props, callback, fieldName }) {
  const user = yield select(userSelector);
  const transient = yield select(transientSelector);
  let data = props;
  if (fieldName !== undefined) {
    if (
      !transient[`${fieldName}_has_error`] &&
      (transient[fieldName] !== user[fieldName] ||
        (transient[fieldName]?.short_code && transient[fieldName]?.short_code !== user[fieldName]))
    ) {
      const updatedData = {
        data: {
          attributes: {
            ...transient,
            nationality: transient.nationality?.nationality,
            country_of_birth: transient.country_of_birth?.short_code,
            country_of_citizenship: transient.country_of_citizenship?.short_code
          }
        }
      };
      data = updatedData;
    } else {
      return null;
    }
  }
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.UPDATE_USER_SUCCESS, callback, fetchCountries: props?.data?.attributes?.locale },
      fail: { type: UserTypes.UPDATE_USER_FAIL, callback }
    },
    promise: User.updateUser({ id: user.id, ...data })
  });
};

export const updateUserSuccess = function* ({ payload, callback, fetchCountries = false }) {
  if (callback) yield call(callback);

  const user = yield select(userSelector);

  if (user.locale !== payload.locale) {
    yield put({
      type: ApplicationTypes.UPDATE_PROPS,
      props: {
        locale: payload.locale
      }
    });
  }

  yield put({
    type: UserTypes.SET_USER,
    payload
  });

  if (fetchCountries) {
    yield put({ type: UserTypes.GET_COUNTRIES });
  }
};

export const updateUserFail = function* ({ error, callback }) {
  // we have to reset back to undefined the keys used for change password
  yield put({
    type: UserTypes.SET_USER,
    payload: { current_password: undefined, password: undefined, password_confirmation: undefined }
  });

  let errorMessage;
  if (callback) {
    if (Array.isArray(error?.response?.data?.errors)) {
      errorMessage = error.response.data.errors[0];
    } else {
      errorMessage = error?.response?.data?.message;
    }
  } else {
    if (Array.isArray(error?.response?.data?.errors)) {
      errorMessage = error.response.data.errors.join(', ');
    } else {
      errorMessage = error?.response?.data?.error || I18n.t('growl:generalError.message');
    }
  }

  yield put({
    type: GrowlTypes.ALERT,
    title: callback ? I18n.t('growl:error:generalError.title') : I18n.t('growl:error.updateUser.title'),
    body: errorMessage,
    kind: 'error'
  });
};

export const updateUserAvatar = function* ({ data }) {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('user_id', data.user_id);
  const fileType = data.file.type.split('/').pop().toLowerCase();

  if (data.file.size <= FILE_SIZE_LIMITS.ATTACHMENTS) {
    if (!ACCEPTED_IMAGE_TYPES.includes(fileType)) {
      yield put({
        type: GrowlTypes.ALERT,
        title: I18n.t('growl:error.attachmentType.title'),
        body: I18n.t('growl:error.attachmentType.body', { fileType }),
        kind: 'error'
      });
      return;
    }

    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: UserTypes.UPDATE_USER_AVATAR_SUCCESS },
        fail: { type: UserTypes.UPDATE_USER_AVATAR_FAIL }
      },
      promise: User.updateUserAvatar(formData)
    });

    yield put({
      type: TransientTypes.UPDATE_PROP,
      key: 'updateUserAvatarLoading',
      value: true
    });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.attachmentSize.title'),
      body: I18n.t('growl:error.attachmentSize.body'),
      kind: 'error'
    });
  }
};

export const updateUserAvatarSuccess = function* ({ payload }) {
  const user = yield select(userSelector);

  if (payload?.user_id == user?.id) {
    yield put({
      type: UserTypes.UPDATE_PROP,
      key: 'avatar_url',
      value: payload?.image?.url
    });

    yield put({
      type: ApplicationTypes.SAVE_USER_PUBLIC_INFO,
      email: user.email,
      avatar: payload?.image?.url
    });
  } else {
    const onboardingUserProfile = yield select(adminSelector);

    yield put({
      type: AdminTypes.UPDATE_PROPS,
      props: {
        userOnboardingProfile: {
          ...onboardingUserProfile,
          user: {
            ...onboardingUserProfile?.user,
            avatar_url: payload.image.url
          }
        }
      }
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'updateUserAvatarLoading',
    value: false
  });
};

export const updateUserAvatarFail = function* ({ error }) {
  const errorResposnseData = error?.response?.data;
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error:generalError.title'),
    body:
      errorResposnseData && 'errors' in errorResposnseData
        ? errorResposnseData.errors[0]
        : I18n.t('growl:error:generalError.message'),
    kind: 'error'
  });

  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'updateUserAvatarLoading',
    value: false
  });
};

export const sendFeedback = function* ({ data, callback }) {
  const { feedback_type, feedback_message } = data;
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.SEND_FEEDBACK_SUCCESS, callback },
      fail: { type: UserTypes.SEND_FEEDBACK_FAIL }
    },
    promise: User.sendFeedback(feedback_type, feedback_message)
  });
};

export const sendFeedbackSuccess = function* ({ callback }) {
  if (callback) yield call(callback);
};

export const sendFeedbackFail = function* () {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.feedback.title'),
    body: I18n.t('growl:error.feedback.body'),
    kind: 'error'
  });
};

export const getAttachments = function* ({ attachmentId }) {
  const isOffline = yield select(isOfflineSelector);
  const campaign = yield select(campaignsSelector);
  const user = yield select(userSelector);
  const campaign_id = campaign[user.id]?.selectedCampaign?.id;
  const id = attachmentId ? attachmentId : campaign_id;

  if (!isOffline) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: UserTypes.GET_ATTACHMENTS_SUCCESS },
        fail: { type: Api.API_ERROR }
      },
      promise: User.getAttachments(id)
    });
  }
};

export const getAttachmentsSuccess = function* ({ payload }) {
  const complete_documents = payload.filter(payload =>
    [DOCUMENT_STATES.ACCEPTED, DOCUMENT_STATES.SIGNED, DOCUMENT_STATES.READ].includes(payload.attachable.state)
  );
  const pending_documents = payload.filter(
    payload =>
      (payload.attachable.state === '' || payload.attachable.state === DOCUMENT_STATES.REJECTED) &&
      (payload.attachable.uploaded || payload.attachable.applicant_signed_on)
  );

  const incomplete_documents = payload.filter(
    payload => !(complete_documents?.includes(payload) || pending_documents?.includes(payload))
  );

  const isOptionalOrReadOnly = document =>
    ![DOCUMENT_TYPES.OPTIONAL, DOCUMENT_TYPES.READ_ONLY].includes(document.attachable.document_type);

  const isMandatoryOrTimeLeashed = document =>
    [DOCUMENT_TYPES.MANDATORY, DOCUMENT_TYPES.MC_ADMIN_TIME_LEASHED].includes(document.attachable.document_type);

  const needs_attention_documents = incomplete_documents.filter(isOptionalOrReadOnly);
  const needed_for_check_in = needs_attention_documents.filter(isMandatoryOrTimeLeashed);

  yield put({
    type: UserTypes.UPDATE_PROPS,
    props: {
      complete_documents,
      incomplete_documents,
      needed_for_check_in,
      needs_attention_documents,
      pending_documents
    }
  });
};

export const getPostCodes = function* ({ payload }) {
  const user = yield select(userSelector);
  yield delay(500);
  let promise;

  if (payload && payload?.country) {
    promise = User.getPostCodes(payload.value, payload.country.toLowerCase());
  } else {
    promise = User.getPostCodes(payload, user.country.toLowerCase());
  }

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: TransientTypes.SET_POST_CODES },
      fail: { type: UserTypes.API_ERROR }
    },
    promise
  });
};

export const getUKAddress = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: {
        type: UserTypes.GET_UK_ADDRESS_SUCCESS
      },
      fail: { type: UserTypes.GET_UK_ADDRESS_FAIL }
    },
    promise: User.getUKAddress(data)
  });
};

export const getUKAddressSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'uk_address',
    value: payload
  });
};

export const getUKAddressFail = function* () {
  yield put({
    type: TransientTypes.UPDATE_PROP,
    key: 'address_field_disabled',
    value: false
  });
};

export const bankAccountCheckFR = function* ({ key, data }) {
  yield put({
    type: TransientTypes.UPDATE_PROP_WITH_VALIDATIONS,
    key: key,
    value: data,
    validations: [validations.isRequired, validations.isIBAN]
  });
  const transient = yield select(transientSelector);
  if (!transient.account_number_has_error)
    yield call(validateOnlineBankAccountUser, { account_number: transient.account_number });
};

export const bankAccountCheckUK = function* ({ key, data }) {
  const localValidation = [validations.isRequired];

  if (key === 'account_number') {
    localValidation.push(validations.accountNumber);
  }
  if (key === 'bank_sort_code') {
    localValidation.push(validations.sortCode);
  }
  yield put({
    type: TransientTypes.UPDATE_PROP_WITH_VALIDATIONS,
    key: key,
    value: data,
    validations: localValidation
  });
  const transient = yield select(transientSelector);
  if (
    !transient.account_number_has_error &&
    !transient.bank_sort_code_has_error &&
    transient.account_number &&
    transient.bank_sort_code
  )
    yield call(validateOnlineBankAccountUser, {
      account_number: transient.account_number,
      bank_sort_code: transient.bank_sort_code,
      country: 'UK'
    });
};

export const validateOnlineBankAccountUser = function* ({ account_number, bank_sort_code, country = '' }) {
  let promise = User.bankAccountCheckFR;
  if (country === SHORT_CODE_COUNTRIES.UK) promise = User.bankAccountCheckUK;
  const formattedAcountNumber = account_number.replace(/\s/g, '');

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.VALIDATE_ONLINE_BANK_ACCOUNT_USER_SUCCESS, country },
      fail: { type: Api.API_ERROR }
    },
    promise: promise(formattedAcountNumber, bank_sort_code)
  });
};

export const validateOnlineBankAccountUserSuccess = function* ({ payload, country }) {
  if (country === 'UK') {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: { bank_name: payload.bank, error: payload.error }
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: { bank_name: payload.bank, bic: payload.bic, errors: payload.error_messages }
    });
  }
};

export const uploadAttachments = function* ({ data }) {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('id', data.id);

  if (data.file.size <= FILE_SIZE_LIMITS.ATTACHMENTS) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: UserTypes.UPLOAD_ATTACHMENTS_SUCCESS },
        fail: { type: UserTypes.UPLOAD_ATTACHMENTS_FAIL }
      },
      promise: User.uploadAttachments(formData)
    });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: { loadingUploadAttachment: data.id }
    });
  } else {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.attachmentSize.title'),
      body: I18n.t('growl:error.attachmentSize.body'),
      kind: 'error'
    });
  }
};

export const uploadAttachmentsSuccess = function* ({ payload }) {
  yield put({ type: UserTypes.SET_NEW_ATTACHMENTS_LIST, payload });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { loadingUploadAttachment: '' }
  });
};

export const uploadAttachmentsFail = function* ({ error }) {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error:generalError.title'),
    body: error?.response?.data?.errors[0],
    kind: 'error'
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { loadingUploadAttachment: '' }
  });
};

export const deleteAttachment = function* ({ payload }) {
  const attachments = yield select(pendingAttachmentsSelector);
  const attachment = attachments.filter(attachment => attachment.id === payload.id)[0];

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.DELETE_ATTACHMENT_SUCCESS, attachment },
      fail: { type: Api.API_ERROR }
    },
    promise: User.deleteAttachment(payload.id)
  });
};

export const deleteAttachmentSuccess = function* ({ attachment }) {
  const pendingAttachments = yield select(pendingAttachmentsSelector);
  const incompleteAttachments = yield select(incompleteAttachmentsSelector);
  const newPendingDocumentsList = pendingAttachments.filter(document => document.id !== attachment.id);
  const newIncompleteDocumentsList = [...incompleteAttachments, attachment];

  yield put({
    type: UserTypes.UPDATE_PROPS,
    props: {
      pending_documents: newPendingDocumentsList,
      incomplete_documents: newIncompleteDocumentsList
    }
  });

  yield put({
    type: UserTypes.GET_ATTACHMENTS
  });
};

export const readAttachment = function* ({ payload }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      fail: { type: Api.API_ERROR }
    },
    promise: User.readAttachment(payload)
  });
};

export const getCountries = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.GET_COUNTRIES_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: User.getCountries()
  });
};

export const getCountriesSuccess = function* ({ payload }) {
  yield put({
    type: UserTypes.UPDATE_PROP,
    key: 'countries',
    value: payload
  });
};

export const getOnboardingProfiles = function* ({ id, callback }) {
  const isOffline = yield select(isOfflineSelector);
  if (!isOffline) {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: UserTypes.GET_ONBOARDING_PROFILES_SUCCESS, callback },
        fail: { type: Api.API_ERROR }
      },
      promise: User.getOnboardingProfiles(id)
    });
  } else {
    if (callback) yield call(callback);
  }
};

export const getOnboardingProfilesSuccess = function* ({ payload, callback }) {
  yield put({
    type: UserTypes.UPDATE_PROP,
    key: 'onboarding_profile',
    value: payload[0]
  });
  if (callback) yield call(callback);
};

export const getUser = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.GET_USER_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: User.getUser(id)
  });
};

export const getUserSuccess = function* ({ payload: user }) {
  yield put({
    type: UserTypes.SET_USER,
    payload: user
  });
};

export const getRoles = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.GET_ROLES_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: User.getRoles()
  });
};

export const getRolesSuccess = function* ({ payload }) {
  const user = yield select(userSelector);
  const roles = payload;
  const userRole = roles?.filter(item => item.id === user.role.id);
  const isApplicant = userRole[0]?.name === 'Applicant';

  yield put({
    type: UserTypes.UPDATE_PROPS,
    props: { roles, isApplicant }
  });
};

export const requestChangeUserPassword = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.REQUEST_CHANGE_USER_PASSWORD_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: User.requestChangeUserPassword(data)
  });
};

export const requestChangeUserPasswordSuccess = function* () {
  yield call(NavigationService.navigate, {
    name: 'ResetPasswordConfirmation'
  });
};

export const resetPassword = function* ({ payload }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: UserTypes.RESET_PASSWORD_SUCCESS },
      fail: { type: UserTypes.RESET_PASSWORD_FAIL }
    },
    promise: User.resetPassword(payload)
  });
};

export const resetPasswordSuccess = function* () {
  yield put({ type: TransientTypes.RESET });
  //clear URL and navigate to Login
  window.location.replace('/');
};

export const resetPasswordFail = function* ({ error }) {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error:generalError.title'),
    body: error?.response?.data?.errors[0],
    kind: 'error'
  });
};
