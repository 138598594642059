import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import Config from 'Config';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { ALERT_COLORS, ALERT_TYPES } from 'Constants';
import { AlertCircle, CheckMarkCircle, Exit } from 'Icons';

import './style.scss';

const Banner = ({
  content = '',
  onClose = mockFunction,
  show = false,
  timer = Config.GROWL_AUTOHIDE,
  title = '',
  type = ''
}) => {
  const backgroundColor = ALERT_COLORS[type?.toUpperCase()] || ALERT_COLORS.INFO;
  const iconType = backgroundColor === ALERT_COLORS.SUCCESS ? <CheckMarkCircle /> : <AlertCircle />;

  useEffect(() => {
    if (timer && show) {
      const timerId = setTimeout(() => {
        onClose();
      }, timer);

      return () => clearTimeout(timerId);
    }
  }, [timer, show]);

  return (
    show && (
      <RowView
        id={'banner'}
        backgroundColor={backgroundColor}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        width={'100%'}
        gap={12}
        paddingVertical={10}
        paddingHorizontal={20}>
        <div>{iconType}</div>

        <ColumnView alignItems={'flex-start'}>
          <div className="banner-title">{title}</div>
          <div className="banner-content">{content}</div>
        </ColumnView>

        {type === ALERT_TYPES.SUCCESS && (
          <div onClick={onClose}>
            <Exit />
          </div>
        )}
      </RowView>
    )
  );
};

Banner.propTypes = {
  content: PropTypes.string,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  timer: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Banner;
