import { connect } from 'react-redux';

import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import RejectSection from './view';

export default connect(
  state => ({
    transient: state.transient,
    userOnboardingProfile: state.admin.userOnboardingProfile
  }),
  {
    updateTransientProps: transientActions.updateProps,
    updateOnboardingUserSection: adminActions.updateOnboardingUserSection,
    updateOnboardingUserProfile: adminActions.updateOnboardingUserProfile,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(RejectSection);
