import React from 'react';
import moment from 'moment';

import { I18n } from 'Locales';
import { COUNTRIES, CREATE_USER_OPTIONS } from 'Constants';
import { capitalize, ibanMaskConfig, mockFunction } from 'Helpers';

const isDisabled = (config, readOnly) => (config ? config : readOnly);

const getFormattedValue = (config, value, transient) => {
  if (value === undefined) return '';
  if (config.iBanMaskValue) return ibanMaskConfig(value);
  if (config.fieldName === 'marketing_companies') {
    return renderMultipleValues(config.selectTranslationKey, value);
  }

  const translatedValue = config.translateValue
    ? I18n.t(`profile:contactTab.${transient?.[config.fieldName]}`) || ''
    : value;

  return config.capitalizeValue ? capitalize(translatedValue) || '' : translatedValue;
};
const getOptionLabel = (isTranslationKeyEmpty, selectTranslationKey, option) =>
  isTranslationKeyEmpty ? option : I18n.t(`${selectTranslationKey}.${option}`);

const renderSelectOptions = (userOptions, selectTranslationKey, fieldName, value) => {
  const firstLetterUpperCase = `select${fieldName?.charAt(0).toUpperCase()}${fieldName?.slice(1)}`;
  const isTranslationKeyEmpty = selectTranslationKey === '';

  const defaultOption = value
    ? getOptionLabel(isTranslationKeyEmpty, selectTranslationKey, value[0])
    : I18n.t(`admin:createUserFields.${firstLetterUpperCase}`);
  const options = userOptions?.map((option, index) => (
    <option key={option.id || index} value={option}>
      {getOptionLabel(isTranslationKeyEmpty, selectTranslationKey, option)}
    </option>
  ));

  if (!value) {
    options?.unshift(
      <option key="default" value="" disabled selected>
        {defaultOption}
      </option>
    );
  }

  return options;
};

const renderMultipleValues = (selectTranslationKey = '', value = []) =>
  value
    ?.map(value => {
      const displayedValue = selectTranslationKey ? I18n.t(`${selectTranslationKey}.${value}`) : value;
      return displayedValue;
    })
    .join(', ');

const isFieldPopulated = (field, transient) => {
  const value = transient[field];
  return value && !transient[`${field}_has_error`] && (!Array.isArray(value) || value.length > 0);
};

const areRequiredFieldsPopulated = (displayedFields, transient) => {
  const requiredFields = displayedFields
    .filter(field => field.validations.includes('isRequired'))
    .map(field => field.fieldName);

  return requiredFields.every(field => isFieldPopulated(field, transient));
};

const handleCreateUser = ({
  createUser = mockFunction,
  createUserFields = [],
  displayedFields = [],
  transient = {},
  user = {}
}) => {
  const displayedConfigs = createUserFields?.map(config =>
    displayedFields.filter(displayedField => displayedField.fieldName === config.fieldName).map(() => config)
  );
  const fieldNames = displayedConfigs?.flatMap(config => config?.map(field => field?.fieldName));
  const populatedFields = Object.keys(transient)
    .filter(key => fieldNames.includes(key) && transient[key] !== '')
    .reduce((obj, key) => {
      obj[key] = transient[key];
      return obj;
    }, {});

  Object.keys(populatedFields).forEach(key => {
    if (key.startsWith('other_')) {
      const baseFieldName = key.replace('other_', '');
      delete populatedFields[baseFieldName];
    }
  });

  if (!populatedFields.country) {
    populatedFields.country = user.country;
  }

  const roleId = user?.roles.filter(role => role.name === populatedFields.role)[0]?.id;
  const officeId = user.office.id;
  const selectedCountry = { shortCode: populatedFields.country, country: COUNTRIES[populatedFields.country] };

  const formattedUserData = {
    ...populatedFields,
    role_id: roleId,
    office_id: officeId,
    parent_id: user.id,
    selected_country: selectedCountry,
    date_of_assigment: moment().format('DD/MM/YYYY')
  };
  // Role is not needed after role_id is extracted
  delete formattedUserData.role;

  createUser(formattedUserData);
};

const getDisplayedFields = (transient, otherProps) => {
  const { user, createUserFields } = otherProps;
  const fieldsConfig = createUserFields;

  return fieldsConfig?.filter(config => {
    const isCountrySpecificFieldValid =
      !config?.countrySpecificField || config.countrySpecificField.includes(transient?.country);
    const isRoleSpecificFieldValid = !config?.roleSpecificField || config.roleSpecificField.includes(transient.role);

    const shouldDisplay = config.displayIfCanSimpleCreateUsers !== false;

    if (user.can_simple_create_users && !shouldDisplay) {
      return false;
    }

    return (
      (isCountrySpecificFieldValid && isRoleSpecificFieldValid) ||
      (!config?.countrySpecificField && !config?.roleSpecificField)
    );
  });
};

const renderSelectField = (selectOptionsMapping, configFieldName) => {
  const { options, selectTranslationKey, fieldName, value } = selectOptionsMapping[configFieldName] || {};
  return renderSelectOptions(options, selectTranslationKey, fieldName, value);
};

const resetKeys = () =>
  CREATE_USER_OPTIONS.NEEDS_TO_RESET.reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});

export {
  areRequiredFieldsPopulated,
  getDisplayedFields,
  getFormattedValue,
  handleCreateUser,
  isDisabled,
  renderMultipleValues,
  renderSelectField,
  renderSelectOptions,
  resetKeys
};
