import React from 'react';

import PropTypes from 'prop-types';

const Documents = ({ fillColor = '#1C1B1F' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="none">
    <path
      fill={fillColor}
      // eslint-disable-next-line max-len
      d="M3 19.5c-.699 0-1.29-.242-1.774-.726A2.413 2.413 0 0 1 .5 17v-1.077c0-.497.177-.923.531-1.277a1.74 1.74 0 0 1 1.277-.53H3.5V2.307c0-.497.177-.923.531-1.277A1.74 1.74 0 0 1 5.308.5h10.384c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V17c0 .699-.242 1.29-.726 1.774A2.413 2.413 0 0 1 15 19.5H3ZM15 18c.283 0 .52-.096.713-.288A.968.968 0 0 0 16 17V2.308a.3.3 0 0 0-.086-.221.3.3 0 0 0-.222-.087H5.308a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v11.808h7.192c.497 0 .923.177 1.277.531.354.354.531.78.531 1.277V17c0 .283.096.52.287.712.192.192.43.288.713.288ZM6.942 6.692a.725.725 0 0 1-.534-.215.726.726 0 0 1-.216-.535c0-.213.072-.39.216-.534a.726.726 0 0 1 .534-.216h7.116c.212 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215H6.942Zm0 2.885a.725.725 0 0 1-.534-.216.726.726 0 0 1-.216-.534c0-.213.072-.391.216-.535a.726.726 0 0 1 .534-.215h7.116c.212 0 .39.072.534.216a.726.726 0 0 1 .216.534c0 .213-.072.39-.216.535a.726.726 0 0 1-.534.215H6.942ZM3 18h9.5v-2.077a.3.3 0 0 0-.086-.221.3.3 0 0 0-.222-.087H2.308a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.221V17c0 .283.096.52.288.712.191.192.429.288.712.288Zm0 0H2h10.5H3Z"
    />
  </svg>
);

export default Documents;

Documents.propTypes = {
  fillColor: PropTypes.string
};
