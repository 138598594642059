import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Page, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { User } from 'Repositories';
import { RowView } from 'Containers';
import { AppHeader } from 'Components';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { debounce, mockFunction } from 'Helpers';

const handleSearch = debounce(
  (country, getPostCodes, updateTransientProps, searchSuccess, filteredAddressByPostCodes, e) => {
    const { value } = e.target;
    const trimmedValue = value.trim();

    if (trimmedValue.length < 4) {
      return;
    }

    if (
      searchSuccess !== value.substring(0, searchSuccess.length) ||
      searchSuccess.length === 0 ||
      filteredAddressByPostCodes?.length === 0
    ) {
      getPostCodes({ value, country });
      updateTransientProps({ searchSuccess: value });
    }
    updateTransientProps({ search: value });
  },
  500
);

const handleUKSearch = debounce((getUKAddress, updateTransientProps, e) => {
  const text = e.target.value.trim();
  if (text.length === 0) {
    updateTransientProps({
      uk_address: []
    });
  } else {
    const data = { text };
    getUKAddress(data);
  }
}, 500);

const filteredAddressByFullName = (filteredAddressByPostCodes, search = '', searchSuccess = '') =>
  filteredAddressByPostCodes?.filter(element =>
    element.full_address?.includes(search.substring(searchSuccess.length, search.length))
  );

const onClickHandler = ({
  address_line1,
  address_line2,
  address_line3,
  city,
  postcode,
  updateOnboardingUserProfile,
  updateTransientProps,
  userId
}) => {
  const city_has_error = city?.length === 0;
  const postcode_has_error = postcode?.length === 0;
  const address_line1_has_error = address_line1?.length === 0;

  updateTransientProps({
    address_line1_has_error,
    address_line1,
    address_line2,
    address_line3,
    city_has_error,
    city,
    filteredAddressByPostCodes: [],
    postcode_has_error,
    postcode
  });

  const newUser = {
    data: {
      attributes: {
        address_line1,
        address_line2,
        address_line3,
        city,
        postcode
      }
    }
  };
  updateOnboardingUserProfile(newUser, userId);

  NavigationService.goBack({ name: PAGE_NAMES.ONBOARDING_PROFILE });
};

const onClickUKHandler = ({ updateTransientProps, updateOnboardingUserProfile, userId, item }) => {
  User.getUKAddress(item).then(cb => {
    if (cb.status === 200) {
      const address_uk_find = cb.data[0];
      const city_has_error = address_uk_find.town === 0;
      const postcode_has_error = address_uk_find.postcode === 0;
      const address_line1_has_error = address_uk_find.address_line1 === 0;

      const newUser = {
        data: {
          attributes: {
            city: address_uk_find.town,
            postcode: address_uk_find.postcode,
            address_line1: address_uk_find.address_line1,
            address_line2: address_uk_find.address_line2,
            address_line3: address_uk_find.address_line3
          }
        }
      };
      updateOnboardingUserProfile(newUser, userId);

      updateTransientProps({
        address_line1_has_error,
        city_has_error,
        postcode_has_error,
        uk_address: []
      });

      NavigationService.goBack({ name: PAGE_NAMES.ONBOARDING_PROFILE });
    }
  });
};

const SearchAddressList = ({
  filteredAddressByPostCodes,
  getPostCodes,
  getUKAddress,
  search,
  searchSuccess,
  uk_address,
  updateOnboardingUserProfile,
  updateTransientProps,
  userOnboardingProfile
}) => {
  const userId = userOnboardingProfile?.user?.id;
  const country = userOnboardingProfile?.user?.country;

  return (
    <Page id="profile-postcodes">
      <AppHeader
        user
        hideAvatar
        goBack
        title={I18n.t('profile:contactTab.addressFinder')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.PROFILE })}
      />
      <RowView>
        <Searchbar
          onChange={
            country === 'UK'
              ? handleUKSearch.bind(null, getUKAddress, updateTransientProps)
              : handleSearch.bind(
                  null,
                  country,
                  getPostCodes,
                  updateTransientProps,
                  searchSuccess,
                  filteredAddressByPostCodes
                )
          }
          onClickClear={
            country === 'UK'
              ? updateTransientProps.bind(null, { uk_address: [] })
              : updateTransientProps.bind(null, { search: '' })
          }
          placeholder={I18n.t(`profile:contactTab.${country === 'UK' ? 'searchUK' : 'search'}`)}
        />
      </RowView>
      {uk_address && !!uk_address.length ? (
        <List className="mt-0">
          {uk_address.map((item, index) => {
            const { id, type, full_address } = item;
            const address_params = {
              id,
              type,
              text: full_address.split('(More Addresses)')[0].trim()
            };

            return (
              <ListItem
                link
                key={index}
                onClick={
                  item.type === 'Address'
                    ? onClickUKHandler.bind(null, { updateTransientProps, updateOnboardingUserProfile, userId, item })
                    : getUKAddress.bind(null, address_params)
                }>
                {item.full_address}
              </ListItem>
            );
          })}
        </List>
      ) : (
        <List className="mt-0">
          {filteredAddressByFullName(filteredAddressByPostCodes, search, searchSuccess)?.map(
            (addressDetails, index) => (
              <ListItem
                link
                key={index}
                onClick={onClickHandler.bind(null, {
                  address_line1: addressDetails.address_line1,
                  address_line2: addressDetails.address_line2,
                  address_line3: addressDetails.address_line3,
                  city: addressDetails.town,
                  postcode: addressDetails.postcode,
                  userId,
                  updateOnboardingUserProfile,
                  updateTransientProps
                })}>
                {addressDetails.full_address}
              </ListItem>
            )
          )}
        </List>
      )}
    </Page>
  );
};

SearchAddressList.propTypes = {
  country: PropTypes.string,
  filteredAddressByPostCodes: PropTypes.array,
  getPostCodes: PropTypes.func,
  getUKAddress: PropTypes.func,
  search: PropTypes.string,
  searchSuccess: PropTypes.string,
  uk_address: PropTypes.array,
  updateTransientProps: PropTypes.func,
  userOnboardingProfile: PropTypes.object,
  updateOnboardingUserProfile: PropTypes.func
};

SearchAddressList.defaultProps = {
  country: '',
  filteredAddressByPostCodes: [],
  getPostCodes: mockFunction,
  getUKAddress: mockFunction,
  search: '',
  searchSuccess: '',
  uk_address: [],
  updateTransientProps: mockFunction,
  userOnboardingProfile: {},
  updateOnboardingUserProfile: mockFunction
};

export default SearchAddressList;
