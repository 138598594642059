import { connect } from 'react-redux';

import PasswordUpdate from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

export default connect(
  state => ({
    transient: state.transient
  }),
  {
    logout: applicationActions.logout,
    updateUser: userActions.updateApiModel,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(PasswordUpdate);
