import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { DOCUMENT_TYPES } from 'Constants';
import { ColumnView, RowView } from 'Containers';

import './style.scss';
import { renderDocument } from '../../utils/helpers';

const Block = ({
  blocks = [],
  currentPage = '',
  deleteUserAttachment = mockFunction,
  isOffline = false,
  readAttachment = mockFunction,
  removeAttachment = mockFunction,
  role_name = '',
  transient = {},
  updateGrowlProps = mockFunction,
  updateOnboardingProfileDocuments = mockFunction,
  updateTransientProps = mockFunction,
  updateUserDocument = mockFunction,
  userId = ''
}) => {
  const files = useRef([]);

  const formatPageName = pageName => pageName.charAt(0).toUpperCase() + pageName.slice(1).replace(/(\d+)/, ' $1');

  const formattedPageName = formatPageName(currentPage);

  const findBlock = blocks?.find(block => block.name === formattedPageName);
  const onboardingProfileDocuments = findBlock ? findBlock.onboarding_profile_documents : null;

  const renderDocumentSection = (documents, sectionTitle) =>
    !!documents?.length && (
      <>
        <RowView justifyContent={'flex-start'} paddingHorizontal={16}>
          <div className="section-title">{sectionTitle}</div>
        </RowView>
        {documents.map((document, index) =>
          renderDocument({
            deleteUserAttachment,
            document,
            files,
            index,
            isOffline,
            readAttachment,
            removeAttachment,
            role_name,
            transient,
            updateGrowlProps,
            updateOnboardingProfileDocuments,
            updateTransientProps,
            updateUserDocument,
            userId
          })
        )}
      </>
    );

  const documentInfo = Object.keys(DOCUMENT_TYPES).map((key, index) => {
    const sectionTitle = DOCUMENT_TYPES[key];
    const filteredDocuments = onboardingProfileDocuments?.filter(document => document.document_type === sectionTitle);
    return <React.Fragment key={index}>{renderDocumentSection(filteredDocuments, sectionTitle)}</React.Fragment>;
  });

  return (
    <>
      <ColumnView id={'document-info'} paddingVertical={24}>
        <RowView justifyContent={'space-between'} paddingHorizontal={16} paddingBottom={16}>
          <div className="basic-profile-title">{I18n.t(`admin:onboardingProfilePages.${currentPage}`)}</div>
          <div className="accepted-number">{findBlock?.completed_count.slice(1, -1)}</div>
        </RowView>

        {documentInfo}
      </ColumnView>
    </>
  );
};

Block.propTypes = {
  blocks: PropTypes.array,
  currentPage: PropTypes.string,
  deleteUserAttachment: PropTypes.func,
  isOffline: PropTypes.bool,
  readAttachment: PropTypes.func,
  removeAttachment: PropTypes.func,
  role_name: PropTypes.string,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateOnboardingProfileDocuments: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateUserDocument: PropTypes.func,
  userId: PropTypes.string
};

export default Block;
