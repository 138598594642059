import React from 'react';
import PropTypes from 'prop-types';

const Exit = ({ fillColor = '#444444' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18 6 6 18M6 6l12 12" />
  </svg>
);

Exit.propTypes = {
  fillColor: PropTypes.string
};

export default Exit;
