import React from 'react';

const ReviewSection = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
    <path
      fill="#444"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M15.943 1.059a2.835 2.835 0 0 0-4.01 0L6.622 6.37a.625.625 0 0 0-.151.244l-1.563 4.688a.625.625 0 0 0 .79.79l4.688-1.562a.624.624 0 0 0 .245-.151l5.313-5.313a2.835 2.835 0 0 0 0-4.008Zm-3.125.883a1.585 1.585 0 0 1 2.24 2.242L9.85 9.39l-3.363 1.121L7.61 7.15l5.209-5.208ZM8 2.75c.154 0 .306.006.457.016l1.104-1.103a7.5 7.5 0 1 0 5.777 5.776l-1.104 1.105A6.25 6.25 0 1 1 8 2.75Z"
    />
  </svg>
);

export default ReviewSection;
