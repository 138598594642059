import PropTypes from 'prop-types';
import { List } from 'framework7-react';
import React, { useEffect } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { Button, Modal, SearchAndSelect, TextField } from 'Components';

import './style.scss';

const handleSelect = ({ items, updateTransientProps, property }, e) => {
  const selectedIndex = e.target.value;
  const selectedOption = items[selectedIndex];

  updateTransientProps({ [property]: selectedOption.id });
};

const handleChangeOption = (updateTransientProps, key, e) => {
  updateTransientProps({ [key]: e.target.value });
};

const renderOptions = ({ options, type = '', value = '' }) => {
  const selectableOptions = options.map((option, index) => (
    <option key={option.id || index} value={index}>
      {option.name}
    </option>
  ));

  if (type !== '') {
    selectableOptions.unshift(
      <option key="default" value="" disabled selected={true}>
        {value !== '' ? value : I18n.t(`admin:reOnboardModal.${type}`)}
      </option>
    );
  }

  return selectableOptions;
};

const handleSelectSigner = (updateTransientProps, key, e) => {
  updateTransientProps({ [key]: e.target.value });
};

const renderSigners = ({ signers }) => {
  const selectableOptions = signers.map((signer = {}, index) => (
    <option key={index} value={signer?.signer?.id}>
      {signer?.signer?.full_name}
    </option>
  ));

  return selectableOptions;
};

const handleOnClick = ({ bulkCampaignId, bulkIds, releaseBlock, selectedCampaigns, transient }) => {
  const bulkAction = bulkIds.length !== 0 ? 'bulk' : '';

  const campaignId =
    transient.block !== 3
      ? ''
      : selectedCampaigns !== ''
      ? selectedCampaigns
      : bulkCampaignId !== ''
      ? bulkCampaignId
      : transient.userOnboardingProfile.campaign_id;

  releaseBlock(
    {
      onboarding_profile_ids: bulkIds.length !== 0 ? bulkIds : [transient?.userOnboardingProfile?.id],
      campaign_id: campaignId,
      name: `Block ${transient.block}`,
      runtime_variables_group: transient?.selectedOption || '1',
      signer_user_id: transient?.selected_signer
    },
    bulkAction
  );
};

const ReleaseBlock = ({
  bulkIds = [],
  bulkCampaignId = '',
  getCampaignsByClient = mockFunction,
  isOpen = false,
  onClose = mockFunction,
  releaseBlock = mockFunction,
  showSigner = false,
  showOptions = false,
  showCampaign = false,
  showClients = false,
  subtitle = '',
  title = '',
  transient = {},
  updateTransientProps = mockFunction
}) => {
  const optionsValues = [
    { name: I18n.t('admin:options.option1') },
    { name: I18n.t('admin:options.option2') },
    { name: I18n.t('admin:options.option3') }
  ];

  const {
    campaigns = [],
    client_name = '',
    clients = [],
    displayedCampaigns = [],
    releaseBlockProcessing = false,
    selected_client = '',
    selectedCampaigns = [],
    selectedRows = [],
    signers = [],
    userOnboardingProfile = {}
  } = transient;

  const campaignsName = (selectedRows.length > 0 && selectedRows[0].campaigns.map(campaign => campaign.name)) || [];
  const clientName = (selectedRows.length > 0 && selectedRows[0].client?.name) || '';
  const hasCampaigns = userOnboardingProfile?.campaigns?.length > 0 || selectedRows[0]?.campaigns?.length > 0;
  const displayedCampaignsIds = displayedCampaigns?.map(campaign => campaign?.id);

  useEffect(() => {
    if (selected_client !== '') {
      getCampaignsByClient(transient.selected_client);
    }
  }, [selected_client]);

  useEffect(() => {
    if (hasCampaigns) {
      updateTransientProps({ selectedCampaigns: displayedCampaignsIds });
    }
    updateTransientProps({ selected_signer: signers[0]?.signer?.id });
  }, [isOpen]);

  const isDisabled = showCampaign && !selectedCampaigns?.length;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ColumnView id={'release-block-modal'} justifyContent="space-between" height={'100%'}>
        <ColumnView justifyContent="flex-start">
          <RowView>
            <div className="title-modal">{title}</div>
          </RowView>
          <RowView justifyContent={'flex-start'} paddingVertical={16}>
            {subtitle}
          </RowView>

          <List className="w-full">
            {showClients && (
              <TextField
                label={I18n.t('admin:client')}
                disabled={client_name !== '' || clientName !== ''}
                onChange={handleSelect.bind(null, {
                  items: clients,
                  updateTransientProps,
                  property: 'selected_client'
                })}
                type="select">
                {renderOptions({
                  options: clients,
                  type: 'clientPlaceholder',
                  value: client_name !== '' ? client_name : clientName
                })}
              </TextField>
            )}

            {showCampaign && (
              <div className="w-full pb-4">
                {hasCampaigns ? (
                  <RowView
                    justifyContent={'flex-start'}
                    {...(displayedCampaigns?.length && { paddingTop: 4, paddingBottom: 8 })}
                    gap={4}
                    flexWrap={'wrap'}>
                    {displayedCampaigns?.map((option, index) => (
                      <div key={index} className="tag">
                        {option?.name}
                      </div>
                    ))}
                  </RowView>
                ) : (
                  <SearchAndSelect
                    resetKey={selected_client}
                    displayProperty="name"
                    fieldName="selectedCampaigns"
                    label={I18n.t('admin:onboardingProfile.campaign')}
                    multiple={true}
                    options={campaigns?.length > 0 ? campaigns : campaignsName}
                    selectedProperty="id"
                    updateTransientProps={updateTransientProps}
                  />
                )}
              </div>
            )}

            {showOptions && (
              <TextField
                label={I18n.t('admin:selectOption')}
                type="select"
                value={transient.selectedOption || optionsValues[0].name}
                onChange={handleChangeOption.bind(null, updateTransientProps, 'selectedOption')}>
                {renderOptions({ options: optionsValues })}
              </TextField>
            )}
            {showSigner && (
              <TextField
                label={I18n.t('admin:signer')}
                onChange={handleSelectSigner.bind(null, updateTransientProps, 'selected_signer')}
                type="select">
                {renderSigners({ signers })}
              </TextField>
            )}
          </List>
        </ColumnView>

        <ColumnView justifyContent={'flex-end'}>
          <RowView gap={20} justifyContent={'flex-end'} marginTop={'auto'}>
            <Button.OutlineSmall className="mt-8" onClick={onClose}>
              {I18n.t('general:cancel')}
            </Button.OutlineSmall>
            <Button.PrimarySmall
              disabled={isDisabled || releaseBlockProcessing}
              processing={releaseBlockProcessing}
              className="mt-8"
              onClick={handleOnClick.bind(null, {
                bulkCampaignId,
                bulkIds,
                releaseBlock,
                selectedCampaigns,
                transient
              })}>
              {I18n.t('admin:release')}
            </Button.PrimarySmall>
          </RowView>
        </ColumnView>
      </ColumnView>
    </Modal>
  );
};

ReleaseBlock.propTypes = {
  bulkCampaignId: PropTypes.number,
  bulkIds: PropTypes.array,
  getCampaignsByClient: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  releaseBlock: PropTypes.func,
  showCampaign: PropTypes.bool,
  showClients: PropTypes.bool,
  showOptions: PropTypes.bool,
  showSigner: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

export default ReleaseBlock;
