import React from 'react';
import PropTypes from 'prop-types';

const CheckMark = ({ fillColor = '#444444' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill={fillColor}
      // eslint-disable-next-line max-len
      d="m9.55 15.516 8.638-8.639a.741.741 0 0 1 1.07 0 .735.735 0 0 1 .232.535c0 .201-.077.38-.232.535l-9.075 9.075a.867.867 0 0 1-.633.27.867.867 0 0 1-.633-.27l-4.175-4.175a.714.714 0 0 1-.22-.53.75.75 0 0 1 .236-.54.735.735 0 0 1 .534-.232c.201 0 .38.077.535.232l3.723 3.739Z"
    />
  </svg>
);

CheckMark.propTypes = {
  fillColor: PropTypes.string
};

export default CheckMark;
