import PropTypes from 'prop-types';
import { List } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { Button, Modal, TextField } from 'Components';

const handleUpdateBadgeNumber = ({ badgeId, updateBadgeNumber, updatedBadgeNumber, updateTransientProps }) => {
  updateBadgeNumber({ updatedBadgeNumber, badgeId });
  updateTransientProps({ showUpdateBadgeModal: false });
};
const UpdateBadgeNumberModal = ({
  badgeId = '',
  badgeNumber = '',
  showUpdateBadgeModal = false,
  updateBadgeNumber = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const [updatedBadgeNumber, setUpdatedBadgeNumber] = useState('');
  const disabled = updatedBadgeNumber?.length === 0 || updatedBadgeNumber === badgeNumber;

  useEffect(() => {
    setUpdatedBadgeNumber(badgeNumber);
  }, [badgeNumber]);

  return (
    <Modal
      open={showUpdateBadgeModal}
      onClose={updateTransientProps.bind(null, { showUpdateBadgeModal: false })}
      closeByBackdropClick={true}>
      <ColumnView width={'100'} gap={24}>
        <RowView>
          <div className="text-md font-bold">{I18n.t('admin:onboardingProfile.changeBadgeNumber')}</div>
        </RowView>

        <List className="w-full">
          <TextField
            label={I18n.t('admin:onboardingProfile.badgeNumber')}
            value={updatedBadgeNumber}
            onChange={e => setUpdatedBadgeNumber(e.target.value)}
          />
        </List>

        <ColumnView justifyContent={'flex-end'}>
          <RowView gap={12} justifyContent={'flex-end'}>
            <Button.OutlineSmall onClick={updateTransientProps.bind(null, { showUpdateBadgeModal: false })}>
              {I18n.t('general:cancel')}
            </Button.OutlineSmall>
            <Button.PrimarySmall
              disabled={disabled}
              onClick={handleUpdateBadgeNumber.bind(null, {
                badgeId,
                updateBadgeNumber,
                updatedBadgeNumber,
                updateTransientProps
              })}>
              {I18n.t('general:ok')}
            </Button.PrimarySmall>
          </RowView>
        </ColumnView>
      </ColumnView>
    </Modal>
  );
};

UpdateBadgeNumberModal.propTypes = {
  badgeId: PropTypes.string,
  badgeNumber: PropTypes.string,
  showUpdateBadgeModal: PropTypes.bool,
  updateBadgeNumber: PropTypes.func,
  updateTransientProps: PropTypes.func
};

export default UpdateBadgeNumberModal;
