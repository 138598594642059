/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'framework7-react';

import { I18n } from 'Locales';
import { PAGE_NAMES } from 'Constants';
import { Avatar, Bubble } from 'Components';
import { NavigationService } from 'Services';
import { ArrowBack, VerticalDots } from 'Icons';
import { ColumnView, RowView } from 'Containers';
import { isObjEmpty, mockFunction } from 'Helpers';

import './style.scss';

const handleOnClickAvatar = ({ onProfileRedirect, updateApplicationProp }) => {
  if (onProfileRedirect !== undefined) {
    onProfileRedirect();
  } else {
    NavigationService.navigate({
      name: PAGE_NAMES.PROFILE
    });
  }

  updateApplicationProp('selectedAppBottomBarSection', {
    label: 'Profile',
    pageName: PAGE_NAMES.PROFILE
  });
};

const AppHeader = ({
  actions = [],
  admin,
  banner,
  children,
  childrenPaddingHorizontal,
  goBack,
  handleClick,
  hideAvatar,
  onClickMcPin,
  goBackIcon: GoBackIcon,
  onProfileRedirect,
  showMcPins,
  title,
  updateApplicationProp,
  user
}) => (
  <div className="sticky-top">
    <RowView backgroundColor={'white'} paddingHorizontal={16}>
      <div className="header-container">
        {/* LEFT SIDE */}
        <div className="left">
          {goBack && (
            <RowView justifyContent={'flex-start'} maxWidth={50} width={'auto'}>
              <Link onClick={handleClick}>{GoBackIcon ? <GoBackIcon /> : <ArrowBack />}</Link>
            </RowView>
          )}
        </div>

        {/* CENTER */}
        <div className="center">
          <div className="nav-title">{title}</div>
        </div>

        {/* RIGHT SIDE */}
        <div className="right">
          {user && !isObjEmpty(user) && !hideAvatar && (
            <Avatar
              size={40}
              source={user?.avatar_url}
              user={user}
              userAvatar
              onClick={handleOnClickAvatar.bind(null, { onProfileRedirect, updateApplicationProp })}
            />
          )}
          {user && !isObjEmpty(user) && showMcPins && (
            <div className="mc-pin" onClick={onClickMcPin}>
              {user?.mc_pins?.length > 1
                ? admin?.selectedMcPin === ''
                  ? I18n.t('admin:marketingCompanyAbreviation')
                  : admin?.selectedMcPin
                : user?.mc_pins[0]}
            </div>
          )}
          {actions?.length > 0 && (
            <RowView width={'auto'}>
              <Bubble items={actions} icon={VerticalDots} width={0} justIcon={true} />
            </RowView>
          )}
        </div>
      </div>
    </RowView>

    {banner}

    <ColumnView paddingHorizontal={childrenPaddingHorizontal !== undefined ? childrenPaddingHorizontal : 16}>
      {children}
    </ColumnView>
  </div>
);

AppHeader.propTypes = {
  actions: PropTypes.array,
  children: PropTypes.node,
  childrenPaddingHorizontal: PropTypes.number,
  showMcPins: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  onClickMcPin: PropTypes.func,
  onProfileRedirect: PropTypes.func,
  title: PropTypes.string,
  user: PropTypes.shape({
    avatar_url: PropTypes.string
  })
};

AppHeader.defaultProps = {
  children: null,
  childrenPaddingHorizontal: 16,
  showMcPins: false,
  hideAvatar: false,
  onClickMcPin: mockFunction,
  onProfileRedirect: undefined,
  title: '',
  user: null
};

export default AppHeader;
