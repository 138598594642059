import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';
import { F7, NavigationService } from 'Services';
import { Logout, Offline, Online, Send } from 'Icons';
import { APP_MODES, ICON_COLOR, PAGE_NAMES } from 'Constants';

const renderExitFlowAlert = (mode, logout, updateFlowProps) => {
  const titleKey = mode === APP_MODES.DONOR ? 'flow:confirmDonorExitFlow' : 'flow:confirmExitFlow';
  const title = I18n.t(titleKey);
  const onClick =
    mode === APP_MODES.DONOR
      ? () => logout()
      : () => {
          updateFlowProps({ validatedPages: [] });
          NavigationService.goBack({ name: PAGE_NAMES.CAMPAIGNS });
        };

  F7.dialog
    .create({
      title,
      buttons: [{ text: I18n.t('general:cancel') }, { text: I18n.t('general:ok'), onClick }]
    })
    .open();
};

const FlowBottomBar = ({ isOffline, logout, mode, summaryFlowPage, updateFlowProps }) => {
  const donorMode = mode === APP_MODES.DONOR;
  const NetworkIcon = isOffline ? Offline : Online;
  const handleExitClick = useCallback(() => renderExitFlowAlert(mode, logout, updateFlowProps), [mode, logout]);

  return (
    <>
      <ToolbarF7 position={'bottom'} tabbar noShadow noHairLine scrollable id={'flow-bottom-bar'}>
        <RowView>
          <ColumnView height={74}>
            <Link onClick={handleExitClick}>
              <Logout fillColor={ICON_COLOR.GRAY} />
              <div className="label">{I18n.t('general:exit')}</div>
            </Link>
          </ColumnView>
          {!donorMode && !isOffline && (
            <ColumnView height={74}>
              <Link disable={isOffline} onClick={summaryFlowPage}>
                <Send />
                <div className="label">{I18n.t('general:send')}</div>
              </Link>
            </ColumnView>
          )}
          <ColumnView>
            <NetworkIcon />
            <div className="label"> {I18n.t(`general:${isOffline ? 'offline' : 'online'}`)}</div>
          </ColumnView>
        </RowView>
      </ToolbarF7>
    </>
  );
};

FlowBottomBar.propTypes = {
  isOffline: PropTypes.bool,
  logout: PropTypes.func,
  mode: PropTypes.string,
  summaryFlowPage: PropTypes.func,
  updateFlowProps: PropTypes.func
};

FlowBottomBar.defaultProps = {
  isOffline: false,
  logout: mockFunction,
  mode: '',
  summaryFlowPage: mockFunction,
  updateFlowProps: mockFunction
};

export default FlowBottomBar;
