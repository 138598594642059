import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'framework7-react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { Modal } from 'Components';
import { PwaService } from 'Services';
import { mockFunction } from 'Helpers';
import { DataByteIconLogo } from 'Icons';
import { ColumnView, RowView } from 'Containers';

const handleInstallClick = updateTransientProps => {
  PwaService.showPrompt();
  updateTransientProps({ install_modal: false });
};

const InstallModal = ({ install_modal = false, updateTransientProps = mockFunction }) => (
  <Modal open={install_modal} onClose={updateTransientProps.bind(null, { install_modal: false })}>
    <ColumnView id={'finish'} justifyContent={'space-between'} height={'100%'}>
      <ColumnView gap={8} paddingVertical={8}>
        <RowView paddingHorizontal={8}>
          <RowView justifyContent="center" paddingLeft={22}>
            <div className="text-md font-bold">{I18n.t('general:addToHomeScreen')}</div>
          </RowView>
          <RowView
            justifyContent="flex-end"
            width={16}
            onClick={updateTransientProps.bind(null, { install_modal: false })}>
            <Icon className="justify-end" md={'material:close'} />
          </RowView>
        </RowView>
        <RowView backgroundColor={colors.darkGrey} height={1} />
        <ColumnView gap={8} paddingVertical={8} onClick={handleInstallClick.bind(null, updateTransientProps)}>
          <img src={DataByteIconLogo} />
          <RowView>
            <div className="text-sm font-light subtitle">{I18n.t('general:installDataByte')}</div>
          </RowView>
          <Icon f7={'arrow_down_to_line'} />
        </ColumnView>
      </ColumnView>
    </ColumnView>
  </Modal>
);

InstallModal.propTypes = {
  install_modal: PropTypes.bool,
  updateTransientProps: PropTypes.func
};

export default InstallModal;
