import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { Link } from 'Components';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { ArrowNarrowLeft } from 'Icons';

import './style.scss';

const onNavigate = ({
  currentPageNumber,
  direction,
  filters,
  getItemsFunction,
  numberOfElements,
  totalNumberOfPages,
  updateTransientProps
}) => {
  if (
    (direction === 'back' && currentPageNumber === 1) ||
    (direction === 'next' && currentPageNumber === totalNumberOfPages)
  )
    return;

  const newPageNumber = direction === 'next' ? currentPageNumber + 1 : currentPageNumber - 1;
  getItemsFunction({ ...filters, page: newPageNumber, per_page: numberOfElements });
  updateTransientProps({ selectedRows: [] });
};

const Pagination = ({
  filters = {},
  meta = {},
  getItemsFunction = mockFunction,
  loading = false,
  numberOfElements = 10,
  totalNumberOfPages = '',
  updateTransientProps = mockFunction
}) => {
  const { page = 1 } = meta;
  const currentPageNumber = page;

  return (
    !loading &&
    totalNumberOfPages !== 0 && (
      <div className="w-full">
        <RowView paddingVertical={10} paddingHorizontal={16} backgroundColor={colors.white} width={'100%'}>
          <RowView width={'auto'}>
            <Link
              onClick={onNavigate.bind(null, {
                currentPageNumber,
                direction: 'back',
                filters,
                getItemsFunction,
                numberOfElements,
                totalNumberOfPages,
                updateTransientProps
              })}>
              <ArrowNarrowLeft />
            </Link>
          </RowView>

          <RowView>
            <span className="pagintation">{I18n.t('general:pageOf', { currentPageNumber, totalNumberOfPages })}</span>
          </RowView>

          <RowView width={'auto'}>
            <Link
              onClick={onNavigate.bind(null, {
                currentPageNumber,
                direction: 'next',
                filters,
                getItemsFunction,
                numberOfElements,
                totalNumberOfPages,
                updateTransientProps
              })}>
              <ArrowNarrowLeft className={'rotate180'} />
            </Link>
          </RowView>
        </RowView>
      </div>
    )
  );
};

Pagination.propTypes = {
  filters: PropTypes.object,
  getItemsFunction: PropTypes.func,
  loading: PropTypes.bool,
  meta: PropTypes.object,
  numberOfElements: PropTypes.number,
  totalNumberOfPages: PropTypes.number,
  updateTransientProps: PropTypes.func
};

export default Pagination;
