import React from 'react';

const Plus = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
    <path
      fill="#0D8DE7"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M15.5 10.832h-4.167v4.166a.833.833 0 0 1-1.667 0v-4.166H5.5a.833.833 0 1 1 0-1.667h4.166V4.998a.833.833 0 0 1 1.667 0v4.167H15.5a.833.833 0 1 1 0 1.667Z"
    />
  </svg>
);

export default Plus;
