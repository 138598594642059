import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';

import Pagination from './view';

export default connect(
  state => ({
    ...state.transient,
    meta: state.admin?.onboardings?.meta
  }),
  {
    updateTransientProps: transientActions.updateProps
  }
)(Pagination);
