import React from 'react';
import PropTypes from 'prop-types';
import { Block, List, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { DataByteLogo } from 'Icons';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { AppHeader, Button, StrengthBar, TextField } from 'Components';
import { handleOnChangeWithValidations, mockFunction, validations } from 'Helpers';

import './style.scss';

const updateUserPassword = ({ updateUser, transient, navigateToCampaigns }) => {
  const newUser = {
    data: {
      attributes: {
        current_password: transient.current_password,
        password: transient.password,
        password_confirmation: transient.password_confirmation
      }
    }
  };
  updateUser(newUser, navigateToCampaigns);
};

const navigateToCampaigns = () => {
  NavigationService.navigate({
    name: PAGE_NAMES.CAMPAIGNS
  });
};

const getIconColor = (fieldHasError, hasFieldValue) => (fieldHasError ? 'red' : hasFieldValue ? 'blue' : 'gray');

const PasswordUpdate = ({ logout, transient, updateTransientPropWithValidations, updateUser }) => {
  const disableButton =
    transient.password_has_error ||
    transient.current_password_has_error ||
    transient.password_confirmation_has_error ||
    transient.password !== transient.password_confirmation ||
    !transient.password ||
    !transient.current_password ||
    !transient.password_confirmation;

  const isPasswordMatching = {
    isValid: value => value === transient.password,
    message: I18n.t('validations:passwordDontMatch'),
    messageKey: 'validations:passwordDontMatch'
  };

  return (
    <Page id={'password-update'}>
      <AppHeader hideAvatar title={I18n.t('general:changePassword')} />
      <ColumnView minHeight={'calc(100% - 74px)'} alignItems={'center'}>
        <img src={DataByteLogo} className="pt-24" width={'35%'} height={'35%'} />
        <List form className="w-full">
          <div className="px-16 text-sm mb-4">{I18n.t('general:passwordExpired')}</div>
          <TextField
            label={I18n.t('settings:oldPassword')}
            type="password"
            value={transient?.current_password || ''}
            onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'current_password', [
              validations?.isRequired
            ])}
            errorMessageForce={transient?.current_password_has_error}
            errorMessage={I18n.t(transient?.current_password_error_message_key)}
            icon="material:check"
            iconColor={getIconColor(transient?.current_password_has_error, transient?.current_password)}
          />
          <TextField
            label={I18n.t('settings:newPassword')}
            type="password"
            value={transient?.password || ''}
            onChange={handleOnChangeWithValidations.bind(null, updateTransientPropWithValidations, 'password', [
              validations?.isRequired,
              validations?.passwordValidation
            ])}
            errorMessageForce={transient?.password_has_error}
            errorMessage={I18n.t(transient?.password_error_message_key)}
            icon="material:check"
            iconColor={getIconColor(transient?.password_has_error, transient?.password)}
          />
          <TextField
            label={I18n.t('settings:confirmPassword')}
            type="password"
            value={transient?.password_confirmation || ''}
            onChange={handleOnChangeWithValidations.bind(
              null,
              updateTransientPropWithValidations,
              'password_confirmation',
              [validations?.isRequired, validations?.passwordValidation, isPasswordMatching]
            )}
            icon="material:check"
            iconColor={getIconColor(transient?.password_confirmation_has_error, transient?.password_confirmation)}
            errorMessageForce={transient?.password_confirmation_has_error}
            errorMessage={I18n.t(transient?.password_confirmation_error_message_key)}
          />
          <Block className="mt-8 mb-0">
            <StrengthBar password={transient?.password} />
            <div className={'password-requirement mt-16'}>{I18n.t('settings:passwordRequirement')}</div>
          </Block>
        </List>
        <ColumnView paddingHorizontal={20} paddingBottom={16} marginTop={'auto'} gap={8}>
          <RowView>
            <Button.Primary
              width={'100%'}
              onClick={updateUserPassword.bind(null, {
                updateUser,
                transient,
                navigateToCampaigns
              })}
              disabled={disableButton}>
              {I18n.t('general:changePassword')}
            </Button.Primary>
          </RowView>
          <RowView>
            <Button.Outline width={'100%'} onClick={logout}>
              {I18n.t('general:logout')}
            </Button.Outline>
          </RowView>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

PasswordUpdate.propTypes = {
  logout: PropTypes.func,
  transient: PropTypes.object,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func
};

PasswordUpdate.defaultProps = {
  logout: mockFunction,
  transient: {},
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction
};

export default PasswordUpdate;
