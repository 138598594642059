import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const Activity = ({ userOnboardingProfile = {} }) => (
  <ColumnView id={'activity'} paddingHorizontal={16} paddingVertical={20}>
    <RowView justifyContent={'space-between'} paddingBottom={8} paddingTop={16}>
      <div className="title">{I18n.t('admin:onboardingProfilePages.activity')}</div>
    </RowView>

    <ColumnView gap={12}>
      {userOnboardingProfile?.onboarding_profile_audit_trails?.map((audit, index) => (
        <div className="profile-tab-card" key={index}>
          <div className="audit-row">
            <div className="column-left">
              <div className="bold-text">{audit?.name}</div>
              <div>{audit?.description}</div>
            </div>

            <div className="column-right">
              <div>{moment(audit?.created_at).format('DD/MM/YYYY')}</div>
              <div className="right-align-text">{audit?.author_name}</div>
            </div>
          </div>
        </div>
      ))}
    </ColumnView>
  </ColumnView>
);

Activity.propTypes = {
  userOnboardingProfile: PropTypes.object
};

export default Activity;
