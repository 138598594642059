import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { AlertCircle } from 'Icons';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { AppHeader, Button } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { DOCUMENT_REJECT_REASON, PAGE_NAMES } from 'Constants';

import './style.scss';

const handleRejectSection = ({ currentPage, transient, updateAdminProp, updateOnboardingProfileDocuments }) => {
  const { selectedReason = '' } = transient;

  const data = {
    data: {
      attributes: {
        ...transient.selectedDocument,
        accepted_on: null,
        approved_on: null,
        uploaded: false,
        rejection_reason: selectedReason,
        state: 'rejected'
      },
      id: transient?.selectedDocument?.id
    }
  };

  updateOnboardingProfileDocuments(data, transient?.selectedDocument?.id);
  NavigationService.goBack({ name: PAGE_NAMES.ONBOARDING_PROFILE });
  updateAdminProp('currentPage', currentPage);
};

const RejectDocument = ({
  currentPage,
  transient,
  updateAdminProp,
  updateOnboardingProfileDocuments,
  updateTransientProps
}) => {
  const rejectionReasonDocument = [
    {
      label: I18n.t(`admin:declineDocument.${DOCUMENT_REJECT_REASON.WRONG_DOCUMENT}`),
      value: DOCUMENT_REJECT_REASON.WRONG_DOCUMENT
    },
    {
      label: I18n.t(`admin:declineDocument.${DOCUMENT_REJECT_REASON.OUT_OF_DATE}`),
      value: DOCUMENT_REJECT_REASON.OUT_OF_DATE
    },
    {
      label: I18n.t(`admin:declineDocument.${DOCUMENT_REJECT_REASON.LOW_IMG_QUALITY}`),
      value: DOCUMENT_REJECT_REASON.LOW_IMG_QUALITY
    },
    {
      label: I18n.t(`admin:declineDocument.${DOCUMENT_REJECT_REASON.DATA_MISMATCH}`),
      value: DOCUMENT_REJECT_REASON.DATA_MISMATCH
    }
  ];

  return (
    <Page id="reject-section">
      <AppHeader
        hideAvatar
        goBack
        title={I18n.t('admin:rejectDocument')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.ONBOARDING_PROFILE })}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} marginBottom={8} justifyContent="space-between">
        <div className="w-full">
          <RowView paddingHorizontal={16} paddingVertical={24} alignItems={'flex-start'}>
            <RowView height={'100%'} width={'auto'} paddingBottom={24}>
              <AlertCircle />
            </RowView>
            {I18n.t('admin:rejectDocumentSubtitle')}
          </RowView>

          <List className="fields-list">
            {rejectionReasonDocument.map((item, index) => (
              <ListItem
                key={index}
                radio={true}
                checked={item.value === transient.selectedReason}
                onClick={updateTransientProps.bind(null, { selectedReason: item.value })}>
                {item.label}
              </ListItem>
            ))}
          </List>
        </div>

        <div className="w-full px-16">
          <Button.Primary
            disabled={!transient.selectedReason?.length}
            onClick={handleRejectSection.bind(null, {
              currentPage,
              transient,
              updateAdminProp,
              updateOnboardingProfileDocuments
            })}
            className="line-height-normal h-48">
            {I18n.t('admin:reject')}
          </Button.Primary>
        </div>
      </ColumnView>
    </Page>
  );
};

RejectDocument.propTypes = {
  currentPage: PropTypes.string,
  transient: PropTypes.object,
  updateAdminProp: PropTypes.func,
  updateOnboardingProfileDocuments: PropTypes.func,
  updateTransientProps: PropTypes.func
};

RejectDocument.defaultProps = {
  currentPage: '',
  transient: {},
  updateAdminProp: mockFunction,
  updateOnboardingProfileDocuments: mockFunction,
  updateTransientProps: mockFunction
};

export default RejectDocument;
