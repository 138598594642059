import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import AppHeader from './view';

export default connect(
  state => ({
    admin: state.admin,
    user: state.user,
    search: state.transient.search,
    appTitle: Selectors.selectedCampaignName(state),
    currentRouteName: Selectors.getCurrentRouteName(state),
    flow: {
      pages: Selectors.flowPages(state),
      currentPage: state.flow.currentPage || {},
      currentPageIndex: state.flow.currentPageIndex,
      availablePages: state.flow.availablePages,
      validatedPages: state.flow.validatedPages,
      signature: state.flow.signature
    }
  }),
  {
    updateUserProp: userActions.updateProp,
    updateTransientProps: transientActions.updateProps,
    updateApplicationProp: applicationActions.updateProp
  }
)(AppHeader);
