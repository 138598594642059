import PropTypes from 'prop-types';
import { Checkbox, List } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { CLIENT_TYPE, REGIONS } from 'Constants';
import { SearchAndSelect, TextField } from 'Components';

const handleSelect = ({ callback, items, key, setSelectedCampaign, updateTransientProps }, e) => {
  const selectedIndex = e.target.value;
  const selectedOption = items[selectedIndex];

  updateTransientProps({ [key]: selectedOption.id, [`${key}_name`]: selectedOption.name });
  setSelectedCampaign && setSelectedCampaign(selectedOption);
  callback && callback(selectedOption.id);
};

const handleSelectSigner = (updateTransientProps, key, e) => {
  updateTransientProps({ [key]: e.target.value });
};

const renderOptions = ({ options, type }) => {
  const selectableOptions = options.map((option, index) => (
    <option key={option.id || index} value={index}>
      {option.name}
    </option>
  ));
  selectableOptions.unshift(
    <option key="default" value="" disabled selected={true}>
      {I18n.t(`admin:reOnboardModal.${type}`)}
    </option>
  );

  return selectableOptions;
};

const renderSigners = ({ signers }) => {
  const selectableOptions = signers.map((signer = {}, index) => (
    <option key={index} value={signer?.signer?.id} select={index[0]}>
      {signer?.signer?.full_name}
    </option>
  ));

  return selectableOptions;
};

const Reonboard = ({
  transient = {},
  getReOnboardingCampaigns = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const {
    campaigns = [],
    clients = [],
    full_name = '',
    reuse_existing_badge_number = true,
    selected_campaign_name = '',
    selected_client = '',
    selected_client_name = '',
    signers = [],
    userOnboardingProfile = {}
  } = transient;
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const selectedUserCountry = REGIONS.UK.includes(userOnboardingProfile.user.country);
  const showReuseBadgeCheckbox = selectedUserCountry && selectedCampaign.client_type === CLIENT_TYPE.COMMERCIAL;

  useEffect(() => {
    updateTransientProps({ selected_signer: signers[0]?.signer?.id });
  }, [signers]);

  return (
    <>
      <RowView>
        <div className="title-md font-bold">{I18n.t('admin:reOnboardModal.reOnboardBA')}</div>
      </RowView>

      <List noHairlines className="w-full">
        <TextField
          label={I18n.t('admin:reOnboardModal.client')}
          onChange={handleSelect.bind(null, {
            callback: getReOnboardingCampaigns,
            items: clients,
            key: 'selected_client',
            setSelectedCampaign,
            updateTransientProps
          })}
          type="select">
          {renderOptions({ options: clients, type: 'clientPlaceholder' })}
        </TextField>

        {selected_client && (
          <SearchAndSelect
            displayProperty="name"
            fieldName="selectedCampaigns"
            label={I18n.t('admin:reOnboardModal.campaign')}
            multiple={true}
            options={campaigns}
            resetKey={selected_client}
            selectedProperty="id"
            updateTransientProps={updateTransientProps}
          />
        )}

        <TextField
          label={I18n.t('admin:reOnboardModal.signers')}
          onChange={handleSelectSigner.bind(null, updateTransientProps, 'selected_signer')}
          type="select">
          {renderSigners({ signers })}
        </TextField>

        {showReuseBadgeCheckbox && (
          <RowView justifyContent={'flex-start'}>
            <Checkbox
              checked={reuse_existing_badge_number}
              onChange={updateTransientProps.bind(null, { reuse_existing_badge_number: !reuse_existing_badge_number })}
            />
            <div className="pl-8 pt-2">{I18n.t('admin:reOnboardModal.reuseBadge')}</div>
          </RowView>
        )}
      </List>

      {selected_campaign_name !== '' && selected_client_name !== '' && (
        <div className="names-container">
          <span className="text-sm font-bold mr-4">{full_name}</span>
          <span>
            {I18n.t('admin:reOnboardModal.reonboardClientAndCampaign', {
              campaignName: selected_campaign_name,
              clientName: selected_client_name
            })}
          </span>
        </div>
      )}
    </>
  );
};

Reonboard.propTypes = {
  getReOnboardingCampaigns: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

export default Reonboard;
