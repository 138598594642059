import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';

import { mockFunction, stopPropagation } from 'Helpers';

import './style.scss';

const onBackdropClickHandler = ({ closeByBackdropClick = true, onClose = mockFunction }) => e => {
  if (closeByBackdropClick) {
    onClose(e);
  }
};

const Modal = ({ children = undefined, closeByBackdropClick = true, onClose = mockFunction, open = false }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 10); // Delay to ensure the class is applied after render
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => setIsVisible(false), 500);
      return () => clearTimeout(timer);
    }
  }, [open]);

  if (!isVisible) {
    return null;
  }
  return createPortal(
    <div id="custom-modal">
      <div
        className={`modal-container ${isAnimating ? 'open' : ''}`}
        onClick={onBackdropClickHandler({ closeByBackdropClick, onClose })}>
        <div className={`modal-content ${isAnimating ? 'open' : ''}`} onClick={stopPropagation}>
          {children}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  closeByBackdropClick: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

export default Modal;
