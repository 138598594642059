import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { List, ListItem, Page, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { AppHeader, Button, Fab } from 'Components';

import './style.scss';
import { filters } from '../utils/FiltersConfig';

const groupByCategory = filtersType => filters[filtersType] || {};

const navigateBack = ({ history = '', setSelectedFilters = mockFunction }) => {
  NavigationService.goBack({ name: history[history.length - 1] });
  setSelectedFilters({});
};

const saveFilters = ({
  adminFilters = {},
  filtersType = '',
  history = [],
  navigateBack = mockFunction,
  selectedFilters = [],
  setSelectedFilters = mockFunction,
  updateUserProps = mockFunction
}) => {
  updateUserProps({
    adminFilters: {
      ...adminFilters,
      [filtersType]: selectedFilters[filtersType]
    }
  });
  navigateBack({ history, setSelectedFilters });
};

const handleCheckboxChange = ({
  category = '',
  filter = {},
  filtersType = '',
  selectedFilters = {},
  setSelectedFilters = mockFunction
}) => {
  const newSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
  if (!newSelectedFilters[filtersType]) {
    newSelectedFilters[filtersType] = {};
  }
  if (!newSelectedFilters[filtersType][category]) {
    newSelectedFilters[filtersType][category] = [];
  }
  const isSelected = newSelectedFilters[filtersType][category].some(
    selectedFilter => selectedFilter?.name === filter?.name
  );
  if (isSelected) {
    newSelectedFilters[filtersType][category] = newSelectedFilters[filtersType][category].filter(
      selectedFilter => selectedFilter?.name !== filter?.name
    );
  } else {
    newSelectedFilters[filtersType][category].push(filter);
  }

  setSelectedFilters(newSelectedFilters);
};

const AdvancedFilters = ({
  adminFilters = {},
  filtersType = '',
  history = [],
  role = '',
  view = '',
  updateUserProps = mockFunction
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const groupedFilters = groupByCategory(filtersType);
  const [selectedFilters, setSelectedFilters] = useState(JSON.parse(JSON.stringify(adminFilters)));

  const filteredGroupedFilters = Object.keys(groupedFilters)?.reduce((acc, category) => {
    const filteredFilters = groupedFilters[category]?.filter(filter => {
      const isVisibleForRole = !filter.displayForRole || filter.displayForRole.includes(role);
      const isVisibleForView = !filter.displayInView || filter.displayInView.includes(view);
      const matchesSearch = I18n.t(`filters:${filtersType}.${filter?.name}`)
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase());

      return isVisibleForRole && isVisibleForView && matchesSearch;
    });

    if (filteredFilters?.length > 0) {
      acc[category] = filteredFilters;
    }
    return acc;
  }, {});

  return (
    <Page id="filter-type">
      <AppHeader
        user
        goBack
        hideAvatar
        title={I18n.t('general:filterType')}
        handleClick={navigateBack.bind(null, { history, setSelectedFilters })}
      />

      <RowView paddingHorizontal={10} marginTop={30}>
        <Searchbar
          disableButton={false}
          clearButton={false}
          placeholder={I18n.t('general:searchFilter')}
          value={searchQuery || ''}
          onInput={e => setSearchQuery(e.target.value)}
        />
      </RowView>

      {!Object.values(filteredGroupedFilters)?.length ? (
        <div className="empty-table">{I18n.t('general:noResultsFound')}</div>
      ) : (
        <ColumnView paddingHorizontal={21} paddingBottom={100}>
          {Object.keys(filteredGroupedFilters)?.map(category => (
            <ColumnView key={category}>
              <RowView justifyContent={'flex-start'} paddingTop={15}>
                <div className="category">{I18n.t(`filters:categories.${category}`)}</div>
              </RowView>
              {filteredGroupedFilters[category]?.map(filter => (
                <List key={filter?.name} className="w-full">
                  <ListItem
                    checked={
                      !!selectedFilters[filtersType]?.[category]?.some(
                        selectedFilter => selectedFilter?.name === filter?.name
                      )
                    }
                    checkbox={true}
                    onClick={handleCheckboxChange.bind(null, {
                      category,
                      filter,
                      filtersType,
                      selectedFilters,
                      setSelectedFilters
                    })}>
                    {I18n.t(`filters:${filtersType}.${filter?.name}`)}
                  </ListItem>
                </List>
              ))}
            </ColumnView>
          ))}
        </ColumnView>
      )}

      <Fab className={'w-full px-20'} position={'center-bottom'} slot={'fixed'}>
        <Button.Primary
          width={'100%'}
          onClick={saveFilters.bind(null, {
            adminFilters,
            filtersType,
            history,
            navigateBack,
            selectedFilters,
            setSelectedFilters,
            updateUserProps
          })}>
          {I18n.t('general:save')}
        </Button.Primary>
      </Fab>
    </Page>
  );
};

AdvancedFilters.propTypes = {
  adminFilters: PropTypes.object,
  filtersType: PropTypes.string,
  history: PropTypes.array,
  role: PropTypes.string,
  updateUserProps: PropTypes.func,
  view: PropTypes.string
};

export default AdvancedFilters;
