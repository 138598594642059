import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { mockFunction } from 'Helpers';
import { FIELD_NAMES } from 'Constants';
import { Button, Card } from 'Components';
import { ColumnView, RowView } from 'Containers';

const renderDrawerContent = (item, idx) => {
  const isMessage = item?.field_name === FIELD_NAMES.MESSAGE;
  const needsTranslation = ['state'];
  const value = needsTranslation.includes(item?.field_name)
    ? I18n.t(`admin:onboardingTable.messagesAndStates.${item?.value}`)
    : item?.value;

  return (
    <ColumnView key={idx}>
      {isMessage && <Card className="w-full" description={value} />}

      {!isMessage && (
        <RowView justifyContent={'space-between'} alignItems={'flex-start'}>
          <RowView justifyContent={'flex-start'}>{I18n.t(`admin:onboardingTable.${item?.field_name}`)}</RowView>
          <RowView justifyContent={'flex-start'} marginLeft={30}>
            {value}
          </RowView>
        </RowView>
      )}
    </ColumnView>
  );
};

const Drawer = ({ items = [], onClick = mockFunction }) => (
  <ColumnView backgroundColor={colors.lightGrey} paddingHorizontal={8} paddingBottom={8} paddingTop={8} gap={8}>
    {items.map(renderDrawerContent)}

    <RowView justifyContent={'flex-end'}>
      <Button.OutlineSmall onClick={onClick}>{I18n.t('admin:onboardingTable.seeDetails')}</Button.OutlineSmall>
    </RowView>
  </ColumnView>
);

Drawer.propTypes = {
  id: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func
};

export default Drawer;
