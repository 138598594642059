import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Checkbox } from 'framework7-react';

import { colors } from 'Theme';
import { I18n } from 'Locales';
import { ArrowUp } from 'Icons';
import { ColumnView, RowView } from 'Containers';
import { mockFunction, stopPropagation } from 'Helpers';

import './style.scss';
import Drawer from './Drawer';
import { transformData } from './utils/helpers';

const renderHeader = column => (
  <RowView key={column.name} justifyContent={'flex-start'} gap={15}>
    <div className="table-header">{column?.name}</div>
  </RowView>
);

const renderItem = ({ row = {} }, key = '') => {
  const displayedItem = Array.isArray(row[key]) ? row[key].join(', ') : row[key];

  return (
    <RowView key={key} justifyContent={'flex-start'}>
      <div className="item">{displayedItem}</div>
    </RowView>
  );
};

const renderRows = (
  {
    drawerButtonAction = mockFunction,
    fieldsKeys = [],
    handleRowCheckboxChange = mockFunction,
    handleRowClick = mockFunction,
    moreInfo = true,
    multipleSelection = true,
    selectedRows = [],
    setShowDrawer = mockFunction,
    showDrawer = {}
  },
  row = {}
) => {
  const selectedRow = row.id === showDrawer;
  const isRowSelected = selectedRows?.some(selectedRow => selectedRow.id === row.id);

  return (
    <ColumnView key={row?.id} width={'100%'}>
      <RowView
        paddingVertical={5}
        {...(moreInfo && {
          onClick: e => {
            handleRowClick(row.id, setShowDrawer, showDrawer);
            e.stopPropagation();
          }
        })}>
        <RowView justifyContent={'space-between'} paddingHorizontal={8} gap={15}>
          {!!multipleSelection && (
            <RowView width={'auto'} justifyContent={'flex-start'} onClick={stopPropagation}>
              <Checkbox checked={isRowSelected} onChange={handleRowCheckboxChange.bind(null, row)} />
            </RowView>
          )}

          {fieldsKeys?.map(renderItem.bind(null, { row }))}
        </RowView>

        {moreInfo && (
          <RowView justifyContent={'flex-end'} width={'auto'}>
            <ArrowUp fillColor={colors.blue} className={selectedRow ? 'rotate180' : 'rotate0'} />
          </RowView>
        )}
      </RowView>

      {selectedRow && <Drawer items={row?.more_details} id={row.id} onClick={drawerButtonAction.bind(null, row)} />}

      <RowView backgroundColor={colors.darkGrey} height={1} />
    </ColumnView>
  );
};

const Table = ({
  columns = [],
  drawerButtonAction = mockFunction,
  moreInfo = true,
  multipleSelection = true,
  processing = false,
  rows = {},
  selectedRows = [],
  updateTransientProps = mockFunction,
  userRole = ''
}) => {
  const { payload } = rows;
  const transformedData = transformData(payload, userRole);
  const fieldsKeys = columns?.map(column => column?.field);
  const [showDrawer, setShowDrawer] = useState(null);
  const isAllSelected = transformedData?.length > 0 && selectedRows?.length === transformedData.length;

  const handleRowClick = (rowId, setShowDrawer, showDrawer) => setShowDrawer(rowId === showDrawer ? null : rowId);

  const handleHeaderCheckboxChange = () => {
    if (isAllSelected) {
      updateTransientProps({ selectedRows: [] });
    } else {
      updateTransientProps({ selectedRows: transformedData });
    }
  };

  const handleRowCheckboxChange = row => {
    const newSelectedRows = selectedRows?.some(selectedRow => selectedRow.id === row.id)
      ? selectedRows.filter(selectedRow => selectedRow.id !== row.id)
      : [...(selectedRows || []), row];

    updateTransientProps({ selectedRows: newSelectedRows });
  };

  return (
    <ColumnView width={'100%'} paddingHorizontal={16} paddingTop={15}>
      <RowView justifyContent={'space-between'} paddingHorizontal={8} gap={15}>
        {!!multipleSelection && (
          <RowView width={'auto'} justifyContent={'flex-start'}>
            <Checkbox checked={isAllSelected} onChange={handleHeaderCheckboxChange} />
          </RowView>
        )}

        {columns?.map(renderHeader)}

        {/* To keep table header left align with rows */}
        {moreInfo && <RowView width={'auto'} />}
      </RowView>

      <RowView backgroundColor={colors.darkGrey} height={2} marginTop={5} marginBottom={10} />

      {processing ? (
        <div className="empty-table">
          <div className="loader" />
        </div>
      ) : (
        <ColumnView>
          {transformedData?.length ? (
            transformedData?.map(
              renderRows.bind(null, {
                drawerButtonAction,
                fieldsKeys,
                handleRowCheckboxChange,
                handleRowClick,
                moreInfo,
                multipleSelection,
                selectedRows,
                setShowDrawer,
                showDrawer
              })
            )
          ) : (
            <div className="empty-table">{I18n.t('general:noResultsFound')}</div>
          )}
        </ColumnView>
      )}
    </ColumnView>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  drawerButtonAction: PropTypes.func,
  moreInfo: PropTypes.bool,
  multipleSelection: PropTypes.bool,
  processing: PropTypes.bool,
  rows: PropTypes.object,
  selectedRows: PropTypes.array,
  updateTransientProps: PropTypes.func,
  userRole: PropTypes.string
};

export default Table;
