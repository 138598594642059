import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { Avatar, Bubble } from 'Components';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { camelToSnake, mockFunction } from 'Helpers';
import { ReviewSection, Warning, StateActive, StateRejected } from 'Icons';
import { ADMIN_ROLES, COUNTRIES_ADDRESS, ONBOARDING_PROFILE_STATES, PAGE_NAMES, REGIONS } from 'Constants';

import './style.scss';
import { ProfileTextField } from '../../Components';
import { onboardingProfileTextFieldsConfig } from '../../utils/config';

const handleAcceptSection = (section, updateOnboardingUserSection, userOnboardingProfile, isAdmin) => {
  const acceptedSection = camelToSnake(section);
  const keyToUpdate = isAdmin ? 'by_admin' : 'by_mc_admin';

  const updatedProfile = {
    ...userOnboardingProfile,
    accepted_sections: {
      ...userOnboardingProfile?.accepted_sections,
      [keyToUpdate]: {
        ...userOnboardingProfile?.accepted_sections?.[keyToUpdate],
        [acceptedSection]: true
      }
    }
  };

  const newData = {
    data: {
      attributes: updatedProfile
    }
  };

  updateOnboardingUserSection(newData, userOnboardingProfile?.id, 'accept');
};

const handleRejectSection = (section, fieldsToReject, fieldsToPerformAction, updateTransientProps) => {
  updateTransientProps({
    section: section,
    fields: fieldsToReject,
    sectionFields: fieldsToPerformAction
  });
  NavigationService.navigate({
    name: PAGE_NAMES.REJECT_SECTION
  });
};

const renderSection = ({
  bankAccountCheckFR = mockFunction,
  bankAccountCheckUK = mockFunction,
  isOffline = false,
  section = {},
  transient = {},
  updateOnboardingUserProfile = mockFunction,
  updateOnboardingUserSection = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserAvatar = mockFunction,
  user_role = '',
  userOnboardingProfile = {}
}) => {
  const sectionKeys = Object.keys(section)[0];
  const fields = section[sectionKeys];

  const fieldsUnableToReject = onboardingProfileTextFieldsConfig.basicProfile
    .flatMap(section => Object.values(section).flat())
    .filter(field => field && !field.validations.includes('isRequired'))
    .map(field => field.fieldName);

  const fieldsBelongsToUserCountry = fields.filter(
    field => !field?.countrySpecificField || field?.countrySpecificField?.includes(userOnboardingProfile?.user?.country)
  );
  const { updateUserAvatarLoading = false } = transient;
  const rejectableFields = fieldsBelongsToUserCountry.filter(field => !fieldsUnableToReject.includes(field.fieldName));
  const fieldsToReject = rejectableFields.map(field => field.label);
  const fieldsToPerformAction = rejectableFields.map(field => field);
  const rejectionReason = userOnboardingProfile?.rejected_sections?.[camelToSnake(sectionKeys)]?.reason;
  const userCountry = userOnboardingProfile?.user?.country;
  const isAdmin = user_role === ADMIN_ROLES.ADMIN;
  const bubbleDisabledStatuses = [
    ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL,
    ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW
  ];
  const isBubbleDisabled = bubbleDisabledStatuses.includes(transient.state);
  const isDisabledForMCAdmin = !isAdmin && isBubbleDisabled;
  const isDisabledForAdmin =
    (isAdmin && transient.state === ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL) ||
    transient.state === ONBOARDING_PROFILE_STATES.ONBOARDED;
  const isDisabled = isDisabledForMCAdmin || isDisabledForAdmin;
  const acceptedAdminSections = userOnboardingProfile?.accepted_sections?.by_admin[camelToSnake(sectionKeys)];
  const acceptedMCAdminSection = userOnboardingProfile?.accepted_sections?.by_mc_admin[camelToSnake(sectionKeys)];

  const bubbleActions = [
    {
      label: I18n.t('admin:actions.acceptSection'),
      onClick: () => handleAcceptSection(sectionKeys, updateOnboardingUserSection, userOnboardingProfile, isAdmin)
    },
    {
      label: I18n.t('admin:actions.rejectSection'),
      onClick: () => handleRejectSection(sectionKeys, fieldsToReject, fieldsToPerformAction, updateTransientProps)
    }
  ];

  if (sectionKeys === 'emergency' && REGIONS.UK.includes(userCountry)) {
    return null;
  }

  return (
    <React.Fragment key={sectionKeys}>
      <RowView justifyContent="space-between" paddingHorizontal={16} paddingVertical={8}>
        <RowView justifyContent="flex-start" width="auto" gap={8}>
          <div>{I18n.t(`admin:basicProfileSections.${sectionKeys}`)}</div>
          {((rejectionReason || acceptedMCAdminSection) && !isAdmin) ||
          ((rejectionReason || acceptedAdminSections) && isAdmin) ? (
            <img className="h-20 w-20 pb-2" src={rejectionReason ? StateRejected : StateActive} />
          ) : null}
        </RowView>
        <RowView width="auto" justifyContent="flex-end">
          <div className="pt-2">{I18n.t('admin:sectionStatus')}</div>
          <Bubble
            items={bubbleActions}
            icon={ReviewSection}
            width={0}
            justIcon={true}
            disabled={isOffline || isDisabled}
          />
        </RowView>
      </RowView>
      {sectionKeys === 'generalDetails' && (
        <RowView paddingTop={8} paddingBottom={40}>
          <Avatar
            editable
            isOffline={isOffline}
            size={90}
            source={userOnboardingProfile?.user?.avatar_url}
            transient={transient}
            updateUserAvatarLoading={updateUserAvatarLoading}
            updateTransientProps={updateTransientProps}
            updateUserAvatar={updateUserAvatar}
            user={userOnboardingProfile?.user}
            userAvatar
          />
        </RowView>
      )}

      {rejectionReason && (
        <RowView justifyContent={'flex-start'} paddingHorizontal={16} paddingBottom={8}>
          <div className="rejection-wrapper">
            <Warning />
            <div className="rejection-text">{rejectionReason}</div>
          </div>
        </RowView>
      )}
      {sectionKeys === 'homeAddress' && COUNTRIES_ADDRESS.FINDER.includes(userOnboardingProfile?.user?.country) && (
        <List>
          <ListItem
            disabled={isOffline || isDisabled}
            link
            title={I18n.t('profile:contactTab.addressFinder')}
            onClick={NavigationService.navigate.bind(null, {
              name: 'SearchAddressList'
            })}
          />
        </List>
      )}
      {fields.map((field, index) => {
        if (
          !field?.countrySpecificField ||
          field?.countrySpecificField?.includes(userOnboardingProfile?.user?.country)
        ) {
          return (
            <ProfileTextField
              key={index}
              config={field}
              section={sectionKeys}
              userOnboardingProfile={userOnboardingProfile}
              transient={transient}
              isOffline={isOffline}
              userRole={user_role}
              bankAccountCheckFR={bankAccountCheckFR}
              bankAccountCheckUK={bankAccountCheckUK}
              updateTransientProps={updateTransientProps}
              updateTransientPropWithValidations={updateTransientPropWithValidations}
              updateOnboardingUserSection={updateOnboardingUserSection}
              updateOnboardingUserProfile={updateOnboardingUserProfile}
            />
          );
        } else {
          return null;
        }
      })}
    </React.Fragment>
  );
};

const BasicProfile = ({
  bankAccountCheckFR = mockFunction,
  bankAccountCheckUK = mockFunction,
  isOffline = false,
  transient = {},
  updateOnboardingUserProfile = mockFunction,
  updateOnboardingUserSection = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserAvatar = mockFunction,
  user_role = '',
  userOnboardingProfile = {}
}) => {
  let accepted = 0;
  let sections = 0;

  if (transient?.accepted_sections && transient?.accepted_sections?.by_mc_admin) {
    const keys = Object.keys(transient.accepted_sections.by_mc_admin);
    sections = keys.length;

    keys.forEach(key => {
      if (transient.accepted_sections.by_mc_admin[key] === true && user_role !== ADMIN_ROLES.ADMIN) {
        accepted++;
      } else if (transient.accepted_sections.by_admin[key] === true) {
        accepted++;
      }
    });
  }

  return (
    <>
      <ColumnView id={'basic-profile'}>
        <RowView justifyContent={'space-between'} paddingHorizontal={16} paddingBottom={8} paddingTop={20}>
          <div className="basic-profile-title">{I18n.t('admin:onboardingProfilePages.basic')}</div>
          <div className="accepted-number">{I18n.t('admin:acceptedOfTotal', { accepted, sections })}</div>
        </RowView>
        <List className="w-full">
          {onboardingProfileTextFieldsConfig?.basicProfile.map(section =>
            renderSection({
              bankAccountCheckFR,
              bankAccountCheckUK,
              isOffline,
              section,
              transient,
              updateOnboardingUserProfile,
              updateOnboardingUserSection,
              updateTransientProps,
              updateTransientPropWithValidations,
              updateUserAvatar,
              user_role,
              userOnboardingProfile
            })
          )}
        </List>
      </ColumnView>
    </>
  );
};

BasicProfile.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  isOffline: PropTypes.bool,
  transient: PropTypes.object,
  updateOnboardingUserProfile: PropTypes.func,
  updateOnboardingUserSection: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  user_role: PropTypes.string,
  userOnboardingProfile: PropTypes.object
};

export default BasicProfile;
