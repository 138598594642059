import { connect } from 'react-redux';

import Profile from './view';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

export default connect(
  state => ({
    campaign: state.campaign,
    currentPage: state.application.currentPage,
    isOffline: state.application.isOffline,
    onboarding_profile: state.user.onboarding_profile,
    profilePages: state.application.profilePages,
    roles: state.user.roles,
    transient: state.transient,
    user: state.user
  }),
  {
    bankAccountCheckFR: userActions.bankAccountCheckFR,
    bankAccountCheckUK: userActions.bankAccountCheckUK,
    getOnboardingProfiles: userActions.getOnboardingProfiles,
    getPostCodes: userActions.getPostCodes,
    getRoles: userActions.getRoles,
    getUser: userActions.getUser,
    updateProp: applicationActions.updateProp,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    updateUser: userActions.updateApiModel,
    updateUserAvatar: userActions.updateUserAvatar,
    updateUserProp: userActions.updateProp
  }
)(Profile);
