import { createActions } from 'reduxsauce';
import { persistCombineReducers } from 'redux-persist';

import { appInitialState } from 'Reducers/application';
import { admin, application, campaign, checkIn, flow, growl, postCodes, result, transient, user } from 'Reducers';

import { storeConfig } from './config';

export const { Types } = createActions(
  {
    reset: null
  },
  { prefix: 'state/' }
);

const appReducers = persistCombineReducers(storeConfig, {
  admin: admin,
  application: application,
  campaign: campaign,
  checkIn: checkIn,
  flow: flow,
  growl: growl,
  postCodes: postCodes,
  transient: transient,
  result: result,
  user: user
});

const rootReducer = (state, action) => {
  if (action.type === 'state/RESET') {
    state = {
      ...state,
      admin: undefined,
      application: {
        ...state.application,
        ...appInitialState
      },
      flow: undefined,
      postCodes: undefined,
      // keep the result state just if offline sales exists
      result: state?.result[state.user.id]?.offline?.length ? { ...state.result } : undefined,
      transient: undefined,
      user: undefined
    };
  }
  return appReducers(state, action);
};

export default rootReducer;
