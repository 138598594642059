import {
  Admin,
  AdvancedFilters,
  Birthday,
  BrokenOrUnauthorized,
  BulkReleaseBlock,
  Campaigns,
  Carousel,
  ChangePassword,
  CheckIn,
  CheckInList,
  CreateUser,
  Dashboard,
  Feedback,
  Finish,
  Flow,
  ForgotPassword,
  FullName,
  Login,
  NotFound,
  OnboardingProfile,
  PasswordUpdate,
  PostCodesList,
  Profile,
  RejectDocument,
  RejectSection,
  Reports,
  ResetPassword,
  ResetPasswordConfirmation,
  SearchAddressList,
  Settings,
  SignUp,
  TermsAndConditions,
  ThankYou,
  Tools,
  VersionUpdate
} from 'Pages';
import { APP_MODES } from 'Constants';

const publicRoutes = [
  {
    name: 'TermsAndConditions',
    path: '/public/user/signup/terms-and-conditions',
    component: TermsAndConditions
  },
  {
    name: 'Login',
    path: '/public/user/login',
    component: Login
  },
  {
    name: 'SignUp',
    path: '/public/user/signup',
    component: SignUp
  },
  {
    name: 'ForgotPassword',
    path: '/public/user/forgot-password',
    component: ForgotPassword
  },
  {
    name: 'BrokenOrUnauthorized',
    path: '/public/broken-or-unauthorized',
    component: BrokenOrUnauthorized
  },
  {
    name: 'ResetPassword',
    path: '/public/user/reset-password',
    component: ResetPassword
  },
  {
    name: 'ResetPasswordConfirmation',
    path: '/public/user/reset-password-confirmation',
    component: ResetPasswordConfirmation
  }
];

const sessionRoutes = [
  {
    name: 'Admin',
    path: '/session/admin',
    component: Admin,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'Dashboard',
    path: '/session/dashboard',
    component: Dashboard,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'OnboardingProfile',
    path: '/session/onboarding',
    component: OnboardingProfile,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'BulkReleaseBlock',
    path: '/session/bulk-release-block',
    component: BulkReleaseBlock,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'RejectDocument',
    path: '/session/reject-document',
    component: RejectDocument,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'RejectSection',
    path: '/session/reject-section',
    component: RejectSection,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'SearchAddressList',
    path: '/session/search-address',
    component: SearchAddressList,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'Birthday',
    path: '/session/birthday',
    component: Birthday,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Carousel',
    path: '/session/carousel',
    component: Carousel,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Finish',
    path: '/session/finish',
    component: Finish,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'FullName',
    path: '/session/full-name',
    component: FullName,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Campaigns',
    path: '/session/campaigns',
    component: Campaigns,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'ChangePassword',
    path: '/session/change-password',
    component: ChangePassword,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'CheckIn',
    path: '/session/checkin',
    component: CheckIn,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Feedback',
    path: '/session/feedback',
    component: Feedback
  },
  {
    name: 'Flow',
    path: '/session/flow',
    component: Flow
  },
  {
    name: 'Profile',
    path: '/session/profile',
    component: Profile,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Reports',
    path: '/session/reports',
    component: Reports,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Settings',
    path: '/session/settings',
    component: Settings,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'Tools',
    path: '/session/tools',
    component: Tools,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'ThankYou',
    path: '/session/thankyou',
    component: ThankYou
  },
  {
    name: 'PostCodesList',
    path: '/session/postcode-list',
    component: PostCodesList,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'VersionUpdate',
    path: '/session/version-update',
    component: VersionUpdate
  },
  {
    name: 'CheckInList',
    path: '/session/check-in-list',
    component: CheckInList,
    accessPermission: [APP_MODES.ADMIN, APP_MODES.AGENT]
  },
  {
    name: 'AdvancedFilters',
    path: '/session/advanced-filters',
    component: AdvancedFilters,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'CreateUser',
    path: '/session/create-user',
    component: CreateUser,
    accessPermission: [APP_MODES.ADMIN]
  },
  {
    name: 'PasswordUpdate',
    path: '/session/update-password',
    component: PasswordUpdate,
    accessPermission: [APP_MODES.AGENT, APP_MODES.ADMIN]
  }
];

const routes = [
  ...publicRoutes,
  ...sessionRoutes,
  {
    path: '(.*)',
    component: NotFound
  }
];

export const defaultRoutes = {
  donorSession: {
    name: 'Flow',
    url: '/session/flow'
  },
  noSession: {
    name: 'Login',
    url: '/public/user/login'
  },
  session: {
    name: 'Campaigns',
    url: '/session/campaigns'
  }
};

export default routes;
