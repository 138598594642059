import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

const BlockedLogoutModal = ({ hasOfflineResults = mockFunction, openPopup = false, setOpenPopup = mockFunction }) => (
  <Modal open={openPopup} onClose={setOpenPopup.bind(null, false)}>
    <ColumnView height={'100%'}>
      <ColumnView alignItems={'center'}>
        <RowView>
          <h2 className="weight">{I18n.t('general:logutPopupTitle')}</h2>
        </RowView>
        <RowView>
          <p className="text-center">{I18n.t('general:logutPopupDescription')}</p>
        </RowView>
      </ColumnView>
      <RowView justifyContent={'center'} alignItems={'flex-end'} paddingTop={50}>
        <Button.Primary onClick={hasOfflineResults.bind(null, { setOpenPopup })} width={'100%'}>
          {I18n.t('general:ok')}
        </Button.Primary>
      </RowView>
    </ColumnView>
  </Modal>
);

BlockedLogoutModal.propTypes = {
  hasOfflineResults: PropTypes.func,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func
};

export default BlockedLogoutModal;
