import PropTypes from 'prop-types';
import { List } from 'framework7-react';
import React, { useEffect } from 'react';

import { I18n } from 'Locales';
import { Warning } from 'Icons';
import { Avatar } from 'Components';
import { mockFunction } from 'Helpers';
import { ONBOARDING_FIELDS } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { isRejectedField } from 'Pages/session/Profile/utils/helpers';
import TextFieldComponent from 'Pages/session/Profile/ProfileTextField';
import { profileTextFieldsConfig } from 'Pages/session/Profile/utils/config';

import './style.scss';
import { RejectionReasonModal, ShowBadgeModal } from '../Modals';

const areEqual = (prevProps, nextProps) =>
  prevProps.transient === nextProps.transient &&
  prevProps.isOffline === nextProps.isOffline &&
  prevProps.user.avatar_url === nextProps.user.avatar_url &&
  Object.keys(prevProps.transient).every(key => prevProps.transient[key] === nextProps.transient[key]);

const renderTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig?.generalDetails?.map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent
        key={index}
        config={config}
        section={sectionConfig}
        transient={transient}
        user={user}
        {...otherProps}
      />
    ) : null
  );

const ProfileTab = ({
  campaign,
  isOffline,
  onboarding_profile,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  updateUserAvatar,
  user
}) => {
  const rejectedFields = user?.rejected_fields || [];
  const rejectedProfileArray = rejectedFields.filter(field => ONBOARDING_FIELDS.PROFILE.includes(field));

  const getSelectedBadge = (user, campaign) => {
    if (!campaign[user.id] || !user.active_badges?.length) return null;

    const selectedCampaignId = campaign[user.id].selectedCampaign?.id;
    return user.active_badges.find(badge => badge.campaign.id === selectedCampaignId) || user.active_badges[0];
  };

  const selectedBadge = getSelectedBadge(user, campaign);

  const { rejected_sections: rejectedSections, accepted_sections: acceptedSections } = onboarding_profile;
  const { showProfileRejectionModal = false, updateUserAvatarLoading = false } = transient;

  const sections = {
    generalDetails: {
      readOnly:
        user.soft_deactivated || (acceptedSections?.by_mc_admin?.general_details && user.isApplicant) || isOffline,
      rejectedReason: rejectedSections?.general_details?.reason
    }
  };

  useEffect(() => {
    if (user?.active_badges?.length) {
      updateTransientProps({
        activeUserBadges: {
          badges: user.active_badges,
          selectedBadge
        }
      });
    }
  }, []);

  return (
    <>
      <ColumnView width={'100%'} id={'profile-tab'}>
        <RowView paddingTop={30} paddingBottom={16}>
          <Avatar
            badge={user?.active_badges?.length ? true : false}
            editable
            size={90}
            source={user?.avatar_url}
            transient={transient}
            updateTransientProps={updateTransientProps}
            updateUserAvatar={updateUserAvatar}
            updateUserAvatarLoading={updateUserAvatarLoading}
            user={user}
            isOffline={isOffline}
            userAvatar
          />
        </RowView>

        {!!rejectedProfileArray.length && (
          <RowView
            justifyContent={'flex-start'}
            paddingHorizontal={16}
            marginTop={32}
            onClick={updateTransientProps.bind(null, { showProfileRejectionModal: true })}>
            <div className="rejection-wrapper">
              <Warning />
              <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
            </div>
          </RowView>
        )}
        <RowView justifyContent={'flex-start'} marginTop={rejectedProfileArray.length ? 0 : 32}>
          <List className="w-full">
            {'email' in transient &&
              renderTextFields('generalDetails', transient, user, {
                updateTransientProps,
                updateTransientPropWithValidations,
                updateUser,
                isReadOnly: sections.generalDetails.readOnly,
                isRejectedField: field => isRejectedField(rejectedFields, field)
              })}
          </List>
        </RowView>
      </ColumnView>

      <ShowBadgeModal
        transient={transient}
        updateTransientProps={updateTransientProps}
        updateUserAvatar={updateUserAvatar}
        user={user}
      />

      <RejectionReasonModal
        isOpen={showProfileRejectionModal}
        onClose={updateTransientProps.bind(null, { showProfileRejectionModal: false })}
        rejectedFields={{ fields: rejectedProfileArray }}
        rejectedReason={sections.generalDetails?.rejectedReason}
        fieldConfig={[profileTextFieldsConfig.generalDetails]}
      />
    </>
  );
};

ProfileTab.propTypes = {
  campaign: PropTypes.object,
  handleOnChangeWithValidations: PropTypes.func,
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  updateUserAvatarLoading: PropTypes.bool,
  user: PropTypes.object,
  userBadge: PropTypes.array,
  userFullName: PropTypes.string
};

ProfileTab.defaultProps = {
  campaign: {},
  handleOnChangeWithValidations: mockFunction,
  isOffline: false,
  onboarding_profile: {},
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  updateUserAvatar: mockFunction,
  updateUserAvatarLoading: false,
  user: {},
  userBadge: [],
  userFullName: ''
};

export default React.memo(ProfileTab, areEqual);
