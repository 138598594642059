import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'framework7-react';

import { I18n } from 'Locales';
import { Modal } from 'Components';
import { mockFunction } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const RejectionReasonModal = ({
  fieldConfig = [],
  isOpen = false,
  onClose = mockFunction,
  rejectedFields = {},
  rejectedReason = ''
}) => (
  <Modal open={isOpen} onClose={onClose}>
    <ColumnView id={'rejection-modal'} justifyContent="space-between" height={'100%'} gap={24}>
      <ColumnView justifyContent="flex-start">
        <RowView justifyContent="flex-end" height={24} onClick={onClose}>
          <Icon className="justify-end" md={'material:close'} />
        </RowView>
        <ColumnView justifyContent="flex-start">
          <div className="rejection-modal__title">{I18n.t('profile:rejectionTitle')}</div>
          <div className="rejection-modal__content">
            <div className="rejection-modal__subtitle">{I18n.t('profile:rejectionText')}</div>
            <div className="rejection-modal__tags">
              {rejectedFields?.fields?.map((fieldName, index) => (
                <div key={index} className="rejection-modal__tags--tag">
                  {fieldConfig
                    .map(group => I18n.t(group.find(field => field.fieldName === fieldName)?.label))
                    .filter(label => label)}
                </div>
              ))}
            </div>
            <div className="rejection-modal__reason">{rejectedReason}</div>
          </div>
        </ColumnView>
      </ColumnView>
    </ColumnView>
  </Modal>
);

RejectionReasonModal.propTypes = {
  fieldConfig: PropTypes.arrayOf(PropTypes.array).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rejectedFields: PropTypes.object,
  rejectedReason: PropTypes.string
};

export default RejectionReasonModal;
