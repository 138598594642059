import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Page, Tab, Tabs, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { CampaignHand, PlayArrow } from 'Icons';
import { ColumnView, RowView } from 'Containers';
import { AppHeader, Banner, Button, Fab } from 'Components';
import { ALERT_TYPES, EMPTY_TABS, ICON_COLOR, PAGE_NAMES } from 'Constants';

import './style.scss';
import { Onboarded, Onboarding } from './subviews';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const tcArray = [
  {
    key: 'terms_agreement',
    title: 'mediaReleaseForm'
  },
  {
    key: 'conditions_agreement',
    title: 'legalAgreements'
  },
  {
    key: 'privacy_agreement',
    title: 'privacyStatement'
  }
];

const navigateCampaign = ({ campaign, getOnboardingProfiles, setUserCampaign, userId }) => {
  setUserCampaign(campaign, false);
  getOnboardingProfiles(userId);
};

const handleNavigate = (updateApplicationProp, value) => {
  NavigationService.navigate({
    name: PAGE_NAMES.PROFILE
  });
  updateApplicationProp('currentPage', value);
  updateApplicationProp('selectedAppBottomBarSection', {
    label: 'Profile',
    pageName: PAGE_NAMES.PROFILE
  });
};

const processSectionValue = ({ user, empty_pages }, sectionValue) => {
  const [, { fields }] = sectionValue;
  fields.forEach(field => {
    const page = EMPTY_TABS[field];
    if (!empty_pages[page]) {
      empty_pages[page] = { blank_fields: [], name: page };
    }
    if (!empty_pages[page].blank_fields.includes(field) && !user?.blank_fields?.includes(field)) {
      empty_pages[page].blank_fields.push(field);
    }
  });
};

const renderEmptyPages = (emptyPages, blankField) => {
  const page = EMPTY_TABS[blankField];
  emptyPages[page] = emptyPages[page] || { blank_fields: [], name: page };
  emptyPages[page].blank_fields.push(blankField);
  return emptyPages;
};

const renderCampaign = (
  { campaign = [], getOnboardingProfiles = mockFunction, setUserCampaign = mockFunction, user = {} },
  item,
  index
) => {
  const isSelectedCampaign = item.id === campaign[user?.id]?.selectedCampaign?.id;

  return (
    <div
      key={index}
      className={'campaign-tab'}
      onClick={navigateCampaign.bind(null, {
        campaign: item,
        getOnboardingProfiles,
        setUserCampaign,
        userId: user.id
      })}>
      <ColumnView>
        {item?.icon_logo_url !== null ? (
          <img src={item?.icon_logo_url} className="tab-icon-size" />
        ) : (
          <CampaignHand fillColor={isSelectedCampaign ? ICON_COLOR.BLUE : ICON_COLOR.GRAY} />
        )}
        <RowView key={index} marginTop={8}>
          <Link tabLink={campaign[user?.id]?.selectedCampaign?.id} tabLinkActive={isSelectedCampaign}>
            <span className={`tab-label ${isSelectedCampaign && 'active'}`}>{item.name.toUpperCase()}</span>
          </Link>
        </RowView>
      </ColumnView>
    </div>
  );
};

const renderTab = ({ user = {} }, campaign, index) => {
  const selectedCampaignId = campaign[user.id]?.selectedCampaign?.id;
  return <Tab className={'background'} id={selectedCampaignId} key={index} tabActive={selectedCampaignId} />;
};

const handleTermsAndConditionsList = (updateTransientProps, item) => {
  updateTransientProps({ renderKey: item.key });
  NavigationService.navigate({
    name: 'TermsAndConditions'
  });
  updateTransientProps({ tc_modal: false });
};

const termsAndConditionsMessage = updateTransientProps => (
  <div className="text-sm font-light">
    {I18n.t('general:tcAgreement')}
    {tcArray.map((item, index) => (
      <span key={index}>
        <span className="title-links" onClick={handleTermsAndConditionsList.bind(null, updateTransientProps, item)}>
          {I18n.t(`general:${item.title}`)}
        </span>
        {index < tcArray.length - 2 ? ', ' : index === tcArray.length - 2 ? `${' '}${I18n.t('general:and')}${' '}` : ''}
      </span>
    ))}
  </div>
);

const updateUserTC = ({ updateUser, updateTransientProps }) => {
  updateUser({ data: { attributes: { tc_accepted: true } } });
  updateTransientProps({ tc_modal: false, tc_accepted: true });
};

const renderBanner = ({ isOffline = false, forceSendToDonor = false }) => {
  if (!isOffline && !forceSendToDonor) return;

  let bannerTitle = '';
  let content = '';

  if (forceSendToDonor && isOffline) {
    bannerTitle = I18n.t('general:forceSendToDonorBanner:title');
    content = I18n.t('general:forceSendToDonorBanner:description');
  }
  if (isOffline) {
    bannerTitle = I18n.t('general:isOfflineBanner:title');
    content = I18n.t('general:isOfflineBanner:description');
  }

  return <Banner type={ALERT_TYPES.INFO} title={bannerTitle} content={content} />;
};

const handleStartFlow = ({
  checkInObject,
  flowId,
  getPublishedFlows,
  isOffline,
  NavigationService,
  updateTransientProps
}) => {
  if (isOffline) {
    NavigationService.navigate({
      name: 'Flow',
      flowId
    });
  } else {
    updateTransientProps({ loadingFlow: true });
    getPublishedFlows(checkInObject);
  }
};

const Campaigns = ({
  campaign,
  campaigns,
  checkIn,
  checkInObject,
  checkOut,
  currentPage,
  flow,
  flowId,
  flowReady,
  getAttachments,
  getCampaigns,
  getOnboardingProfiles,
  getPublishedFlows,
  getRoles,
  getUser,
  hasValidBadge,
  isCheckedIn,
  isOffline,
  profilePages,
  selectedCampaignPublishedFlowId,
  setUserCampaign,
  transient,
  updateApplicationProp,
  updateProp,
  updateTransientProps,
  updateUser,
  updateUserProps,
  user
}) => {
  const userHaveCampaigns = !!campaign[user.id]?.campaigns.length;
  const isOnboarded = userHaveCampaigns;
  const neededForCheckIn = !user.needed_for_check_in?.length && isOnboarded;
  const forceSendToDonor = flow?.flows[selectedCampaignPublishedFlowId]?.force_send_to_donor;

  const [showModal, setShowModal] = useState();

  const { id: userId } = user;
  const { tc_modal = false } = transient;

  useEffect(() => {
    getOnboardingProfiles(userId);
    getUser(userId);
    getRoles();
  }, []);

  useEffect(() => {
    if (campaign[user.id]?.selectedCampaign === undefined && !!campaign[user.id]?.campaigns?.length) {
      setUserCampaign(campaign[user.id].campaigns[0], false);
    }
  }, [campaign]);

  useEffect(() => {
    if (user?.campaign?.id === undefined) {
      getAttachments();
    }
  }, []);

  useEffect(() => {
    if (isCheckedIn && flowReady) {
      updateTransientProps({ processing: false });
    }
  }, [isCheckedIn, flowReady]);

  /*
  small hack by using useState to control f7 popup 
   */
  useEffect(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    const empty_pages = (user?.blank_fields || []).reduce(renderEmptyPages, {});
    const rejected_sections = user?.onboarding_profile?.rejected_sections || {};

    Object.entries(rejected_sections).forEach(processSectionValue.bind(null, { user, empty_pages }));

    if (empty_pages && Object.keys(empty_pages).length > 0) {
      updateUserProps({ empty_pages });
    }
  }, [user.blank_fields, user?.onboarding_profile?.rejected_sections]);

  return (
    <Page id={'campaigns'}>
      {isOnboarded ? (
        <>
          <AppHeader
            title={`${user.first_name} ${I18n.t('general:campaigns')}`}
            banner={forceSendToDonor && isOffline && renderBanner({ isOffline, forceSendToDonor })}>
            <ToolbarF7 className="campaign-navbar" position={'top'} tabbar scrollable noShadow noHairLine>
              {campaigns?.map(
                renderCampaign.bind(null, {
                  campaign,
                  getOnboardingProfiles,
                  setUserCampaign,
                  user
                })
              )}
            </ToolbarF7>
          </AppHeader>
          <ColumnView>
            <Tabs>{campaigns?.map(renderTab.bind(null, { user }))}</Tabs>
          </ColumnView>
        </>
      ) : (
        <AppHeader title={I18n.t('general:home')} banner={!isOnboarded && renderBanner({ isOffline })} />
      )}

      {isOnboarded ? (
        <Onboarded
          campaign={campaign}
          checkIn={checkIn}
          checkInObject={checkInObject}
          checkOut={checkOut}
          currentPage={currentPage}
          flowReady={flowReady}
          getCampaigns={getCampaigns}
          getPublishedFlows={getPublishedFlows}
          handleNavigate={handleNavigate}
          hasValidBadge={hasValidBadge}
          isCheckedIn={isCheckedIn}
          isOffline={isOffline}
          profilePages={profilePages}
          transient={transient}
          updateApplicationProp={updateApplicationProp}
          updateProp={updateProp}
          updateTransientProps={updateTransientProps}
          user={user}
        />
      ) : (
        <Onboarding
          currentPage={currentPage}
          handleNavigate={handleNavigate}
          profilePages={profilePages}
          updateApplicationProp={updateApplicationProp}
          updateProp={updateProp}
          user={user}
        />
      )}
      {neededForCheckIn && userHaveCampaigns && (
        <Fab className={'w-full px-20'} position={'center-bottom'} slot={'fixed'}>
          <Button.Primary
            processing={(transient?.processing && !(isCheckedIn && flowReady)) || transient?.loadingFlow}
            iconLeft={!transient?.processing && !transient?.loadingFlow && !transient?.loadingFlow && <PlayArrow />}
            disabled={
              !(isCheckedIn && flowReady) ||
              (isOffline && forceSendToDonor) ||
              transient?.loadingFlow ||
              (isOffline && forceSendToDonor) ||
              transient?.loadingFlow ||
              transient?.checkOutLoading
            }
            width={'100%'}
            onClick={handleStartFlow.bind(null, {
              checkInObject,
              flowId,
              getPublishedFlows,
              isOffline,
              updateTransientProps,
              NavigationService
            })}>
            {I18n.t('campaign:startFlow')}
          </Button.Primary>
        </Fab>
      )}

      {showModal && (
        <TermsAndConditionsModal
          tc_modal={tc_modal}
          termsAndConditionsMessage={termsAndConditionsMessage}
          updateTransientProps={updateTransientProps}
          updateUser={updateUser}
          updateUserTC={updateUserTC}
        />
      )}
    </Page>
  );
};

Campaigns.propTypes = {
  campaign: PropTypes.object,
  campaigns: PropTypes.array,
  checkIn: PropTypes.func,
  checkInObject: PropTypes.object,
  checkOut: PropTypes.func,
  currentPage: PropTypes.string,
  flow: PropTypes.object,
  flowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flowReady: PropTypes.bool,
  getAttachments: PropTypes.func,
  getCampaigns: PropTypes.func,
  getOnboardingProfiles: PropTypes.func,
  getPublishedFlows: PropTypes.func,
  getRoles: PropTypes.func,
  getUser: PropTypes.func,
  hasValidBadge: PropTypes.bool,
  isCheckedIn: PropTypes.bool,
  isOffline: PropTypes.bool,
  profilePages: PropTypes.array,
  selectedCampaignPublishedFlowId: PropTypes.string,
  setUserCampaign: PropTypes.func,
  transient: PropTypes.object,
  updateApplicationProp: PropTypes.func,
  updateProp: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserProps: PropTypes.func,
  user: PropTypes.object
};

Campaigns.defaultProps = {
  badgeNumber: {},
  campaign: {},
  campaigns: [],
  checkIn: mockFunction,
  checkInObject: {},
  checkOut: mockFunction,
  currentPage: '',
  flow: {},
  flowId: null,
  flowReady: false,
  getAttachments: mockFunction,
  getCampaigns: mockFunction,
  getPublishedFlows: mockFunction,
  getRoles: mockFunction,
  getUser: mockFunction,
  hasValidBadge: false,
  isCheckedIn: false,
  isOffline: false,
  profilePages: [],
  selectedCampaignPublishedFlowId: '',
  transient: {},
  updateApplicationProp: mockFunction,
  updateProp: mockFunction,
  updateTransientProps: mockFunction,
  updateUser: mockFunction,
  updateUserProps: mockFunction,
  user: {}
};

export default Campaigns;
