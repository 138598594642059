import PropTypes from 'prop-types';
import { Page } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { ArrowUp } from 'Icons';
import { mockFunction } from 'Helpers';
import { AppHeader } from 'Components';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';
import { ADMIN_ROLES, PAGE_NAMES } from 'Constants';

import './style.scss';
import SwitchMcPinModal from './SwitchMcPinModal';

const ToggleRowView = ({ isOpen = false, onClick = mockFunction, title = '' }) => (
  <>
    <RowView onClick={onClick}>
      <RowView>
        <div className="section-title pl-20">{title}</div>
      </RowView>
      <ArrowUp className={isOpen ? 'arrow-down' : 'arrow-up'} />
    </RowView>
    <RowView backgroundColor={colors.primary} height={1} marginTop={5} marginBottom={10} />
  </>
);

ToggleRowView.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string
};

const Admin = ({
  admin = {},
  getMcPin = mockFunction,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction,
  user = {}
}) => {
  const [showSubview, setShowSubview] = useState(true);

  useEffect(() => {
    if (admin?.selectedMcPin !== '') {
      updateTransientProps({ selectedMcPin: admin.selectedMcPin });
    }
  }, []);

  const { mcPinsModal = false } = transient;
  const isNotAdmin = user?.role_name !== ADMIN_ROLES.ADMIN;

  return (
    <Page id={'admin'}>
      <AppHeader
        hideAvatar
        showMcPins={isNotAdmin}
        onClickMcPin={user?.mc_pins?.length > 1 ? updateTransientProps.bind(null, { mcPinsModal: true }) : mockFunction}
        title={I18n.t('general:admin')}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} justifyContent="space-between" paddingHorizontal={20}>
        <div className="w-full mt-32">
          <ToggleRowView
            onClick={setShowSubview.bind(null, !showSubview)}
            isOpen={showSubview}
            title={I18n.t('admin:onboarding')}
          />
          {showSubview && (
            <RowView marginBottom={4} onClick={NavigationService.navigate.bind(null, { name: PAGE_NAMES.DASHBOARD })}>
              <div className="section-subtitle">{I18n.t('admin:dashboard')}</div>
            </RowView>
          )}
        </div>
      </ColumnView>

      <SwitchMcPinModal
        admin={admin}
        getMcPin={getMcPin}
        mcPinsModal={mcPinsModal}
        transient={transient}
        updateAdminProps={updateAdminProps}
        updateTransientProps={updateTransientProps}
        user={user}
      />
    </Page>
  );
};

Admin.propTypes = {
  admin: PropTypes.object,
  getMcPin: PropTypes.func,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default Admin;
