import React from 'react';
import PropTypes from 'prop-types';
import { BlockTitle, List } from 'framework7-react';

import { I18n } from 'Locales';
import { Warning } from 'Icons';
import { mockFunction } from 'Helpers';
import { ONBOARDING_FIELDS } from 'Constants';
import { ColumnView, RowView } from 'Containers';

import { isRejectedField } from 'Pages/session/Profile/utils/helpers';
import TextFieldComponent from 'Pages/session/Profile/ProfileTextField';
import { profileTextFieldsConfig } from 'Pages/session/Profile/utils/config';

import { RejectionReasonModal } from '../Modals';

const areEqual = (prevProps, nextProps) =>
  prevProps.isOffline === nextProps.isOffline &&
  Object.keys(prevProps.transient).every(key => prevProps.transient[key] === nextProps.transient[key]);

const renderTextFields = (sectionConfig, transient, user, otherProps) =>
  profileTextFieldsConfig.bankDetails.map((config, index) =>
    !config.countrySpecificField || config.countrySpecificField.includes(user.country) ? (
      <TextFieldComponent
        key={index}
        config={config}
        user={user}
        section={sectionConfig}
        transient={transient}
        {...otherProps}
      />
    ) : null
  );

const BankDetailsTab = ({
  bankAccountCheckFR,
  bankAccountCheckUK,
  isOffline,
  onboarding_profile,
  transient,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  user
}) => {
  const { showBankRejectionModal = false } = transient;
  const { rejected_sections: rejectedSections, accepted_sections: acceptedSections } = onboarding_profile;

  const rejectedFields = user?.rejected_fields || [];
  const rejectedBankArray = rejectedFields.filter(field => ONBOARDING_FIELDS.BANK_DETAILS.includes(field));

  const sections = {
    bankDetails: {
      readOnly: user.soft_deactivated || (acceptedSections?.by_mc_admin?.bank_details && user.isApplicant) || isOffline,
      rejectedReason: rejectedSections?.bank_details?.reason
    }
  };

  return (
    <>
      <ColumnView>
        <RowView justifyContent={'flex-start'}>
          <BlockTitle className="mb-10 text-md text-dark-gray">
            {I18n.t('profile:bankDetailsTab.bankAccount')}
          </BlockTitle>
        </RowView>
        {!!rejectedBankArray.length && (
          <RowView
            justifyContent={'flex-start'}
            paddingHorizontal={16}
            onClick={updateTransientProps.bind(null, { showBankRejectionModal: true })}>
            <div className="rejection-wrapper">
              <Warning />
              <div className="rejection-text">{I18n.t('profile:rejectionTitle')}</div>
            </div>
          </RowView>
        )}
        <RowView justifyContent={'flex-start'}>
          <List noHairlines className="w-full mt-8">
            {'email' in transient &&
              renderTextFields('bankDetails', transient, user, {
                bankAccountCheckFR,
                bankAccountCheckUK,
                isReadOnly: sections.bankDetails.readOnly,
                isRejectedField: field => isRejectedField(rejectedFields, field),
                updateTransientProps,
                updateTransientPropWithValidations,
                updateUser
              })}
          </List>
        </RowView>
        <ColumnView>
          <RowView justifyContent={'flex-start'}>
            <BlockTitle className="font-normal">{I18n.t('profile:bankDetailsTab.validationStatus')}</BlockTitle>
            {transient?.account_number && (
              <BlockTitle
                className={`${
                  transient.account_number_has_error || transient.error ? 'text-fail' : 'text-success'
                } ml-0 uppercase`}>
                {transient.account_number_has_error || transient.error
                  ? I18n.t('profile:bankDetailsTab.invalid')
                  : I18n.t('profile:bankDetailsTab.valid')}
              </BlockTitle>
            )}
          </RowView>
          <RowView justifyContent={'flex-start'}>
            {user.country !== 'UK' && !!transient.errors?.length ? (
              <BlockTitle className="mt-0">
                {transient.errors?.map((error, index) => (
                  <p key={index}>{error}</p>
                ))}
              </BlockTitle>
            ) : (
              <BlockTitle className="mt-0">
                <p>{transient.error}</p>
              </BlockTitle>
            )}
          </RowView>
        </ColumnView>
      </ColumnView>

      <RejectionReasonModal
        isOpen={showBankRejectionModal}
        onClose={updateTransientProps.bind(null, { showBankRejectionModal: false })}
        rejectedFields={{ fields: rejectedBankArray }}
        rejectedReason={sections.bankDetails.rejectedReason}
        fieldConfig={[profileTextFieldsConfig.bankDetails]}
      />
    </>
  );
};

BankDetailsTab.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  handleChangeBankAccount: PropTypes.func,
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

BankDetailsTab.defaultProps = {
  bankAccountCheckFR: mockFunction,
  bankAccountCheckUK: mockFunction,
  handleChangeBankAccount: mockFunction,
  isOffline: false,
  onboarding_profile: {},
  transient: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  user: {}
};

export default React.memo(BankDetailsTab, areEqual);
