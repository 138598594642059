import PropTypes from 'prop-types';
import { List, ListItem, Page } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { AppHeader, Button } from 'Components';
import { NEEDS_LEURKAART, PAGE_NAMES, ROLES } from 'Constants';

import './style.scss';
import { createUserFields } from '../utils/config';
import { CreateUserTextField } from '../Components';
import { areRequiredFieldsPopulated, getDisplayedFields, handleCreateUser, resetKeys } from '../utils/helpers';

const renderField = ({ transient, regionNeedsLeukaartSelected, otherProps }, config, index) => {
  const { regionsAcknowledgement, setRegionsAcknowledgement } = otherProps;

  return (
    <>
      {regionNeedsLeukaartSelected && config.fieldName === 'regions' && (
        <ListItem
          checkbox={true}
          required={true}
          checked={regionsAcknowledgement}
          onChange={setRegionsAcknowledgement.bind(null, !regionsAcknowledgement)}
          title={I18n.t('admin:createUserFields.regionsAcknowledgementMessage')}
        />
      )}
      <CreateUserTextField key={index} keyProp={index} config={config} transient={transient} {...otherProps} />
    </>
  );
};

const navigateBack = ({ cleanUpTransientProps }) => {
  NavigationService.goBack({ name: PAGE_NAMES.DASHBOARD });
  cleanUpTransientProps(['filters']);
};

const CreateUser = ({
  admin = {},
  cleanUpTransientProps = mockFunction,
  createUser = mockFunction,
  getCoach = mockFunction,
  getMarketingCompanies = mockFunction,
  getRecruiter = mockFunction,
  transient = {},
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  user = {}
}) => {
  const { selectedMcPin } = admin;
  const { can_simple_create_users } = user;
  const { country, createUserLoading, marketingCompanies, pins, role } = transient;
  const [areFieldsPopulated, setAreFieldsPopulated] = useState(false);
  const [regionsAcknowledgement, setRegionsAcknowledgement] = useState(false);
  const otherProps = {
    createUserFields,
    getCoach,
    getRecruiter,
    regionsAcknowledgement,
    setRegionsAcknowledgement,
    updateTransientProps,
    updateTransientPropWithValidations,
    user
  };
  const displayedFields = getDisplayedFields(transient, otherProps);
  const mcCompaniesName = Array.isArray(transient?.pins)
    ? marketingCompanies?.filter(mc => transient.pins.includes(mc?.pin)).map(mc => mc?.name) || []
    : marketingCompanies?.filter(mc => mc?.pin === transient?.pins).map(mc => mc?.name) || [];
  const regionNeedsLeukaartSelected = transient?.regions?.some(region => NEEDS_LEURKAART.includes(region));
  const hasAcknowledgement = !regionNeedsLeukaartSelected || regionsAcknowledgement;
  const isSaveDisabled = areFieldsPopulated || (transient?.regions?.length > 0 && !hasAcknowledgement);

  useEffect(() => {
    const allRequiredFieldsPopulated = areRequiredFieldsPopulated(displayedFields, transient);
    setAreFieldsPopulated(!allRequiredFieldsPopulated);
  }, [displayedFields, transient]);

  useEffect(() => {
    updateTransientProps({ language: 'en' });
    can_simple_create_users &&
      updateTransientProps({ role: ROLES.APPLICANT, pins: selectedMcPin, country: user?.country });
    getMarketingCompanies();
  }, []);

  useEffect(() => {
    if (!can_simple_create_users) {
      const resetedKeys = resetKeys();
      updateTransientProps(resetedKeys);
    }
    setRegionsAcknowledgement(false);
  }, [role]);

  useEffect(() => {
    updateTransientProps({ regions: [] });
    setRegionsAcknowledgement(false);
  }, [country]);

  useEffect(() => {
    !can_simple_create_users && updateTransientProps({ marketing_companies: mcCompaniesName });
  }, [pins]);

  return (
    <Page id={'create-user'}>
      <AppHeader
        goBack
        hideAvatar
        title={I18n.t('admin:createUser')}
        handleClick={navigateBack.bind(null, { cleanUpTransientProps })}
      />
      <ColumnView paddingBottom={16}>
        <List className="w-full">
          {displayedFields.map(renderField.bind(null, { transient, regionNeedsLeukaartSelected, otherProps }))}
        </List>

        <ColumnView paddingHorizontal={16} gap={8}>
          <Button.Primary
            processing={createUserLoading}
            disabled={isSaveDisabled}
            width={'100%'}
            onClick={handleCreateUser.bind(null, {
              createUser,
              createUserFields,
              displayedFields,
              transient,
              user
            })}>
            {I18n.t('general:save')}
          </Button.Primary>

          <Button.Outline width={'100%'} onClick={navigateBack.bind(null, { cleanUpTransientProps })}>
            {I18n.t('general:cancel')}
          </Button.Outline>
        </ColumnView>
      </ColumnView>
    </Page>
  );
};

CreateUser.propTypes = {
  admin: PropTypes.object,
  cleanUpTransientProps: PropTypes.func,
  createUser: PropTypes.func,
  getCoach: PropTypes.func,
  getMarketingCompanies: PropTypes.func,
  getRecruiter: PropTypes.func,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object
};

export default CreateUser;
