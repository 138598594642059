import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Page } from 'framework7-react';

import Config from 'Config';
import { Plus } from 'Icons';
import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { AppHeader, Banner, Button, Pagination, QuickFilters, Table } from 'Components';
import { ADMIN_ROLES, ALERT_TYPES, FILTERS, PAGE_NAMES, TABLE_CONFIG } from 'Constants';

import './style.scss';
import BulkModal from './BulkModal';

const columns = [
  {
    name: I18n.t('admin:onboardingTable.user_name'),
    field: 'name'
  },
  { name: I18n.t('admin:onboardingTable.badge_number'), field: 'badgeNumbers' }
];

const navigateToReleaseBlock = updateTransientProps => {
  updateTransientProps({ fromDashboard: true });
  NavigationService.navigate({ name: PAGE_NAMES.BULK_RELEASE_BLOCK });
};

const navigateToCreateUser = resetTransient => {
  resetTransient();
  NavigationService.navigate({ name: PAGE_NAMES.CREATE_USER });
};

const Dashboard = ({
  adminFilters = {},
  block = '',
  campaigns = [],
  checkSendWelcomeEmail = mockFunction,
  checkVetting = mockFunction,
  checkVettingBannerContent = '',
  clients = [],
  custom_footer = '',
  declineProfiles = mockFunction,
  email = '',
  email_error_message = '',
  email_has_error = false,
  errorsModal = false,
  extendVetting = mockFunction,
  extendVettingBannerContent = '',
  filters = {},
  getAdminOnboardingProfiles = mockFunction,
  getAdminOnboardingProfilesLoading = false,
  getOnboardingBlocks = mockFunction,
  getReOnboardingCampaigns = mockFunction,
  getUserOnboardingProfile = mockFunction,
  onboardings = {},
  profilesWithErrors = [],
  profilesWithoutErrors = '',
  reactivateProfileBannerContent = '',
  reactivateProfiles = mockFunction,
  rejectVetting = mockFunction,
  rejectVettingBannerContent = '',
  releaseBlockBannerContent = '',
  reOnboard = mockFunction,
  reOnboardLoading = false,
  resetTransient = mockFunction,
  reuse_existing_badge_number = true,
  selected_client = '',
  selected_signer = '',
  selectedCampaigns = [],
  selectedProfilesForReOnboard = mockFunction,
  selectedRows = [],
  sendWelcomeEmail = mockFunction,
  showCheckVettingBanner = false,
  showExtendVettingBanner = false,
  showReactivateProfileBanner = false,
  showRejectVettingBanner = false,
  showReleaseBlockBanner = false,
  showReonboardModal = false,
  showWelcomeEmailBanner = false,
  showWelcomeEmailModal = false,
  signers = [],
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserProps = mockFunction,
  user = {},
  user_role = '',
  welcomeEmailBannerContent = ''
}) => {
  const { meta } = onboardings;
  const isOneRowSelected = selectedRows?.length === 1;
  const userEmail = isOneRowSelected ? selectedRows[0].email : '';
  const noneIsSelected = selectedRows?.length <= 0;
  const isAdmin = user_role === ADMIN_ROLES.ADMIN;
  const isOwner = user_role === ADMIN_ROLES.OWNER;
  const isNotAdmin = user_role !== ADMIN_ROLES.ADMIN;

  const handleDrawerButtonAction = row => {
    getUserOnboardingProfile(row.id, 'seeDetails');
    getOnboardingBlocks(row.id);
    updateAdminProps({ selectedUser: [row] });
    updateTransientProps({ fromDashboard: false });
  };

  const bubbleActions = [
    {
      label: I18n.t('admin:menuItems.sendWelcomeEmail'),
      disabled: noneIsSelected,
      onClick: checkSendWelcomeEmail.bind(null, { email: userEmail, selectedRows })
    },
    {
      label: I18n.t('admin:menuItems.checkVetting'),
      hide: !isAdmin,
      disabled: noneIsSelected,
      onClick: checkVetting.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.declineVetting'),
      disabled: noneIsSelected,
      onClick: declineProfiles.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.extendVetting'),
      disabled: noneIsSelected,
      onClick: extendVetting.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.rejectVetting'),
      disabled: noneIsSelected,
      onClick: rejectVetting.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.reOnboard'),
      disabled: noneIsSelected,
      onClick: selectedProfilesForReOnboard.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.reActivate'),
      hide: isOwner,
      disabled: noneIsSelected,
      onClick: reactivateProfiles.bind(null, selectedRows)
    },
    {
      label: I18n.t('admin:menuItems.releaseBlock'),
      disabled: noneIsSelected,
      onClick: navigateToReleaseBlock.bind(null, updateTransientProps)
    }
  ];

  const renderBanner = () => {
    const banners = [
      {
        content: I18n.t('admin:successBannerMessages.welcomeEmail', {
          welcomeEmailBannerContent
        }),
        show: showWelcomeEmailBanner,
        onClose: updateTransientProps.bind(null, { showWelcomeEmailBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.extendVetting', {
          extendVettingBannerContent
        }),
        show: showExtendVettingBanner,
        onClose: updateTransientProps.bind(null, { showExtendVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.checkVetting', {
          checkVettingBannerContent
        }),
        show: showCheckVettingBanner,
        onClose: updateTransientProps.bind(null, { showCheckVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.rejectVetting', {
          rejectVettingBannerContent
        }),
        show: showRejectVettingBanner,
        onClose: updateTransientProps.bind(null, { showRejectVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.reactivateProfile', {
          reactivateProfileBannerContent
        }),
        show: showReactivateProfileBanner,
        onClose: updateTransientProps.bind(null, { showReactivateProfileBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.blockRelease', {
          releaseBlockBannerContent,
          blockNumber: block
        }),
        show: showReleaseBlockBanner,
        onClose: updateTransientProps.bind(null, { showReleaseBlockBanner: false })
      }
    ];

    const activeBanner = banners.find(banner => banner.show);

    return (
      <>
        {activeBanner && (
          <Banner
            type={ALERT_TYPES.SUCCESS}
            content={activeBanner.content}
            show={activeBanner}
            timer={Config.GROWL_AUTOHIDE}
            onClose={activeBanner.onClose}
          />
        )}
      </>
    );
  };

  return (
    <Page id={'dashboard'}>
      <AppHeader
        hideAvatar
        showMcPins={isNotAdmin}
        user
        banner={renderBanner()}
        actions={bubbleActions}
        goBack
        handleClick={NavigationService.navigate.bind(null, { name: PAGE_NAMES.ADMIN })}
        onClickMcPin={user?.mc_pins?.length > 1 ? updateTransientProps.bind(null, { mcPinsModal: true }) : mockFunction}
        title={I18n.t('admin:dashboard')}
      />

      <div className="table-container">
        <QuickFilters
          filtersType={FILTERS.ONBOARDING}
          getItemsFunction={getAdminOnboardingProfiles}
          meta={meta}
          role={user_role}
          transient={transient}
          quickFilters={adminFilters}
          updateUserProps={updateUserProps}
          updateTransientProps={updateTransientProps}
        />

        <Table
          rows={onboardings}
          columns={columns}
          processing={getAdminOnboardingProfilesLoading}
          userRole={user_role}
          drawerButtonAction={handleDrawerButtonAction}
        />

        <ColumnView width={'100%'} paddingBottom={65}>
          <Pagination
            loading={getAdminOnboardingProfilesLoading}
            filters={filters}
            totalNumberOfPages={meta?.total_pages}
            getItemsFunction={getAdminOnboardingProfiles}
            numberOfElements={TABLE_CONFIG.ROWS}
          />
        </ColumnView>
      </div>

      <Fab className={'w-full px-16'} position={'center-bottom'} slot={'fixed'}>
        <Button.Outline width={'100%'} iconLeft={<Plus />} onClick={navigateToCreateUser.bind(null, resetTransient)}>
          {I18n.t('admin:createUser')}
        </Button.Outline>
      </Fab>

      <BulkModal
        campaigns={campaigns}
        clients={clients}
        custom_footer={custom_footer}
        email_error_message={email_error_message}
        email_has_error={email_has_error}
        email={email}
        errorsModal={errorsModal}
        getReOnboardingCampaigns={getReOnboardingCampaigns}
        isOneRowSelected={isOneRowSelected}
        profilesWithErrors={profilesWithErrors}
        profilesWithoutErrors={profilesWithoutErrors}
        reOnboard={reOnboard}
        reuse_existing_badge_number={reuse_existing_badge_number}
        reOnboardLoading={reOnboardLoading}
        selectedCampaigns={selectedCampaigns}
        selected_client={selected_client}
        selected_signer={selected_signer}
        selectedRows={selectedRows}
        sendWelcomeEmail={sendWelcomeEmail}
        showReonboardModal={showReonboardModal}
        showWelcomeEmailModal={showWelcomeEmailModal}
        signers={signers}
        updateTransientProps={updateTransientProps}
        updateTransientPropWithValidations={updateTransientPropWithValidations}
      />
    </Page>
  );
};

Dashboard.propTypes = {
  adminFilters: PropTypes.object,
  block: PropTypes.string,
  campaigns: PropTypes.array,
  checkSendWelcomeEmail: PropTypes.func,
  checkVetting: PropTypes.func,
  checkVettingBannerContent: PropTypes.string,
  clients: PropTypes.array,
  custom_footer: PropTypes.string,
  declineProfiles: PropTypes.func,
  email_error_message: PropTypes.string,
  email_has_error: PropTypes.bool,
  email: PropTypes.string,
  errorsModal: PropTypes.bool,
  extendVetting: PropTypes.func,
  extendVettingBannerContent: PropTypes.string,
  filters: PropTypes.object,
  getAdminOnboardingProfiles: PropTypes.func,
  getAdminOnboardingProfilesLoading: PropTypes.bool,
  getOnboardingBlocks: PropTypes.func,
  getReOnboardingCampaigns: PropTypes.func,
  getUserOnboardingProfile: PropTypes.func,
  onboardings: PropTypes.object,
  profilesWithErrors: PropTypes.array,
  profilesWithoutErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reactivateProfileBannerContent: PropTypes.string,
  reactivateProfiles: PropTypes.func,
  rejectVetting: PropTypes.func,
  rejectVettingBannerContent: PropTypes.string,
  releaseBlockBannerContent: PropTypes.string,
  reOnboard: PropTypes.func,
  reOnboardLoading: PropTypes.bool,
  resetTransient: PropTypes.func,
  reuse_existing_badge_number: PropTypes.bool,
  selected_client: PropTypes.string,
  selected_signer: PropTypes.string,
  selectedCampaigns: PropTypes.array,
  selectedProfilesForReOnboard: PropTypes.func,
  selectedRows: PropTypes.array,
  sendWelcomeEmail: PropTypes.func,
  showCheckVettingBanner: PropTypes.bool,
  showExtendVettingBanner: PropTypes.bool,
  showReactivateProfileBanner: PropTypes.bool,
  showRejectVettingBanner: PropTypes.bool,
  showReleaseBlockBanner: PropTypes.bool,
  showReonboardModal: PropTypes.bool,
  showWelcomeEmailBanner: PropTypes.bool,
  showWelcomeEmailModal: PropTypes.bool,
  signers: PropTypes.array,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUserProps: PropTypes.func,
  user_role: PropTypes.string,
  user: PropTypes.object,
  welcomeEmailBannerContent: PropTypes.string
};

export default Dashboard;
