import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { I18n } from 'Locales';
import { colors } from 'Theme';
import { Avatar } from 'Components';
import { APP_MODES } from 'Constants';
import { isSmallDevice } from 'Helpers';
import { ColumnView, RowView } from 'Containers';

import './style.scss';

const AgentInfo = props => {
  const { currentFlowBadgeNumber, flow_signature, mode, user, userAvatar, userFullName, validBadgeNumber } = props;
  const { showBaFullName, showBaBadgeNumber, showBaPicture, showCurrentDate, legalStatement } = flow_signature;

  const donorMode = mode === APP_MODES.DONOR;

  return (
    <RowView paddingHorizontal={15}>
      <ColumnView width={'auto'} flexGrow={4} justifyContent={'space-between'}>
        {!isSmallDevice ? (
          <RowView justifyContent={'space-between'} flexWrap={'wrap'}>
            <div className="agent-info">
              {showBaFullName && userFullName && (
                <ColumnView alignItems={'flex-start'}>
                  <div className="item-title text-color-gray">{I18n.t('flow:components.agentInfo.brandAmbasador')}</div>
                  <span>{userFullName}</span>
                  <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
                </ColumnView>
              )}

              {showBaBadgeNumber && validBadgeNumber && (
                <ColumnView alignItems={'flex-start'}>
                  <div className="item-title text-color-gray">{I18n.t('flow:components.agentInfo.badgeNumber')}</div>
                  <span>{currentFlowBadgeNumber}</span>
                  <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
                </ColumnView>
              )}

              {showCurrentDate && (
                <ColumnView alignItems={'flex-start'}>
                  <div className="item-title text-color-gray">{I18n.t('flow:components.agentInfo.signDate')}</div>
                  <span>{new Date().toLocaleDateString()}</span>
                  <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
                </ColumnView>
              )}
            </div>
          </RowView>
        ) : (
          <>
            {showBaFullName && userFullName && (
              <ColumnView alignItems={'flex-start'} width={'100%'}>
                <div>
                  <span className="item-title text-color-gray">
                    {`${I18n.t('flow:components.agentInfo.brandAmbasador')}:`}
                  </span>
                  <span className="pl-6">{userFullName}</span>
                </div>
                <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
              </ColumnView>
            )}

            <RowView justifyContent={'flex-start'}>
              <div className="agent-info-small">
                {showBaBadgeNumber && validBadgeNumber && (
                  <ColumnView alignItems={'flex-start'}>
                    <div className="item-title text-color-gray">{I18n.t('flow:components.agentInfo.badgeNumber')}</div>
                    <span>{currentFlowBadgeNumber}</span>
                    <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
                  </ColumnView>
                )}

                {showCurrentDate && (
                  <ColumnView alignItems={'flex-start'}>
                    <div className="item-title text-color-gray">{I18n.t('flow:components.agentInfo.signDate')}</div>
                    <span>{new Date().toLocaleDateString()}</span>
                    <RowView backgroundColor={colors.darkGrey} height={1} marginTop={5} marginBottom={10} />
                  </ColumnView>
                )}
              </div>
            </RowView>
          </>
        )}

        {legalStatement && (
          <Fragment>
            <RowView>
              <ColumnView alignItems={'flex-start'}>
                <div className="item-title item-label">{I18n.t('flow:components.agentInfo.legalInfo')}</div>
                <span>{legalStatement}</span>
              </ColumnView>
            </RowView>
            <RowView marginTop={5} marginVertical={5} />
          </Fragment>
        )}
      </ColumnView>

      {showBaPicture && !donorMode && (
        <RowView flexGrow={1} width={'auto'} flexBasis={null}>
          <Avatar
            avatarInitials={!donorMode}
            user={user}
            source={userAvatar}
            size={isSmallDevice ? 80 : 120}
            slot={'media'}
          />
        </RowView>
      )}
    </RowView>
  );
};

AgentInfo.propTypes = {
  currentFlowBadgeNumber: PropTypes.string,
  flow_signature: PropTypes.object,
  mode: PropTypes.string,
  user: PropTypes.object,
  userAvatar: PropTypes.string,
  userFullName: PropTypes.string,
  validBadgeNumber: PropTypes.any
};

export default AgentInfo;
