import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as transientActions } from 'Reducers/transient';

import AdvancedFilters from './view';

export default connect(
  state => ({
    ...state.transient,
    adminFilters: state.user.adminFilters,
    history: state.application.routing.history
  }),
  {
    updateTransientProps: transientActions.updateProps,
    updateUserProps: userActions.updateProps
  }
)(AdvancedFilters);
