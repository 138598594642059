import PropTypes from 'prop-types';
import { Page } from 'framework7-react';
import React, { useCallback, useEffect } from 'react';

import Config from 'Config';
import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { AppHeader, Button } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { PAGE_NAMES, ONBOARDING_PROFILE_STATES } from 'Constants';

import { ReleaseBlock } from 'Admin/Onboarding/Profile/subviews/Components';

const BulkReleaseBlock = ({
  admin = {},
  getCampaignsByClient = mockFunction,
  getClients = mockFunction,
  getSigners = mockFunction,
  releaseBlock = mockFunction,
  transient = {},
  updateGrowlProps = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const currentSelection = transient?.fromDashboard ? transient?.selectedRows || [] : admin?.selectedUser || [];
  const campaigns =
    Array.isArray(currentSelection) && currentSelection?.length > 0 ? currentSelection[0]?.campaigns : null;
  const uniqueCampaigns = campaigns?.filter((value, index, self) => self.indexOf(value) === index);
  const uniqueCampaignsList = campaigns?.length > 0 ? uniqueCampaigns : null;

  const showGrowlError = (titleKey, bodyKey) => {
    updateGrowlProps({
      visible: true,
      title: I18n.t(titleKey),
      body: I18n.t(bodyKey),
      kind: 'error'
    });

    updateTransientProps({ [transient.modalKey]: false });

    setTimeout(() => {
      updateGrowlProps({ visible: false });
    }, Config.GROWL_AUTOHIDE);
  };

  const blocksConfig = useCallback(
    () => [
      { modalKey: 'releaseBlock1Modal', block: 1 },
      { modalKey: 'releaseBlock2Modal', block: 2 },
      { modalKey: 'releaseBlock3Modal', block: 3 },
      { modalKey: 'releaseBlock4Modal', block: 4 }
    ],
    []
  );

  const handleClick = useCallback(
    block => {
      if (block.block === 3 && !hasSameCampaignId) {
        showGrowlError('growl:error.bulkReleaseBlockFail.title', 'growl:error.bulkReleaseBlockFail.body');
      } else if (hasProfileCancelled) {
        showGrowlError('growl:error.profileCanceledBulkFail.title', 'growl:error.profileCanceledBulkFail.body');
      } else {
        updateTransientProps({
          displayedCampaigns: uniqueCampaignsList,
          modalKey: block.modalKey,
          block: block.block,
          [block.modalKey]: true
        });
      }
    },
    [hasProfileCancelled, hasSameCampaignId, transient, updateGrowlProps, updateTransientProps]
  );

  useEffect(() => {
    getSigners(currentSelection[0]?.marketing_company?.id);
    getClients();
  }, []);

  const selectedUsersIds = Array.isArray(currentSelection) ? currentSelection.map(row => row?.id) : [];
  const hasProfileCancelled = Array.isArray(currentSelection)
    ? currentSelection.some(row => row.state === ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED)
    : false;
  const selectedUsers = Array.isArray(currentSelection) ? currentSelection.map(row => row.name).join(', ') : [];
  const firstCampaignId =
    Array.isArray(currentSelection) && currentSelection.length > 0 ? currentSelection[0]?.campaigns[0]?.id : '';
  const hasSameCampaignId =
    Array.isArray(currentSelection) && currentSelection.length > 0
      ? currentSelection.every(
          row =>
            row?.campaigns?.every(
              campaign => campaign?.id === firstCampaignId || (campaign.id === null && firstCampaignId === null)
            ) || currentSelection.length === 1
        )
      : '';

  return (
    <Page>
      <AppHeader
        hideAvatar
        goBack
        title={I18n.t('admin:releaseBlock')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.DASHBOARD })}
      />
      <ColumnView paddingTop={16}>
        {blocksConfig().map((block, index) => (
          <RowView paddingHorizontal={20} paddingBottom={16} key={index}>
            <Button.Outline width={'100%'} disabled={false} onClick={handleClick.bind(null, block)}>
              {`Block ${block.block}`}
            </Button.Outline>
          </RowView>
        ))}
      </ColumnView>

      <ReleaseBlock
        blockNumber={admin?.currentPage}
        bulkCampaignId={firstCampaignId}
        bulkIds={selectedUsersIds}
        getCampaignsByClient={getCampaignsByClient}
        isOpen={transient?.[transient.modalKey]}
        onClose={updateTransientProps.bind(null, {
          modalKey: '',
          [transient.modalKey]: false,
          block: '',
          nextBlock: '',
          releaseNextBlock: false
        })}
        releaseBlock={releaseBlock}
        showCampaign={transient.block === 3}
        showClients={transient.block === 3}
        showOptions={transient.block === 3}
        showSigner={true}
        subtitle={`${I18n.t('admin:documentsWillBeSendTo')} ${selectedUsers}`}
        title={I18n.t('admin:releaseBlockNumber', { block: transient.block })}
        transient={transient}
        updateTransientProps={updateTransientProps}
      />
    </Page>
  );
};

BulkReleaseBlock.propTypes = {
  admin: PropTypes.object,
  getCampaignsByClient: PropTypes.func,
  getClients: PropTypes.func,
  getSigners: PropTypes.func,
  releaseBlock: PropTypes.func,
  transient: PropTypes.object,
  updateGrowlProps: PropTypes.func,
  updateTransientProps: PropTypes.func
};

export default BulkReleaseBlock;
