import { connect } from 'react-redux';

import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import CreateUser from './view';

export default connect(
  state => ({
    admin: state.admin,
    transient: state.transient,
    user: state.user
  }),
  {
    cleanUpTransientProps: transientActions.cleanUpTransientProps,
    createUser: adminActions.createUser,
    getCoach: adminActions.getCoach,
    getMarketingCompanies: adminActions.getMarketingCompanies,
    getMcPin: adminActions.getMcPin,
    getRecruiter: adminActions.getRecruiter,
    resetTransient: transientActions.reset,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations
  }
)(CreateUser);
