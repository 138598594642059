import React from 'react';

import PropTypes from 'prop-types';

const Contact = ({ fillColor = '#1C1B1F' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none">
    <path
      fill={fillColor}
      // eslint-disable-next-line max-len
      d="M2.308 15.5c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 0 1 .5 13.692V2.308c0-.505.175-.933.525-1.283C1.375.675 1.803.5 2.308.5h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H2.308ZM18 3.442l-7.514 4.81a1.181 1.181 0 0 1-.238.103.848.848 0 0 1-.496 0 1.181 1.181 0 0 1-.239-.103L2 3.442v10.25a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087h15.385a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V3.442ZM10 7l7.846-5H2.154L10 7ZM2 3.673V2.53v.03-.032 1.145Z"
    />
  </svg>
);

export default Contact;

Contact.propTypes = {
  fillColor: PropTypes.string
};
