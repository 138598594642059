import { connect } from 'react-redux';

import { Creators as GrowlActions } from 'Reducers/growl';
import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import BulkReleaseBlock from './view';

export default connect(
  state => ({
    admin: state.admin,
    transient: state.transient
  }),
  {
    getCampaignsByClient: adminActions.getCampaignsByClient,
    getClients: adminActions.getClients,
    getSigners: adminActions.getSigners,
    releaseBlock: adminActions.releaseBlock,
    updateGrowlProps: GrowlActions.updateProps,
    updateTransientProps: transientActions.updateProps
  }
)(BulkReleaseBlock);
