import { connect } from 'react-redux';

import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import Admin from './view';

export default connect(
  state => ({
    admin: state.admin,
    transient: state.transient,
    user: state.user
  }),
  {
    getMcPin: adminActions.getMcPin,
    updateAdminProps: adminActions.updateProps,
    updateTransientProps: transientActions.updateProps
  }
)(Admin);
