import React from 'react';

const VerticalDots = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      //eslint-disable-next-line no-useless-escape, max-len
      d="M10.0002 4.93333C10.2124 4.93333 10.4159 4.84905 10.5659 4.69902C10.7159 4.54899 10.8002 4.34551 10.8002 4.13333C10.8002 3.92116 10.7159 3.71768 10.5659 3.56765C10.4159 3.41762 10.2124 3.33333 10.0002 3.33333C9.78802 3.33333 9.58454 3.41762 9.43451 3.56765C9.28448 3.71768 9.2002 3.92116 9.2002 4.13333C9.2002 4.34551 9.28448 4.54899 9.43451 4.69902C9.58454 4.84905 9.78802 4.93333 10.0002 4.93333ZM10.0002 10.8C10.2124 10.8 10.4159 10.7157 10.5659 10.5657C10.7159 10.4157 10.8002 10.2122 10.8002 10C10.8002 9.78783 10.7159 9.58434 10.5659 9.43431C10.4159 9.28429 10.2124 9.2 10.0002 9.2C9.78802 9.2 9.58454 9.28429 9.43451 9.43431C9.28448 9.58434 9.20019 9.78783 9.20019 10C9.20019 10.2122 9.28448 10.4157 9.43451 10.5657C9.58454 10.7157 9.78802 10.8 10.0002 10.8ZM10.0002 16.6667C10.2124 16.6667 10.4158 16.5824 10.5659 16.4324C10.7159 16.2823 10.8002 16.0788 10.8002 15.8667C10.8002 15.6545 10.7159 15.451 10.5659 15.301C10.4158 15.151 10.2124 15.0667 10.0002 15.0667C9.78802 15.0667 9.58454 15.151 9.43451 15.301C9.28448 15.451 9.20019 15.6545 9.20019 15.8667C9.20019 16.0788 9.28448 16.2823 9.43451 16.4324C9.58454 16.5824 9.78802 16.6667 10.0002 16.6667Z"
      fill="#444444"
      stroke="#444444"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VerticalDots;
