import { createActions, createReducer } from 'reduxsauce';

import { setInitial, updateProp, updateProps, validateProp } from '../shared';

export const { Types, Creators } = createActions(
  {
    cleanUpTransientProps: ['keys'],
    reset: null,
    setPostCodes: ['payload'],
    updateProp: ['key', 'value'],
    updateProps: ['props'],
    updatePropWithValidations: ['key', 'value', 'validations'],
    validateProp: ['prop']
  },
  { prefix: 'transient/' }
);

const initialState = {
  filteredAddressByPostCodes: []
};

export const reset = () => initialState;

export const updatePropWithValidations = (state, { key, value, validations = [] }) => {
  if (!Array.isArray(validations) || validations.length === 0) {
    return {
      ...state,
      [key]: value,
      [`${key}_has_error`]: false,
      [`${key}_error_message`]: '',
      [`${key}_error_message_key`]: ''
    };
  }

  let errorMessage = '';
  let errorMessageKey = '';
  const hasError = validations.some(validation => {
    const isValid = validation?.isValid(value);
    if (!isValid) {
      errorMessage = validation?.message;
      errorMessageKey = validation?.messageKey;
    }
    return !isValid;
  });

  return {
    ...state,
    [key]: value,
    [`${key}_has_error`]: hasError,
    [`${key}_error_message`]: errorMessage,
    [`${key}_error_message_key`]: errorMessageKey
  };
};

export const setPostCodes = (state, { payload }) => {
  const newState = setInitial(state, initialState);
  newState['filteredAddressByPostCodes'] = payload;
  return { ...newState };
};

export const cleanUpTransientProps = (state, { keys }) => {
  // This function keeps only the specified keys in the state, removing all others.
  const newState = {};
  if (Array.isArray(keys)) {
    keys.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        newState[key] = state[key];
      }
    });
  }

  return newState;
};

export default createReducer(initialState, {
  [Types.CLEAN_UP_TRANSIENT_PROPS]: cleanUpTransientProps,
  [Types.RESET]: reset,
  [Types.SET_POST_CODES]: setPostCodes,
  [Types.UPDATE_PROP_WITH_VALIDATIONS]: updatePropWithValidations,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps,
  [Types.VALIDATE_PROP]: validateProp
});

export const transient = state => state.transient;
