import { call, put, select } from 'redux-saga/effects';

import { Api } from 'Core';
import { I18n } from 'Locales';
import { Admin } from 'Repositories';
import { NavigationService } from 'Services';
import { Types as GrowlTypes } from 'Reducers/growl';
import { user as userSelector } from 'Reducers/user';
import { formatDate, getStageQueryParams } from 'Helpers';
import { transient as transientSelector, Types as TransientTypes } from 'Reducers/transient';
import { CREATE_USER_OPTIONS, ONBOARDING_PROFILE_STATES, ONBOARDING_PROFILE_TABS, PAGE_NAMES } from 'Constants';
import {
  admin as adminSelector,
  blocks as blocksSelector,
  currentTabs as currentTabsSelector,
  onboardingUserProfile as onboardingUserProfileSelector,
  Types as AdminTypes
} from 'Reducers/admin';

export const getAdminOnboardingProfiles = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES_SUCCESS },
      fail: { type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES_FAIL }
    },
    promise: Admin.getAdminOnboardingProfiles(data)
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminOnboardingProfilesLoading: true }
  });
};

export const getAdminOnboardingProfilesSuccess = function* ({ payload, meta }) {
  const sanitizedPayload = payload?.map(profile => {
    const sanitizedProfile = { ...profile };
    sanitizedProfile.badges = sanitizedProfile.badges?.map(badge => {
      const sanitizedOnboardingProfile = { ...badge.onboarding_profile };
      delete sanitizedOnboardingProfile.badges;

      return {
        ...badge,
        onboarding_profile: sanitizedOnboardingProfile
      };
    });
    if (sanitizedProfile.reonboarding_profile) {
      sanitizedProfile.reonboarding_profile.badges = sanitizedProfile.reonboarding_profile.badges?.map(badge => {
        const sanitizedOnboardingProfile = { ...badge.onboarding_profile };
        delete sanitizedOnboardingProfile.badges;

        return {
          ...badge,
          onboarding_profile: sanitizedOnboardingProfile
        };
      });
    }

    return sanitizedProfile;
  });

  const transformPayload = payload =>
    payload.map(profile => ({
      ...profile,
      badgeNumbers: profile?.badges?.map(badge => badge?.number),
      campaignNames: [...new Set(profile?.campaigns?.map(campaign => campaign?.name))]
    }));

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: { onboardings: { payload: transformPayload(sanitizedPayload), meta } }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminOnboardingProfilesLoading: false }
  });
};

export const getAdminOnboardingProfilesFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: { getAdminOnboardingProfilesLoading: false }
  });
};

export const declineOnboardingProfile = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.DECLINE_ONBOARDING_PROFILE_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.declineProfiles(id)
  });
};

export const declineOnboardingProfileSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });
};

export const declineProfiles = function* ({ data }) {
  const profilesIds = data.map(item => item.id).toString();

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.DECLINE_PROFILES_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.declineProfiles(profilesIds)
  });
};

export const declineProfilesSuccess = function* ({ payload }) {
  const profilesWithErrors = payload.filter(item => Object.keys(item?.resource_errors || {}).length > 0);
  const profilesWithoutErrors = payload.filter(item => !profilesWithErrors.includes(item));

  if (profilesWithErrors.length > 0) {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        errorsModal: true,
        profilesWithErrors: profilesWithErrors,
        profilesWithoutErrors: profilesWithoutErrors.length
      }
    });
  }
  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload: profilesWithoutErrors });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selectedRows: []
    }
  });
};

export const reactivateOnboardingProfile = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.REACTIVATE_ONBOARDING_PROFILE_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.reactivateProfiles(id)
  });
};

export const reactivateOnboardingProfileSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);
  const names = payload?.map(item => item.user_name).join(', ');

  if (payload[0]?.resource_errors?.base?.length > 0) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.updateUser.title'),
      body: payload[0].resource_errors.base[0],
      kind: 'error'
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        reactivateProfileBannerContent: names,
        showReactivateProfileBanner: true
      }
    });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        userOnboardingProfile: {
          ...payload[0],
          user: onboardingUserProfile.user
        }
      }
    });

    yield put({
      type: AdminTypes.UPDATE_PROPS,
      props: {
        userOnboardingProfile: {
          ...payload[0],
          user: onboardingUserProfile.user
        }
      }
    });
  }
};

export const reactivateProfiles = function* ({ data }) {
  const allProfilesCancelled = data?.every(item =>
    item.more_details?.some(
      detail => detail.field_name === 'state' && detail.value === ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED
    )
  );
  const profilesIds = data?.map(item => item.id).toString();

  if (!allProfilesCancelled) {
    yield put({
      type: GrowlTypes.ALERT,
      body: I18n.t('growl:error.reactivateProfiles.body'),
      kind: 'error'
    });
  } else {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: AdminTypes.REACTIVATE_PROFILES_SUCCESS },
        fail: { type: Api.API_ERROR }
      },
      promise: Admin.reactivateProfiles(profilesIds)
    });
  }
};

export const reactivateProfilesSuccess = function* ({ payload }) {
  const profilesWithErrors = payload.filter(item => Object.keys(item?.resource_errors || {}).length > 0);
  const profilesWithoutErrors = payload.filter(item => !profilesWithErrors.includes(item));
  const names = payload?.map(item => item.user_name).join(', ');

  if (profilesWithErrors.length > 0) {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        errorsModal: true,
        profilesWithErrors: profilesWithErrors,
        profilesWithoutErrors: profilesWithoutErrors.length
      }
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      reactivateProfileBannerContent: names,
      showReactivateProfileBanner: true
    }
  });
  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload: profilesWithoutErrors });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selectedRows: []
    }
  });
};

export const extendOnboardingProfileVetting = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.EXTEND_ONBOARDING_PROFILE_VETTING_SUCCESS },
      fail: { type: GrowlTypes.ALERT, body: I18n.t('growl:error.extendVettingFail.body'), kind: 'error' }
    },
    promise: Admin.extendVetting(id)
  });
};

export const extendOnboardingProfileVettingSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);
  const { user_name } = onboardingUserProfile;

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      extendVettingBannerContent: user_name,
      showExtendVettingBanner: true
    }
  });

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });
};

export const extendVetting = function* ({ data }) {
  const profilesIds = data?.map(item => item.id).toString();

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.EXTEND_VETTING_SUCCESS },
      fail: { type: GrowlTypes.ALERT, body: I18n.t('growl:error.extendVettingFail.body'), kind: 'error' }
    },
    promise: Admin.extendVetting(profilesIds)
  });
};

export const extendVettingSuccess = function* ({ payload }) {
  const names = payload.map(item => item.user_name).join(', ');

  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selectedRows: []
    }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      extendVettingBannerContent: names,
      showExtendVettingBanner: true
    }
  });
};

export const rejectOnboardingProfileVetting = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.REJECT_ONBOARDING_PROFILE_VETTING_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.rejectVetting(id)
  });
};

export const rejectOnboardingProfileVettingSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);
  const { user_name } = onboardingUserProfile;

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      rejectVettingBannerContent: user_name,
      showRejectVettingBanner: true
    }
  });

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });
};

export const rejectVetting = function* ({ data }) {
  const profilesIds = data?.map(item => item.id).toString();

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.REJECT_VETTING_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.rejectVetting(profilesIds)
  });
};

export const rejectVettingSuccess = function* ({ payload }) {
  const names = payload.map(item => item.user_name).join(', ');

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      rejectVettingBannerContent: names,
      showRejectVettingBanner: true
    }
  });
  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selectedRows: []
    }
  });
};

export const getMcPin = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_MC_PIN_SUCCESS, data },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getMcPin(data)
  });
};

export const getMcPinSuccess = function* ({ data }) {
  yield put({
    type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES,
    data: { mc_pin: data }
  });
};

export const getUserOnboardingProfile = function* ({ id, callback }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_USER_ONBOARDING_PROFILE_SUCCESS, callback },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getUserOnboardingProfile(id)
  });
};

export const getUserOnboardingProfileSuccess = function* ({ payload, callback }) {
  const user = yield select(userSelector);
  const admin = yield select(adminSelector);
  const transient = yield select(transientSelector);
  const { releaseNextBlock } = transient;

  let redirectTo;
  const INVALID_STATES = [
    ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE,
    ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS,
    ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED
  ];
  const role = user?.role_name === 'Admin' ? 'by_admin' : 'by_mc_admin';
  const formattedCreatedAt = formatDate(payload.created_at);
  const formattedUpdatedAt = formatDate(payload.updated_at);
  const displayBadgesTab = payload?.badges?.length > 0;
  const { onboardingProfilePages } = admin;
  const sectionsNeedAttention =
    payload?.accepted_sections && payload.accepted_sections[role]
      ? Object.values(payload.accepted_sections[role]).filter(value => value === false).length > 0
      : false;
  const documentsNeedAttention = payload?.documents_waiting_for_approval_count > 0;
  const navigationFunction = callback === 'seeDetails' ? NavigationService.navigate : NavigationService.goBack;

  if (sectionsNeedAttention) {
    redirectTo = ONBOARDING_PROFILE_TABS.BASIC_PROFILE;
  } else if (documentsNeedAttention) {
    redirectTo = payload?.first_incomplete_block_name;
  } else {
    redirectTo = ONBOARDING_PROFILE_TABS.DETAILS;
  }

  const sanitizedPayload = {
    ...payload,
    badges: payload?.badges?.map(badge => {
      const onboardingWithoutBadges = { ...badge?.onboarding_profile };
      delete onboardingWithoutBadges.badges;

      return {
        ...badge,
        onboarding_profile: onboardingWithoutBadges
      };
    })
  };

  const updatedOnboardingProfilePages = [...onboardingProfilePages];
  let basicProfilePageIndex = -1;
  if (displayBadgesTab) {
    basicProfilePageIndex = updatedOnboardingProfilePages.indexOf(ONBOARDING_PROFILE_TABS.BASIC_PROFILE);
  }
  if (basicProfilePageIndex !== -1) {
    if (!updatedOnboardingProfilePages.includes(ONBOARDING_PROFILE_TABS.BADGES)) {
      updatedOnboardingProfilePages.splice(basicProfilePageIndex + 1, 0, ONBOARDING_PROFILE_TABS.BADGES);
    }
  } else {
    if (!updatedOnboardingProfilePages.includes(ONBOARDING_PROFILE_TABS.BADGES)) {
      updatedOnboardingProfilePages.push(ONBOARDING_PROFILE_TABS.BADGES);
    }
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      ...payload.profile_fields,
      avatar_url: payload.user?.avatar_url,
      client_name: payload.client?.name,
      mc_pin: payload.marketing_company?.pin,
      created_at: formattedCreatedAt,
      updated_at: formattedUpdatedAt,
      state: payload.state,
      country: payload.user?.country,
      origin: payload.origin,
      campaigns: payload.campaigns,
      accepted_sections: payload.accepted_sections,
      document_confirmation: payload.user?.document_confirmation,
      userOnboardingProfile: sanitizedPayload
    }
  });
  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: sanitizedPayload,
      ...(displayBadgesTab && { onboardingProfilePages: updatedOnboardingProfilePages })
    }
  });

  if (!releaseNextBlock && callback) {
    navigationFunction({
      name: PAGE_NAMES.ONBOARDING_PROFILE
    });
  }

  if (!INVALID_STATES.includes(payload?.state)) {
    yield put({
      type: AdminTypes.UPDATE_PROPS,
      props: {
        currentPage: redirectTo
      }
    });
  }
};

export const getOnboardingBlocks = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_ONBOARDING_BLOCKS_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getOnboardingBlocks(id)
  });
};

export const getOnboardingBlocksSuccess = function* ({ payload }) {
  const currentTabs = yield select(currentTabsSelector);
  const blockNames = payload.map(block => block.name.toLowerCase().replace(/\s+/g, ''));
  const filteredTabs = currentTabs.filter(tab => tab !== ONBOARDING_PROFILE_TABS.ACTIVITY);
  const activityTab = currentTabs.includes(ONBOARDING_PROFILE_TABS.ACTIVITY) ? [ONBOARDING_PROFILE_TABS.ACTIVITY] : [];
  const tabsWithBlockNames = [...new Set([...filteredTabs, ...blockNames, ...activityTab])];

  const sanitizedPayload = payload.map(block => {
    const sanitizedBlock = { ...block };
    sanitizedBlock.onboarding_profile_documents = sanitizedBlock.onboarding_profile_documents.map(doc => {
      const sanitizedDoc = { ...doc };
      delete sanitizedDoc.block;
      return sanitizedDoc;
    });
    return sanitizedBlock;
  });

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      blocks: sanitizedPayload,
      onboardingProfilePages: tabsWithBlockNames
    }
  });
};

export const updateOnboardingProfileDocuments = function* ({ data, id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.UPDATE_ONBOARDING_PROFILE_DOCUMENTS_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.updateOnboardingProfileDocuments(data, id)
  });
};

export const updateOnboardingProfileDocumentsSuccess = function* ({ payload }) {
  const blocks = yield select(blocksSelector);
  const transient = yield select(transientSelector);

  const updatedBlocks = blocks.map(block => {
    if (block.id === payload.block.id) {
      return {
        ...block,
        completed_count: payload.block.completed_count,
        onboarding_profile_documents: block.onboarding_profile_documents.map(document =>
          document.id === payload.id ? payload : document
        )
      };
    }
    return block;
  });

  const sanitizedBlocks = updatedBlocks.map(block => {
    const sanitizedBlock = { ...block };
    sanitizedBlock.onboarding_profile_documents = sanitizedBlock.onboarding_profile_documents.map(doc => {
      const sanitizedDoc = { ...doc };
      delete sanitizedDoc.block;
      return sanitizedDoc;
    });
    return sanitizedBlock;
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      accepted_sections: transient.accepted_sections,
      rejectedFields: [],
      state: payload.onboarding_state,
      rejectionReason: '',
      selectedReason: '',
      selectedDocument: {
        ...transient.selectedDocument,
        accepted_on: payload.accepted_on,
        approved_on: payload.approved_on,
        rejection_reason: payload.rejection_reason,
        state: payload.state
      }
    }
  });

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      blocks: sanitizedBlocks
    }
  });
};

export const updateOnboardingUserProfile = function* ({ props, id, fieldName }) {
  const transient = yield select(transientSelector);
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  let data = props;

  if (fieldName !== undefined) {
    if (
      !transient[`${fieldName}_has_error`] &&
      (transient[fieldName] !== onboardingUserProfile.user[fieldName] ||
        (transient[fieldName]?.short_code &&
          transient[fieldName]?.short_code !== onboardingUserProfile.user[fieldName]))
    ) {
      const updatedData = {
        data: {
          attributes: {
            ...transient,
            nationality: transient.nationality?.nationality,
            country_of_birth: transient.country_of_birth?.short_code,
            country_of_citizenship: transient.country_of_citizenship?.short_code
          }
        }
      };
      data = updatedData;
    } else {
      return null;
    }
  }

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.UPDATE_ONBOARDING_USER_PROFILE_SUCCESS },
      fail: { type: AdminTypes.UPDATE_ONBOARDING_USER_PROFILE_FAIL }
    },
    promise: Admin.updateOnboardingUserProfile(fieldName !== undefined ? { ...data } : data, id)
  });
};

export const updateOnboardingUserProfileSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  const updatedOnboardingProfile = {
    ...onboardingUserProfile,
    user: payload
  };

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: updatedOnboardingProfile
    }
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: updatedOnboardingProfile
    }
  });
};

export const updateOnboardingUserProfileFail = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });
  yield put({
    type: GrowlTypes.ALERT,
    title: error?.response?.data?.errors?.length
      ? error?.response?.data?.errors.join(', ')
      : I18n.t('growl:error.updateUser.body'),
    kind: 'error'
  });
};

export const checkSendWelcomeEmail = function* ({ data }) {
  const { email, selectedRows } = data;
  const readyToSendEmail = selectedRows?.every(item =>
    item.more_details?.some(
      detail =>
        detail.field_name === 'state' &&
        (detail.value === ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE ||
          detail.value === ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS)
    )
  );

  if (!readyToSendEmail) {
    yield put({
      type: GrowlTypes.ALERT,
      body: I18n.t('growl:error.welcomeEmailFail.body'),
      kind: 'error'
    });
  } else {
    yield put({ type: TransientTypes.UPDATE_PROPS, props: { email, showWelcomeEmailModal: true } });
  }
};

export const sendWelcomeEmail = function* ({ data, callback }) {
  const { bulk, custom_footer, email, profileId = '', rows } = data;
  const hasMultipleUsers = rows?.length > 1;
  const selectedRowsIds = rows?.map(item => item.id).toString();

  const promise = hasMultipleUsers
    ? Admin.sendMultipleWelcomeEmails({ custom_footer, selectedRowsIds })
    : Admin.sendWelcomeEmail({
        custom_footer,
        email,
        selectedRowsIds: profileId !== '' ? profileId : selectedRowsIds
      });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.SEND_WELCOME_EMAIL_SUCCESS, rows, callback, bulk },
      fail: { type: AdminTypes.SEND_WELCOME_EMAIL_FAIL }
    },
    promise
  });
};

export const sendWelcomeEmailSuccess = function* ({ rows, callback, bulk }) {
  const transient = yield select(transientSelector);
  const { full_name } = transient;
  const names = rows?.map(row => row.name).join(', ');

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      welcomeEmailBannerContent: bulk ? names : full_name,
      showWelcomeEmailBanner: true
    }
  });

  if (bulk) yield put({ type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES, data: transient?.filters });

  if (callback) yield call(callback);
};

export const sendWelcomeEmailFail = function* ({ error }) {
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.welcomeEmailFail.title'),
    body: error?.response?.data?.errors[0],
    kind: 'error'
  });
};

export const sendWelcomeSms = function* ({ data, callback }) {
  const { custom_footer, phone_number, profileId } = data;

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.SEND_WELCOME_SMS_SUCCESS, callback },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.sendWelcomeSms({ custom_footer, phone_number, profileId })
  });
};

export const sendWelcomeSmsSuccess = function* ({ callback }) {
  const transient = yield select(transientSelector);
  const { full_name } = transient;
  if (callback) yield call(callback);

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      welcomeSmsBannerContent: full_name,
      showWelcomeSmsBanner: true
    }
  });
};

export const selectedProfilesForReOnboard = function* ({ data }) {
  const fistMcName = data[0]?.marketing_company?.name;
  const mcId = data[0]?.marketing_company?.id;
  const fromSameMc = data.every(profile => profile?.marketing_company?.name === fistMcName);
  const hasInvalidProfile = data.some(profile =>
    profile.more_details.some(
      detail =>
        detail.field_name === 'state' &&
        (detail.value === ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE ||
          detail.value === ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS ||
          detail.value === ONBOARDING_PROFILE_STATES.OFFER_NOT_MADE)
    )
  );

  if (hasInvalidProfile) {
    yield put({
      type: GrowlTypes.ALERT,
      body: I18n.t('growl:error.reonboardInvalidState.body'),
      kind: 'error'
    });
  } else if (!fromSameMc) {
    yield put({
      type: GrowlTypes.ALERT,
      body: I18n.t('growl:error.reonboardInvalidMc.body'),
      kind: 'error'
    });
  } else {
    yield put({ type: AdminTypes.GET_CLIENTS });
    yield put({ type: AdminTypes.GET_SIGNERS, mcId });
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        showReonboardModal: true
      }
    });
  }
};

export const getSigners = function* ({ mcId }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_SIGNERS_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getSigners(mcId)
  });
};

export const getSignersSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      signers: payload
    }
  });
};

export const getReOnboardingCampaigns = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_RE_ONBOARDING_CAMPAIGNS_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getReOnboardingCampaigns(data)
  });
};

export const getReOnboardingCampaignsSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      campaigns: payload
    }
  });
};

export const reOnboard = function* ({ data }) {
  const { selectedRows } = data;
  const selectedRowsIds = selectedRows?.map(item => item.id).toString();
  const formData = new FormData();
  formData.append('data[attributes][onboarding_profile_ids]', selectedRowsIds);
  formData.append('data[attributes][client_id]', data.selected_client);
  if (Array.isArray(data?.selectedCampaigns) && data?.selectedCampaigns.length > 0) {
    data?.selectedCampaigns.forEach(id => {
      formData.append('data[attributes][campaign_ids][]', id);
    });
  } else if (data?.selectedCampaigns) {
    formData.append('data[attributes][campaign_ids][]', data?.selectedCampaigns);
  }
  formData.append('data[attributes][signer_user_id]', data.selected_signer);
  formData.append('data[attributes][reuse_existing_badge_number]', data.reuse_existing_badge_number);

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      reOnboardLoading: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.RE_ONBOARD_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.reOnboard({ data: formData })
  });
};

export const reOnboardSuccess = function* ({ payload }) {
  const profilesWithErrors = payload.filter(item => Object.keys(item?.resource_errors || {}).length > 0);
  const profilesWithoutErrors = payload.filter(item => !profilesWithErrors.includes(item));

  if (profilesWithErrors.length > 0) {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        errorsModal: true,
        profilesWithErrors: profilesWithErrors,
        profilesWithoutErrors: profilesWithoutErrors.length
      }
    });
  }
  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload: profilesWithoutErrors });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selected_campaign: '',
      selected_client: '',
      selected_signer: '',
      selectedRows: [],
      showReonboardModal: false,
      reOnboardLoading: false
    }
  });
};

export const reOnboardProfile = function* ({ data }) {
  const formData = new FormData();
  formData.append('data[attributes][onboarding_profile_ids]', data.selectedRowsIds);
  formData.append('data[attributes][client_id]', data.selected_client);
  if (Array.isArray(data?.selectedCampaigns) && data?.selectedCampaigns.length > 0) {
    data?.selectedCampaigns.forEach(id => {
      formData.append('data[attributes][campaign_ids][]', id);
    });
  } else if (data?.selectedCampaigns) {
    formData.append('data[attributes][campaign_ids][]', data?.selectedCampaigns);
  }
  formData.append('data[attributes][signer_user_id]', data.selected_signer);
  formData.append('data[attributes][reuse_existing_badge_number]', data.reuse_existing_badge_number);

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      reOnboardLoading: true
    }
  });

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.RE_ONBOARD_PROFILE_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.reOnboard({ data: formData })
  });
};

export const reOnboardProfileSuccess = function* ({ payload }) {
  const transient = yield select(transientSelector);
  const { full_name } = transient;

  if (payload[0]?.resource_errors?.base?.length > 0) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.updateUser.title'),
      body: payload[0]?.resource_errors.state
        ? payload[0]?.resource_errors.state[0]
        : payload[0]?.resource_errors.base[0],
      kind: 'error'
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        reOnboardBannerContent: full_name,
        showReOnbaordBanner: true
      }
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selected_campaign: '',
      selected_client: '',
      selected_signer: '',
      showProfileReonboardModal: false,
      reOnboardLoading: false
    }
  });
};

export const checkVetting = function* ({ data }) {
  const allProfilesReadyForApproval = data.every(profile =>
    profile.more_details.some(
      detail => detail.field_name === 'state' && detail.value === ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL
    )
  );
  const selectedRowsIds = data?.map(item => item.id).toString();

  if (!allProfilesReadyForApproval) {
    yield put({
      type: GrowlTypes.ALERT,
      body: I18n.t('growl:error.checkVettingInvalid.body'),
      kind: 'error'
    });
  } else {
    yield put({
      type: Api.API_CALL,
      actions: {
        success: { type: AdminTypes.CHECK_VETTING_SUCCESS },
        fail: { type: Api.API_ERROR }
      },
      promise: Admin.checkVetting({ selectedRowsIds })
    });
  }
};

export const checkVettingSuccess = function* ({ payload }) {
  const names = payload?.map(item => item.user_name).join(', ');

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      checkVettingBannerContent: names,
      showCheckVettingBanner: true
    }
  });

  yield put({ type: AdminTypes.ALTER_BULK_ACTIONS, payload });
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      selectedRows: []
    }
  });
};

export const releaseBlock = function* ({ data, callback }) {
  const formData = new FormData();
  formData.append('data[attributes][onboarding_profile_ids][]', data.onboarding_profile_ids);
  formData.append('data[attributes][name]', data.name);
  formData.append('data[attributes][runtime_variables_group]', data.runtime_variables_group);
  if (Array.isArray(data.campaign_id) && data.campaign_id.length > 0) {
    data.campaign_id.forEach(id => {
      formData.append('data[attributes][campaign_ids][]', id);
    });
  } else if (data.campaign_id) {
    formData.append('data[attributes][campaign_ids][]', data.campaign_id);
  }
  formData.append('data[attributes][signer_user_id]', data.signer_user_id);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.RELEASE_BLOCK_SUCCESS, callback },
      fail: { type: AdminTypes.RELEASE_BLOCK_FAIL }
    },
    promise: Admin.releaseBlock(formData)
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      releaseBlockProcessing: true
    }
  });
};

export const releaseBlockSuccess = function* ({ payload, callback }) {
  const admin = yield select(adminSelector);
  const transient = yield select(transientSelector);
  const { selectedRows = [], full_name = '', fromDashboard = false } = transient;
  const hasReadyToSendWelcome = selectedRows?.some(
    row => row.state === ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE
  );
  const names = selectedRows?.map(item => item.user_name).join(', ');
  const isBulkAction = callback === 'bulk' && fromDashboard;

  const onboardingProfileId = transient?.userOnboardingProfile?.id;

  const blockModal = transient.block;

  const modalKey = `releaseBlock${blockModal}Modal`;

  const updatedProps = {
    [modalKey]: false,
    releaseBlockProcessing: false,
    block: '',
    nextBlock: '',
    releaseNextBlock: false
  };

  const countMatchingIds = (selectedRows, payload) => {
    let count = 0;

    for (const row of selectedRows) {
      const isIdMatched = payload.some(item => item.onboarding_profile.id === row.id);
      if (isIdMatched) {
        count++;
      }
    }

    return count;
  };

  const matchingCount = countMatchingIds(selectedRows, payload);
  const unprocessedAccounts = selectedRows.length - matchingCount;

  if (payload.length === 0 && !hasReadyToSendWelcome) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.profileIncompleteBulkFail.title'),
      body: I18n.t('growl:error.profileIncompleteBulkFail.body'),
      kind: 'error'
    });
  } else if (isBulkAction) {
    const data = { ...transient?.filters, ...admin.onboardings.meta };
    yield put({ type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES, data });

    if (selectedRows.length !== matchingCount) {
      yield put({
        type: GrowlTypes.ALERT,
        title: I18n.t('growl:error.profileUnconfirmedBulkFail.title'),
        body: I18n.t('growl:error.profileUnconfirmedBulkFail.body', { count: unprocessedAccounts }),
        kind: 'error'
      });
    }

    NavigationService.goBack({
      name: PAGE_NAMES.DASHBOARD
    });
  } else {
    yield put({
      type: AdminTypes.GET_ONBOARDING_BLOCKS,
      id: onboardingProfileId
    });

    yield put({
      type: AdminTypes.GET_USER_ONBOARDING_PROFILE,
      id: onboardingProfileId
    });
  }

  if (!(payload.length === 0 && !hasReadyToSendWelcome)) {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        releaseBlockBannerContent: isBulkAction ? names : full_name,
        showReleaseBlockBanner: true,
        releaseBlockProcessing: false,
        ...(isBulkAction && { releaseNextBlock: false })
      }
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: updatedProps
  });
};

export const releaseBlockFail = function* ({ error }) {
  const message = error?.response?.data?.message || I18n.t('growl:error.generalError.message');

  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.generalError.title'),
    body: message,
    kind: 'error'
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      releaseBlockProcessing: false
    }
  });
};

export const getClients = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_CLIENTS_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getClients()
  });
};

export const getClientsSuccess = function* ({ payload }) {
  const sortedClients = payload.sort((a, b) => a.name.localeCompare(b.name));

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      clients: sortedClients
    }
  });
};

export const getCampaignsByClient = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_CAMPAIGNS_BY_CLIENT_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getCampaignsByClient(id)
  });
};

export const getCampaignsByClientSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      campaigns: payload
    }
  });
};

export const updateUserDocument = function* ({ data }) {
  const formData = new FormData();
  formData.append('data[attributes][attachable_id]', data.attachable_id);
  formData.append('data[attributes][model_name]', 'OnboardingProfileDocument');
  formData.append('data[attributes][file]', data.file);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.UPDATE_USER_DOCUMENT_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.updateUserDocument(formData)
  });
};

export const updateUserDocumentSuccess = function* ({ payload }) {
  const transient = yield select(transientSelector);
  const onboardingProfileId = transient?.userOnboardingProfile?.id;

  yield put({
    type: AdminTypes.UPDATE_ONBOARDING_PROFILE_DOCUMENTS,
    data: {
      data: {
        attributes: {
          accepted_on: new Date().toISOString(),
          uploaded_on: new Date().toISOString(),
          uploaded: true,
          state: 'accepted',
          rejection_reason: ''
        }
      }
    },
    id: payload.attachable.id
  });

  yield put({
    type: AdminTypes.GET_ONBOARDING_BLOCKS,
    id: onboardingProfileId
  });
};

export const deleteUserAttachment = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.DELETE_USER_ATTACHMENT_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.deleteUserAttachment(data.attachment_id)
  });
};

export const deleteUserAttachmentSuccess = function* () {
  const transient = yield select(transientSelector);
  const onboardingProfileId = transient.userOnboardingProfile.id;

  yield put({
    type: AdminTypes.GET_ONBOARDING_BLOCKS,
    id: onboardingProfileId
  });
};

export const updateOnboardingUserSection = function* ({ data, id, callback }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.UPDATE_ONBOARDING_USER_SECTION_SUCCESS, callback },
      fail: { type: AdminTypes.UPDATE_ONBOARDING_USER_SECTION_ERROR }
    },
    promise: Admin.updateOnboardingUserSection(data, id)
  });
};

export const updateOnboardingUserSectionSuccess = function* ({ payload, callback }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...onboardingUserProfile,
        accepted_by_admin: payload.accepted_by_admin,
        accepted_by_mc_admin: payload.accepted_by_mc_admin,
        accepted_sections: payload.accepted_sections,
        rejected_fields: payload.rejected_fields,
        rejected_sections: payload.rejected_sections
      }
    }
  });

  if (callback === 'reject') {
    NavigationService.goBack({
      name: PAGE_NAMES.ONBOARDING_PROFILE
    });
  } else {
    NavigationService.navigate({
      name: PAGE_NAMES.ONBOARDING_PROFILE
    });
  }

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload,
        user: onboardingUserProfile.user
      },
      accepted_sections: payload.accepted_sections,
      rejectionReason: '',
      rejectedFields: []
    }
  });
};

export const updateOnboardingUserSectionError = function* ({ error }) {
  yield put({ type: Api.API_ERROR, error });
  yield put({
    type: GrowlTypes.ALERT,
    title: I18n.t('growl:error.updateUser.title'),
    body: error.response.data.errors[0],
    kind: 'error'
  });
};

export const confirmOnboardingProfileVetting = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.CONFIRM_ONBOARDING_PROFILE_VETTING_SUCCESS, id },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.confirmVetting(id)
  });
};

export const confirmOnboardingProfileVettingSuccess = function* ({ payload, id }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  if (payload[0]?.resource_errors?.base?.length > 0) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.updateUser.title'),
      body: payload[0]?.resource_errors.state
        ? payload[0]?.resource_errors.state[0]
        : payload[0]?.resource_errors.base[0],
      kind: 'error'
    });

    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        showConfirmVettingModal: false
      }
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        showConfirmVettingModal: false,
        userOnboardingProfile: {
          ...payload[0],
          user: onboardingUserProfile.user
        }
      }
    });

    yield put({
      type: AdminTypes.UPDATE_PROPS,
      props: {
        userOnboardingProfile: {
          ...payload[0],
          user: onboardingUserProfile.user
        }
      }
    });

    yield put({ type: AdminTypes.GET_USER_ONBOARDING_PROFILE, id });
  }
};

export const vetProfile = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.VET_PROFILE_SUCCESS, id },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.vetProfile(id)
  });
};

export const vetProfileSuccess = function* ({ payload, id }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);
  const shouldNotShowErrors = payload[0].state.includes([
    ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL,
    ONBOARDING_PROFILE_STATES.BADGE_RELEASED,
    ONBOARDING_PROFILE_STATES.ONBOARDED
  ]);

  const sanitizedPayload = {
    ...payload[0],
    badges: payload[0]?.badges?.map(badge => {
      const onboardingWithoutBadges = { ...badge?.onboarding_profile };
      delete onboardingWithoutBadges.badges;

      return {
        ...badge,
        onboarding_profile: onboardingWithoutBadges
      };
    })
  };

  if (payload[0]?.resource_errors?.base?.length > 0 && shouldNotShowErrors) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.updateUser.title'),
      body: payload[0]?.resource_errors.state
        ? payload[0]?.resource_errors.state[0]
        : payload[0]?.resource_errors.base[0],
      kind: 'error'
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        showConfirmVettingModal: false,
        userOnboardingProfile: {
          ...sanitizedPayload,
          user: onboardingUserProfile.user
        }
      }
    });

    yield put({
      type: AdminTypes.UPDATE_PROPS,
      props: {
        userOnboardingProfile: {
          ...sanitizedPayload,
          user: onboardingUserProfile.user
        }
      }
    });

    yield put({ type: AdminTypes.GET_USER_ONBOARDING_PROFILE, id });
  }
};

export const checkOnboardingProfileVetting = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.CHECK_ONBOARDING_PROFILE_VETTING_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.checkVetting({ selectedRowsIds: id })
  });
};

export const checkOnboardingProfileVettingSuccess = function* ({ payload }) {
  const onboardingUserProfile = yield select(onboardingUserProfileSelector);

  if (payload[0]?.resource_errors?.base?.length > 0) {
    yield put({
      type: GrowlTypes.ALERT,
      title: I18n.t('growl:error.updateUser.title'),
      body: payload[0].resource_errors.base[0],
      kind: 'error'
    });
  } else {
    yield put({
      type: TransientTypes.UPDATE_PROPS,
      props: {
        state: payload[0].state,
        userOnboardingProfile: {
          ...payload[0],
          user: onboardingUserProfile.user
        }
      }
    });
  }

  yield put({
    type: AdminTypes.UPDATE_PROPS,
    props: {
      userOnboardingProfile: {
        ...payload[0],
        user: onboardingUserProfile.user
      }
    }
  });
};

export const getMarketingCompanies = function* () {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_MARKETING_COMPANIES_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getMarketingCompanies()
  });
};

export const getMarketingCompaniesSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      marketingCompanies: payload
    }
  });
};

export const createUser = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.CREATE_USER_SUCCESS },
      fail: { type: AdminTypes.CREATE_USER_FAIL }
    },
    promise: Admin.createUser(data)
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      createUserLoading: true
    }
  });
};

export const createUserSuccess = function* () {
  const transient = yield select(transientSelector);

  yield put({
    type: AdminTypes.GET_ADMIN_ONBOARDING_PROFILES,
    data: transient?.filters
  });

  NavigationService.goBack({
    name: PAGE_NAMES.DASHBOARD
  });

  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      createUserLoading: false
    }
  });

  yield put({
    type: TransientTypes.RESET
  });
};

export const createUserFail = function* ({ error }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      createUserLoading: false
    }
  });

  yield put({ type: Api.API_ERROR, error });

  yield put({
    type: GrowlTypes.ALERT,
    body: error?.response?.data?.errors.join(', '),
    title: error?.response?.data?.message,
    kind: 'error'
  });
};

export const getCoach = function* ({ data }) {
  const aditionalQuerryParams = getStageQueryParams(CREATE_USER_OPTIONS.COACH_ROLES);

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_COACH_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getCoach(data, aditionalQuerryParams)
  });
};

export const getCoachSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      coachList: payload
    }
  });
};

export const getRecruiter = function* ({ data }) {
  const { recruitment_source, pins, searchValue } = data;
  const personalRecruitmentParams = getStageQueryParams(CREATE_USER_OPTIONS.PERSONAL_RECRUITMENT_RECRUITER_ROLES);
  const officeRecruitmentParams = getStageQueryParams(CREATE_USER_OPTIONS.OFFICE_RECRUITMENT_RECRUITER_ROLES);
  const recruitmentType =
    recruitment_source === CREATE_USER_OPTIONS.RECRUITMENT_TYPES[0]
      ? officeRecruitmentParams
      : personalRecruitmentParams;
  const queryParams = { pins, searchValue, recruitmentType };

  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.GET_RECRUITER_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.getRecruiter(queryParams)
  });
};

export const getRecruiterSuccess = function* ({ payload }) {
  yield put({
    type: TransientTypes.UPDATE_PROPS,
    props: {
      recruiterList: payload
    }
  });
};

export const removeAttachment = function* ({ id }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.REMOVE_ATTACHMENT_SUCCESS },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.removeAttachment(id)
  });
};

export const removeAttachmentSuccess = function* () {
  const transient = yield select(transientSelector);

  yield put({
    type: AdminTypes.GET_ONBOARDING_BLOCKS,
    id: transient?.userOnboardingProfile?.id
  });
};

export const updateBadgeNumber = function* ({ data }) {
  yield put({
    type: Api.API_CALL,
    actions: {
      success: { type: AdminTypes.ALTER_BADGES },
      fail: { type: Api.API_ERROR }
    },
    promise: Admin.updateBadgeNumber(data)
  });
};
