import { createActions, createReducer } from 'reduxsauce';

import { updateApiModel, updateProp, updateProps } from 'Reducers/shared';

export const { Types, Creators } = createActions(
  {
    bankAccountCheckFR: ['key', 'data'],
    bankAccountCheckUK: ['key', 'data'],
    deleteAttachment: ['payload'],
    deleteAttachmentSuccess: ['attachment', 'callback'],
    getAttachments: ['attachmentId'],
    getAttachmentsSuccess: ['payload'],
    getCountries: null,
    getCountriesSuccess: ['payload'],
    getPostCodes: ['payload'],
    getUKAddress: ['data'],
    getUKAddressSuccess: ['payload'],
    getUKAddressFail: null,
    getUser: ['id'],
    getUserSuccess: ['payload'],
    getOnboardingProfiles: ['id', 'callback'],
    getOnboardingProfilesSuccess: ['payload', 'callback'],
    getRoles: null,
    getRolesSuccess: ['payload'],
    readAttachment: ['payload'],
    requestChangeUserPassword: ['data'],
    requestChangeUserPasswordSuccess: ['payload'],
    resetPassword: ['payload'],
    resetPasswordFail: ['payload'],
    resetPasswordSuccess: ['payload'],
    sendFeedback: ['data', 'callback'],
    sendFeedbackFail: null,
    sendFeedbackSuccess: null,
    setCampaign: ['campaign', 'displayGrowl'],
    setCampaignSuccess: ['userId', 'payload'],
    setNewAttachmentsList: ['payload'],
    setUser: ['payload'],
    updateApiModel: ['props', 'callback', 'fieldName'],
    updateProp: ['key', 'value'],
    setProp: ['key', 'payload'],
    updateProps: ['props'],
    updateUserAvatar: ['data'],
    updateUserAvatarFail: ['payload'],
    updateUserAvatarSuccess: ['payload'],
    updateUserFail: ['payload'],
    updateUserSuccess: ['payload'],
    uploadAttachments: ['data'],
    uploadAttachmentsFail: ['payload'],
    uploadAttachmentsSuccess: ['payload'],
    validateOnlineBankAccountUser: ['account_number', 'sort_code', 'registration_number', 'country'],
    validateOnlineBankAccountUserSuccess: ['payload']
  },
  { prefix: 'user/' }
);

const initialState = {};

export const setUser = (state, { payload }) => {
  delete payload.auth_token;

  //TODO remove this logic after agent v1 is closed
  if (payload.rejected_fields && payload.rejected_fields.includes('iban')) {
    const index = payload.rejected_fields.indexOf('iban');
    payload.rejected_fields[index] = 'account_number';
  }

  return {
    ...state,
    ...payload
  };
};

export const incompleteAttachments = state => state.user.incomplete_documents;
export const pendingAttachments = state => state.user.pending_documents;
export const donorFlowId = state => state.user.published_flow_id;
export const firstName = state => state.user.first_name;
export const user = state => state.user;

export const selectedBadgeNumber = state => {
  const activeBadges = state.user?.active_badges;
  const selectedCampaignId = state.transient?.user_campaigns?.selectedCampaign?.id;
  const filteredBadges = activeBadges?.filter(badge => badge?.campaign?.id === selectedCampaignId);
  const badgeNumbers = filteredBadges?.map(badge => badge?.number)?.join(', ');

  return badgeNumbers;
};

export const setNewAttachmentsList = (state, { payload }) => {
  const unchangedPendingAttachments = state.pending_documents.filter(attachment => attachment.id !== payload.id);
  const unchangedIncompleteAttachments = state.incomplete_documents.filter(attachment => attachment.id !== payload.id);
  const filteredAttentionDocuments = state.needs_attention_documents.filter(attachment => attachment.id !== payload.id);

  return {
    ...state,
    pending_documents: [...unchangedPendingAttachments, payload],
    incomplete_documents: unchangedIncompleteAttachments,
    needs_attention_documents: filteredAttentionDocuments
  };
};

export default createReducer(initialState, {
  [Types.SET_NEW_ATTACHMENTS_LIST]: setNewAttachmentsList,
  [Types.SET_USER]: setUser,
  [Types.SET_PROP]: (state, { key, payload }) => ({ ...state, [key]: payload }),
  [Types.UPDATE_API_MODEL]: updateApiModel,
  [Types.UPDATE_PROP]: updateProp,
  [Types.UPDATE_PROPS]: updateProps
});
