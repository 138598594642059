import React from 'react';
import PropTypes from 'prop-types';

import { mockFunction } from 'Helpers';

import './style.scss';

const Link = ({ children = '', onClick = mockFunction }) => (
  <div className="container" onClick={onClick}>
    {children}
  </div>
);

Link.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func
};

export default Link;
