import React from 'react';
import PropTypes from 'prop-types';
import { List, Checkbox } from 'framework7-react';

import { I18n } from 'Locales';
import { Card } from 'Components';
import { ColumnView, RowView } from 'Containers';

import './style.scss';
import { ProfileTextField } from '../../Components';
import { onboardingProfileTextFieldsConfig } from '../../utils/config';

const renderTextFields = (sectionConfig, transient, otherProps) =>
  onboardingProfileTextFieldsConfig?.details?.map((config, index) => (
    <ProfileTextField key={index} config={config} section={sectionConfig} transient={transient} {...otherProps} />
  ));

const Details = ({ isOffline, transient, userOnboardingProfile }) => {
  const sections = {
    details: {
      readOnly: isOffline
    }
  };

  return (
    <ColumnView id={'details'} paddingHorizontal={16}>
      <RowView paddingTop={16}>
        <Card className="w-full" description={userOnboardingProfile?.info_message || ''} />
      </RowView>
      <RowView justifyContent={'space-between'} paddingBottom={8} paddingTop={16}>
        <div className="title">{I18n.t('admin:onboardingProfilePages.details')}</div>
      </RowView>
      <List className="w-full">
        {renderTextFields('details', transient, {
          isReadOnly: sections.details.readOnly
        })}
      </List>
      <RowView justifyContent={'flex-start'} paddingBottom={16}>
        <Checkbox disabled={true} checked={transient.document_confirmation} />
        <div className="pl-8 pt-2">{I18n.t('admin:onboardingProfile.documentConfirmation')}</div>
      </RowView>
    </ColumnView>
  );
};

Details.propTypes = {
  isOffline: PropTypes.bool,
  transient: PropTypes.object,
  userOnboardingProfile: PropTypes.object
};

Details.defaultProps = {
  isOffline: false,
  transient: {},
  userOnboardingProfile: {}
};

export default Details;
