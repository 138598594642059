import React from 'react';

import PropTypes from 'prop-types';

const Bank = ({ fillColor = '#1C1B1F' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      fill={fillColor}
      // eslint-disable-next-line max-len
      d="M3.75 15.25v-6c0-.212.072-.39.216-.534A.726.726 0 0 1 4.5 8.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v6c0 .213-.072.39-.216.535A.726.726 0 0 1 4.5 16a.725.725 0 0 1-.534-.215.726.726 0 0 1-.215-.535Zm5.5 0v-6c0-.212.072-.39.215-.534A.726.726 0 0 1 10 8.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v6c0 .213-.072.39-.216.535a.726.726 0 0 1-.535.215.725.725 0 0 1-.534-.215.726.726 0 0 1-.215-.535ZM1.519 19.5a.726.726 0 0 1-.534-.215.726.726 0 0 1-.216-.535c0-.213.072-.39.216-.534A.726.726 0 0 1 1.519 18H18.48c.213 0 .391.072.535.216a.726.726 0 0 1 .215.535c0 .212-.071.39-.215.534a.726.726 0 0 1-.535.215H1.52Zm13.23-4.25v-6c0-.212.073-.39.216-.534A.726.726 0 0 1 15.5 8.5c.213 0 .39.072.534.216a.726.726 0 0 1 .216.534v6c0 .213-.072.39-.216.535a.726.726 0 0 1-.535.215.725.725 0 0 1-.534-.215.726.726 0 0 1-.215-.535Zm3.77-8.75H1.409a.618.618 0 0 1-.453-.186.616.616 0 0 1-.187-.453v-.39c0-.13.033-.243.098-.337a.746.746 0 0 1 .258-.23L9.205.95c.25-.12.515-.18.794-.18s.544.06.795.18l8.04 3.93a.71.71 0 0 1 .396.641v.27a.689.689 0 0 1-.711.71ZM4.315 5h11.37l-5.55-2.692A.34.34 0 0 0 10 2.279a.34.34 0 0 0-.135.029L4.315 5Z"
    />
  </svg>
);

export default Bank;

Bank.propTypes = {
  fillColor: PropTypes.string
};
