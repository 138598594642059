import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

const TermsAndConditionsModal = ({
  tc_modal = false,
  termsAndConditionsMessage = mockFunction,
  updateTransientProps = mockFunction,
  updateUser = mockFunction,
  updateUserTC = mockFunction
}) => (
  <Modal open={tc_modal} closeByBackdropClick={false}>
    <ColumnView id={'campaigns'} justifyContent={'space-between'} height={'100%'} gap={24}>
      <ColumnView gap={36} paddingVertical={24}>
        <RowView>
          <div className="text-md font-bold">{I18n.t('general:agreementNotice')}</div>
        </RowView>
        <RowView>{termsAndConditionsMessage(updateTransientProps)}</RowView>
      </ColumnView>
      <RowView>
        <Button.Primary
          className="line-height-normal"
          width={'100%'}
          onClick={updateUserTC.bind(null, { updateUser, updateTransientProps })}>
          {I18n.t('general:consent')}
        </Button.Primary>
      </RowView>
    </ColumnView>
  </Modal>
);

TermsAndConditionsModal.propTypes = {
  tc_modal: PropTypes.bool,
  termsAndConditionsMessage: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserTC: PropTypes.func
};

export default TermsAndConditionsModal;
