import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { CREATE_USER_OPTIONS } from 'Constants';
import { LabelWarning, SearchAndSelect, TextField } from 'Components';
import { handleOnChangeWithValidations, mockFunction, phoneNumberSanitization, validations } from 'Helpers';

import { getFormattedValue, isDisabled, renderSelectField } from '../../utils/helpers';

const handleGetRecruiter = ({ getRecruiter, pins, recruitment_source }, searchValue) => {
  getRecruiter({ recruitment_source, pins, searchValue });
};

const handleGetCoach = ({ getCoach, pins }, searchValue) => {
  getCoach({ pins, searchValue });
};

const CreateUserTextField = ({
  config = {},
  getCoach = mockFunction,
  getRecruiter = mockFunction,
  keyProp,
  transient = '',
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  user = {}
}) => {
  const { can_simple_create_users } = user;
  const {
    coachList,
    marketing_companies,
    marketingCompanies,
    pins,
    recruiterList,
    recruitment_source,
    regions,
    role
  } = transient;
  const fieldValue = transient?.[config.fieldName];
  const validationFuncs = config.validations.map(v => validations[v]);
  const hasError = transient?.[`${config.fieldName}_has_error`] || false;
  const mcPins = marketingCompanies?.map(mc => mc?.pin);
  const simpleUserCreate =
    can_simple_create_users && CREATE_USER_OPTIONS.SIMPLE_USER_CREATE_FIELDS?.includes(config.fieldName);
  const type = simpleUserCreate ? 'text' : config.type;
  const isMultiple =
    config.fieldName === 'regions'
      ? config.multiple
      : CREATE_USER_OPTIONS.MC_ADMIN_LIKE_ROLES.includes(role) && !can_simple_create_users && config.multiple;

  const selectOptionsMapping = {
    ...(!can_simple_create_users && {
      role: {
        options: CREATE_USER_OPTIONS.ROLES || [],
        selectTranslationKey: '',
        fieldName: config.fieldName
      },
      pins: {
        options: mcPins || [],
        selectTranslationKey: '',
        fieldName: config.fieldName,
        value: pins || []
      },
      marketing_companies: {
        options: marketing_companies || [],
        selectTranslationKey: '',
        fieldName: config.fieldName,
        value: marketing_companies || []
      }
    }),
    country: {
      options: CREATE_USER_OPTIONS.COUNTRIES || [],
      selectTranslationKey: config.selectTranslationKey,
      fieldName: config.fieldName
    },
    recruitment_source: {
      options: CREATE_USER_OPTIONS.RECRUITMENT_TYPES || [],
      selectTranslationKey: '',
      fieldName: config.fieldName
    },
    language: {
      options: CREATE_USER_OPTIONS.LANGUAGES || [],
      selectTranslationKey: config.selectTranslationKey,
      fieldName: config.fieldName,
      value: CREATE_USER_OPTIONS.LANGUAGES
    },
    recruiter: {
      options: recruiterList || [],
      selectTranslationKey: '',
      fieldName: config.fieldName,
      onSearch: handleGetRecruiter.bind(null, { getRecruiter, recruitment_source, pins })
    },
    coach: {
      options: coachList || [],
      selectTranslationKey: '',
      fieldName: config.fieldName,
      onSearch: handleGetCoach.bind(null, { getCoach, pins })
    },
    regions: {
      value: regions || [],
      options: CREATE_USER_OPTIONS.PITCHING_REGIONS || [],
      selectTranslationKey: config.selectTranslationKey,
      fieldName: config.fieldName
    }
  };

  if (config.searchAndSelect && !simpleUserCreate) {
    return (
      <div className="w-full px-16">
        <SearchAndSelect
          resetKey={role}
          multiple={isMultiple}
          fieldName={config.fieldName}
          label={I18n.t(config.label)}
          otherOptions={config.otherOptions}
          displayProperty={config.displayProperty}
          updateTransientProps={updateTransientProps}
          options={selectOptionsMapping[config?.fieldName]?.options}
          selectedOptions={selectOptionsMapping[config?.fieldName]?.value}
          onSearch={selectOptionsMapping[config?.fieldName]?.onSearch}
        />
      </div>
    );
  }

  return (
    <TextField
      key={keyProp}
      label={<LabelWarning label={I18n.t(config.label)} isRequired={config.validations.includes('isRequired')} />}
      type={type}
      value={getFormattedValue(config, fieldValue, transient)}
      disabled={simpleUserCreate || isDisabled(config.isReadOnly, false)}
      validate={true}
      validateOnBlur={true}
      onInput={config.sanitization && phoneNumberSanitization}
      onChange={handleOnChangeWithValidations.bind(
        null,
        updateTransientPropWithValidations,
        config.fieldName,
        validationFuncs
      )}
      errorMessageForce={hasError}
      errorMessage={transient?.[`${config.fieldName}_error_message`]}>
      {config.type === 'select' && renderSelectField(selectOptionsMapping, config.fieldName)}
    </TextField>
  );
};

CreateUserTextField.propTypes = {
  config: PropTypes.object,
  getCoach: PropTypes.func,
  getRecruiter: PropTypes.func,
  keyProp: PropTypes.number,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object
};

export default CreateUserTextField;
