import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Page } from 'framework7-react';

import { I18n } from 'Locales';
import { AlertCircle } from 'Icons';
import { PAGE_NAMES } from 'Constants';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { AppHeader, Button, TextField } from 'Components';
import { camelToSnake, handleOnChangeWithValidations, validations, mockFunction } from 'Helpers';

import './style.scss';

const handleRejectSection = (transient, updateOnboardingUserSection, userOnboardingProfile) => {
  const section = camelToSnake(transient?.section);

  const updatedProfile = {
    ...userOnboardingProfile,
    rejected_sections: {
      ...userOnboardingProfile?.rejected_sections,
      [section]: {
        fields: transient.rejectedFields || [],
        reason: transient.rejectionReason || ''
      }
    }
  };

  const newData = {
    data: {
      attributes: updatedProfile
    }
  };

  updateOnboardingUserSection(newData, userOnboardingProfile?.id, 'reject');
};

const RejectSection = ({
  transient,
  updateOnboardingUserSection,
  userOnboardingProfile,
  updateTransientProps,
  updateTransientPropWithValidations
}) => {
  const handleClick = item => {
    const currentRejectedFields = transient.rejectedFields || [];
    const updatedRejectedFields = [...currentRejectedFields, item.fieldName];
    updateTransientProps({ rejectedFields: updatedRejectedFields });
  };

  const { rejectionReason_has_error = true, rejectionReason = '' } = transient;
  const isDisabled = rejectionReason_has_error || rejectionReason === '';

  return (
    <Page id="reject-section">
      <AppHeader
        hideAvatar
        goBack
        title={I18n.t('admin:rejectFields')}
        handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.ONBOARDING_PROFILE })}
      />
      <ColumnView minHeight={'calc(100% - 74px)'} marginBottom={8} justifyContent="space-between">
        <div className="w-full">
          <div id="reject-section-message">
            <div>
              <AlertCircle />
            </div>
            <ColumnView alignItems="flex-start">
              <div className="message">{I18n.t('admin:rejectSectionSubtitle')}</div>
            </ColumnView>
          </div>

          <List className="fields-list">
            {transient?.sectionFields?.map((item, index) => (
              <ListItem key={index} checkbox={true} onClick={handleClick.bind(null, item)}>
                {I18n.t(`${item.label}`)}
              </ListItem>
            ))}
          </List>
          <List>
            <TextField
              label={I18n.t('admin:rejectReason')}
              onChange={handleOnChangeWithValidations.bind(
                null,
                updateTransientPropWithValidations,
                'rejectionReason',
                [validations.isRequired]
              )}
              validate
              validateOnBlur
              errorMessageForce={transient?.rejectionReason_has_error}
              errorMessage={transient?.rejectionReason_error_message}
              placeholder={'Please type the rejection reason'}
            />
          </List>
        </div>

        <div className="w-full px-16">
          <Button.Primary
            disabled={isDisabled}
            onClick={handleRejectSection.bind(null, transient, updateOnboardingUserSection, userOnboardingProfile)}
            className="line-height-normal h-48">
            {I18n.t('admin:reject')}
          </Button.Primary>
        </div>
      </ColumnView>
    </Page>
  );
};

RejectSection.propTypes = {
  transient: PropTypes.object,
  updateOnboardingUserSection: PropTypes.func,
  userOnboardingProfile: PropTypes.object,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func
};

RejectSection.defaultProps = {
  transient: {},
  updateOnboardingUserSection: mockFunction,
  userOnboardingProfile: {},
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction
};

export default RejectSection;
