import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

const updateUserDocumentsAgreement = ({ updateUser, user, updateTransientProps }) => {
  updateUser({ data: { attributes: { document_confirmation: !user.document_confirmation } } });
  updateTransientProps({ legal_rights_modal: false });
};

const DocumentsLegalRightsModal = ({
  legal_rights_modal = false,
  updateTransientProps = mockFunction,
  updateUser = mockFunction,
  user = {}
}) => (
  <Modal open={legal_rights_modal} onClose={updateTransientProps.bind(null, { legal_rights_modal: false })}>
    <ColumnView id={'documents'} justifyContent={'space-between'} height={'100%'} gap={24}>
      <ColumnView gap={36} paddingVertical={24}>
        <RowView>
          <div className="text-md font-bold">{I18n.t('profile:documentsTab.documentsAgreementTitle')}</div>
        </RowView>
        <RowView>
          <div className="text-sm font-light">{I18n.t('profile:documentsTab.documentsAgreement')}</div>
        </RowView>
      </ColumnView>
      <RowView justifyContent={'flex-end'} gap={12}>
        <Button.OutlineSmall onClick={updateTransientProps.bind(null, { legal_rights_modal: false })}>
          {I18n.t('general:cancel')}
        </Button.OutlineSmall>
        <Button.PrimarySmall
          onClick={updateUserDocumentsAgreement.bind(null, { updateUser, user, updateTransientProps })}>
          {I18n.t('profile:documentsTab.consent')}
        </Button.PrimarySmall>
      </RowView>
    </ColumnView>
  </Modal>
);

DocumentsLegalRightsModal.propTypes = {
  legal_rights_modal: PropTypes.bool,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func,
  user: PropTypes.object
};

export default DocumentsLegalRightsModal;
