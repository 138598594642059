import { connect } from 'react-redux';

import * as Selectors from 'Selectors';
import { Creators as flowActions } from 'Reducers/flow';
import { Creators as flowCreators } from 'Reducers/flow';
import { Creators as adminActions } from 'Reducers/admin';
import { Creators as checkInActions } from 'Reducers/checkIn';
import { Creators as postcodesActions } from 'Reducers/postCodes';
import { Creators as transientActions } from 'Reducers/transient';
import { Creators as applicationActions } from 'Reducers/application';

import AppBottomBar from './view';

export default connect(
  state => {
    const flow = state.flow.flows[state.flow.currentFlowIndex];
    return {
      currentRouteName: Selectors.getCurrentRouteName(state),
      flow: {
        pages: flow?.flow_pages || [],
        currentPageIndex: state.flow.currentPageIndex
      },
      application: state.application,
      campaign: state.campaign,
      campaigns: Selectors.userCampaigns(state),
      isOffline: state.application.isOffline,
      isSubmittedOffline: Selectors.isSubmittedOffline(state),
      lastTransactionId: state.application.lastTransaction?.id,
      mode: state.application.mode,
      offlineResults: Selectors.offlineFlows(state),
      session: state.application.session,
      transient: state.transient,
      user: state.user
    };
  },
  {
    checkIn: checkInActions.checkIn,
    logout: applicationActions.logout,
    nextFlowPage: flowCreators.nextFlowPage,
    previousFlowPage: flowCreators.previousFlowPage,
    resetOnboardingProfilePages: adminActions.resetOnboardingProfilePages,
    resetTransient: transientActions.reset,
    setSelectedPostcodes: postcodesActions.updateProp,
    summaryFlowPage: flowCreators.summaryFlowPage,
    updateApplicationProp: applicationActions.updateProp,
    updateFlowProps: flowActions.updateProps,
    updateTransientProps: transientActions.updateProps
  }
)(AppBottomBar);
