import React from 'react';

import PropTypes from 'prop-types';

const Identity = ({ fillColor = '#1C1B1F' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <path
      fill={fillColor}
      // eslint-disable-next-line max-len
      d="M4.154 15.711h5.692v-.296c0-.255-.07-.491-.212-.709a1.405 1.405 0 0 0-.592-.506A4.996 4.996 0 0 0 7 13.77a4.996 4.996 0 0 0-2.042.43c-.253.12-.45.289-.592.506a1.276 1.276 0 0 0-.212.71v.295Zm8.442-1.519h2.808a.58.58 0 0 0 .596-.595.58.58 0 0 0-.596-.597h-2.808a.579.579 0 0 0-.596.596.58.58 0 0 0 .596.596ZM6.998 13c.373 0 .691-.13.954-.392.263-.261.394-.579.394-.952s-.13-.691-.392-.954a1.292 1.292 0 0 0-.952-.394c-.373 0-.691.13-.954.392a1.292 1.292 0 0 0-.394.952c0 .373.13.69.392.954.261.263.578.394.952.394Zm5.598-1.5h2.808a.58.58 0 0 0 .596-.596.58.58 0 0 0-.596-.596h-2.808a.58.58 0 0 0-.596.595.58.58 0 0 0 .596.597Zm-10.288 8c-.505 0-.933-.175-1.283-.525A1.745 1.745 0 0 1 .5 17.692V7.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525H7.5V2c0-.413.147-.766.44-1.06C8.235.648 8.589.5 9 .5h2c.412 0 .766.147 1.06.44.293.294.44.647.44 1.06v3.5h5.192c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v10.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H2.308Zm0-1.5h15.384a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V7.307a.3.3 0 0 0-.087-.221.3.3 0 0 0-.22-.087H12.5v.385c0 .409-.148.76-.443 1.056A1.443 1.443 0 0 1 11 8.885H9c-.409 0-.761-.148-1.057-.444A1.443 1.443 0 0 1 7.5 7.385V7H2.308a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v10.385a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087ZM9 7.385h2V2H9v5.385Z"
    />
  </svg>
);

export default Identity;

Identity.propTypes = {
  fillColor: PropTypes.string
};
