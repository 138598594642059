import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Edit } from 'Icons';
import { I18n } from 'Locales';
import { DATE_FORMAT, ROLES } from 'Constants';
import { ColumnView, RowView } from 'Containers';
import { formatDate, mockFunction } from 'Helpers';

import './style.scss';
import { UpdateBadgeNumberModal } from '../Components';

const Badges = ({
  transient = {},
  updateBadgeNumber = mockFunction,
  updateTransientProps = mockFunction,
  user_role = '',
  userOnboardingProfile = {}
}) => {
  const { badgeId, badgeNumber, showUpdateBadgeModal } = transient;

  return (
    <>
      <UpdateBadgeNumberModal
        badgeId={badgeId}
        badgeNumber={badgeNumber}
        showUpdateBadgeModal={showUpdateBadgeModal}
        updateBadgeNumber={updateBadgeNumber}
        updateTransientProps={updateTransientProps}
      />

      <ColumnView id={'badges'} paddingHorizontal={16} paddingVertical={20}>
        <RowView justifyContent={'space-between'} paddingBottom={8}>
          <div className="title">{I18n.t('admin:onboardingProfilePages.badges')}</div>
        </RowView>

        <ColumnView width={'100%'} gap={12}>
          {userOnboardingProfile?.badges?.map((badge, index) => {
            const disabled = moment(badge?.deactivation_date).isBefore(moment(badge?.current_time).startOf('day'));
            const hasActivationDate = badge?.activation_date;

            return (
              <div className={`profile-tab-card ${disabled ? 'disabled' : ''}`} key={index}>
                <div className="row">
                  <div className="column-left">
                    <div className="bold-text">
                      {I18n.t(`admin:onboardingProfile.${badge?.primary ? 'primaryBadge' : 'secondaryBadge'}`, {
                        badgeNumber: badge.number
                      })}
                    </div>

                    <div className="row">
                      <div className="pr-2">{`${I18n.t('admin:onboardingProfile.campaign')}` + ':'}</div>
                      <div>{badge?.campaign?.name}</div>
                    </div>

                    <div className="row">
                      <div className="pr-2">{I18n.t('admin:onboardingProfile.activationDate')}</div>
                      <div className={`${!hasActivationDate && !disabled ? 'disabled-text' : ''}`}>
                        {hasActivationDate ? formatDate(badge?.activation_date) : DATE_FORMAT}
                      </div>
                    </div>

                    {badge.usage_end_date && badge.usage_start_date && (
                      <div className="row">
                        <div className="pr-2">{I18n.t('admin:onboardingProfile.usage')}</div>
                        <div className="bold-text">
                          {`${formatDate(badge?.usage_start_date)} - ${formatDate(badge?.usage_end_date)}`}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="column-right">
                    <div
                      {...(!disabled &&
                        !ROLES.MC_ADMINISTRATIVE.includes(user_role) && {
                          onClick: updateTransientProps.bind(null, {
                            showUpdateBadgeModal: true,
                            badgeNumber: badge?.number,
                            badgeId: badge?.id
                          })
                        })}
                      className={!disabled ? '' : 'disabled-text'}>
                      {!ROLES.MC_ADMINISTRATIVE.includes(user_role) && <Edit />}
                    </div>

                    <div className="status">
                      <div className="bold-text">
                        {badge?.status?.charAt(0).toUpperCase() + badge?.status?.slice(1)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </ColumnView>
      </ColumnView>
    </>
  );
};

Badges.propTypes = {
  transient: PropTypes.object,
  updateBadgeNumber: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user_role: PropTypes.string,
  userOnboardingProfile: PropTypes.object
};

export default Badges;
