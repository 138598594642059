export const filters = {
  onboarding: {
    number: [
      { name: 'mc_pin', type: 'search' },
      { name: 'badge_number', type: 'search' }
    ],
    select: [
      { name: 'active_onboarding', type: 'checkbox', default: true, defaultActive: true },
      { name: 'active_users', type: 'checkbox', default: true, defaultActive: true },
      { name: 'incomplete_profile_only', type: 'checkbox' }
    ],

    name: [
      { name: 'agent_name', type: 'search' },
      { name: 'by_campaign_name', type: 'search' },
      { name: 'by_client_name', type: 'search' },
      { name: 'by_state', type: 'search' },
      { name: 'by_marketing_company_name', type: 'search' }
    ]
  }
};
