import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

const handleClick = ({
  admin = {},
  getMcPin = mockFunction,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction
}) => {
  const { selectedMcPin } = transient;
  updateTransientProps({ mcPinsModal: false, selectedRows: [] });
  if (selectedMcPin !== undefined && selectedMcPin !== '') {
    updateAdminProps({ selectedMcPin: selectedMcPin });
    getMcPin(selectedMcPin);
  } else {
    updateAdminProps({ selectedMcPin: admin?.selectedMcPin });
    getMcPin(admin?.selectedMcPin);
  }
};

const SwitchMcPinModal = ({
  admin = {},
  getMcPin = mockFunction,
  mcPinsModal = false,
  transient = {},
  updateAdminProps = mockFunction,
  updateTransientProps = mockFunction,
  user = {}
}) => (
  <Modal
    open={mcPinsModal}
    onClose={updateTransientProps.bind(null, {
      mcPinsModal: false,
      selectedMcPin: admin.selectedMcPin || ''
    })}>
    <ColumnView id={'mc-switch'} justifyContent={'space-between'}>
      <ColumnView gap={24}>
        <RowView>
          <div className="text-md font-bold">{I18n.t('admin:marketingCompany')}</div>
        </RowView>
        <RowView>
          <div>{I18n.t('admin:selectMarketingCompany')}</div>
        </RowView>
        <List noHairlines className="w-full mb-20 mt-0">
          {user?.mc_pins?.map((item, index) => (
            <ListItem
              key={index}
              radio
              radioIcon={'start'}
              title={`${item} - ${user?.mc_names[index]}`}
              checked={transient?.selectedMcPin ? item === transient?.selectedMcPin : item === admin?.selectedMcPin}
              onChange={updateTransientProps.bind(null, { selectedMcPin: item })}
            />
          ))}
        </List>
      </ColumnView>
      <RowView paddingTop={8} justifyContent={'flex-end'} gap={12}>
        <Button.OutlineSmall onClick={updateTransientProps.bind(null, { mcPinsModal: false, selectedMcPin: '' })}>
          {I18n.t('general:cancel')}
        </Button.OutlineSmall>
        <Button.PrimarySmall
          onClick={handleClick.bind(null, { admin, getMcPin, transient, updateAdminProps, updateTransientProps })}>
          {I18n.t('general:ok')}
        </Button.PrimarySmall>
      </RowView>
    </ColumnView>
  </Modal>
);

SwitchMcPinModal.propTypes = {
  admin: PropTypes.object,
  getMcPin: PropTypes.func,
  mcPinsModal: PropTypes.bool,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateTransientProps: PropTypes.func,
  user: PropTypes.object
};

export default SwitchMcPinModal;
