import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { Button, Modal } from 'Components';
import { ColumnView, RowView } from 'Containers';

import './style.scss';
import { ErrorsModal, ReonboardModal, WelcomeEmailModal } from './subviews';

const handleSendWelcomeEmail = ({
  custom_footer = '',
  email = '',
  selectedRows = [],
  sendWelcomeEmail = mockFunction,
  updateTransientProps = mockFunction
}) => {
  updateTransientProps({
    custom_footer: '',
    errorsModal: false,
    profilesWithErrors: [],
    profilesWithoutErrors: '',
    selectedRows: [],
    showWelcomeEmailModal: false
  });

  sendWelcomeEmail({ custom_footer, email, rows: selectedRows, bulk: true });
};

const BulkModal = ({
  campaigns = [],
  clients = [],
  custom_footer = '',
  email = '',
  email_error_message = [],
  email_has_error = false,
  errorsModal = false,
  getReOnboardingCampaigns = mockFunction,
  isOneRowSelected = '',
  profilesWithErrors = [],
  profilesWithoutErrors = '',
  reOnboard = mockFunction,
  reOnboardLoading = false,
  reuse_existing_badge_number = true,
  selectedCampaigns = [],
  selected_client = '',
  selected_signer = '',
  selectedRows = [],
  sendWelcomeEmail = mockFunction,
  showReonboardModal = false,
  showWelcomeEmailModal = false,
  signers = [],
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction
}) => (
  <Modal
    open={errorsModal || showWelcomeEmailModal || showReonboardModal}
    onClose={updateTransientProps.bind(null, {
      email_error_message: '',
      email_has_error: false,
      email: '',
      errorsModal: false,
      profilesWithErrors: [],
      profilesWithoutErrors: '',
      selectedCampaigns: [],
      selected_client: '',
      selected_signer: '',
      selectedRows: [],
      showWelcomeEmailModal: false,
      showReonboardModal: false
    })}>
    <ColumnView id={'modal'} justifyContent={'space-between'} height={'100%'}>
      <ColumnView gap={24} width={'100%'}>
        {showWelcomeEmailModal ? (
          <WelcomeEmailModal
            custom_footer={custom_footer}
            email_error_message={email_error_message}
            email_has_error={email_has_error}
            email={email}
            isOneRowSelected={isOneRowSelected}
            selectedRows={selectedRows}
            updateTransientProps={updateTransientProps}
            updateTransientPropWithValidations={updateTransientPropWithValidations}
          />
        ) : showReonboardModal ? (
          <ReonboardModal
            campaigns={campaigns}
            clients={clients}
            getReOnboardingCampaigns={getReOnboardingCampaigns}
            reuse_existing_badge_number={reuse_existing_badge_number}
            selected_client={selected_client}
            selectedRows={selectedRows}
            signers={signers}
            updateTransientProps={updateTransientProps}
          />
        ) : (
          <ErrorsModal profilesWithErrors={profilesWithErrors} profilesWithoutErrors={profilesWithoutErrors} />
        )}
      </ColumnView>

      <RowView justifyContent={'flex-end'} paddingTop={4} gap={12}>
        {(showWelcomeEmailModal || showReonboardModal) && (
          <Button.OutlineSmall
            onClick={updateTransientProps.bind(null, {
              email_error_message: '',
              email_has_error: false,
              email: '',
              errorsModal: false,
              profilesWithErrors: [],
              profilesWithoutErrors: '',
              selectedCampaigns: [],
              selected_client: '',
              selected_signer: '',
              selectedRows: [],
              showWelcomeEmailModal: false,
              showReonboardModal: false
            })}>
            {I18n.t('general:cancel')}
          </Button.OutlineSmall>
        )}

        <Button.PrimarySmall
          disabled={showReonboardModal ? !(selectedCampaigns?.length && selected_client && selected_signer) : false}
          processing={reOnboardLoading}
          onClick={
            showWelcomeEmailModal
              ? handleSendWelcomeEmail.bind(null, {
                  custom_footer,
                  email,
                  selectedRows,
                  sendWelcomeEmail,
                  updateTransientProps
                })
              : showReonboardModal
              ? reOnboard.bind(null, {
                  reuse_existing_badge_number,
                  selectedCampaigns,
                  selected_client,
                  selected_signer,
                  selectedRows
                })
              : updateTransientProps.bind(null, {
                  email_error_message: '',
                  email_has_error: false,
                  email: '',
                  errorsModal: false,
                  profilesWithErrors: [],
                  profilesWithoutErrors: '',
                  selectedCampaigns: [],
                  selected_client: '',
                  selected_signer: '',
                  selectedRows: [],
                  showReonboardModal: false,
                  showWelcomeEmailModal: false
                })
          }>
          {showWelcomeEmailModal
            ? I18n.t('general:send')
            : showReonboardModal
            ? I18n.t('general:save')
            : I18n.t('general:ok')}
        </Button.PrimarySmall>
      </RowView>
    </ColumnView>
  </Modal>
);

BulkModal.propTypes = {
  campaigns: PropTypes.array,
  clients: PropTypes.array,
  custom_footer: PropTypes.string,
  email_error_message: PropTypes.string,
  email_has_error: PropTypes.bool,
  email: PropTypes.string,
  errorsModal: PropTypes.bool,
  getReOnboardingCampaigns: PropTypes.func,
  isOneRowSelected: PropTypes.bool,
  profilesWithErrors: PropTypes.array,
  profilesWithoutErrors: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reOnboard: PropTypes.func,
  reOnboardLoading: PropTypes.bool,
  reuse_existing_badge_number: PropTypes.bool,
  selectedCampaigns: PropTypes.array,
  selected_client: PropTypes.string,
  selected_signer: PropTypes.string,
  selectedRows: PropTypes.array,
  sendWelcomeEmail: PropTypes.func,
  showReonboardModal: PropTypes.bool,
  showWelcomeEmailModal: PropTypes.bool,
  signers: PropTypes.array,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object
};

export default BulkModal;
