import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import Dashboard from './view';

export default connect(
  state => ({
    ...state.admin,
    ...state.transient,
    transient: state.transient,
    adminFilters: state.user.adminFilters,
    user_role: state.user.role_name,
    user: state.user
  }),
  {
    checkSendWelcomeEmail: adminActions.checkSendWelcomeEmail,
    checkVetting: adminActions.checkVetting,
    declineProfiles: adminActions.declineProfiles,
    extendVetting: adminActions.extendVetting,
    getAdminOnboardingProfiles: adminActions.getAdminOnboardingProfiles,
    getOnboardingBlocks: adminActions.getOnboardingBlocks,
    getReOnboardingCampaigns: adminActions.getReOnboardingCampaigns,
    getUserOnboardingProfile: adminActions.getUserOnboardingProfile,
    reactivateProfiles: adminActions.reactivateProfiles,
    rejectVetting: adminActions.rejectVetting,
    reOnboard: adminActions.reOnboard,
    resetTransient: transientActions.reset,
    selectedProfilesForReOnboard: adminActions.selectedProfilesForReOnboard,
    sendWelcomeEmail: adminActions.sendWelcomeEmail,
    updateAdminProps: adminActions.updateProps,
    updateTransientProps: transientActions.updateProps,
    updateTransientPropWithValidations: transientActions.updatePropWithValidations,
    updateUserProps: userActions.updateProps
  }
)(Dashboard);
