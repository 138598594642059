import { Api } from 'Core';
import Config from 'Config';
import { TABLE_CONFIG } from 'Constants';

const confirmVetting = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_update_status`, {
    data: { attributes: { onboarding_profile_ids: data, trigger: 'confirm_profile' } }
  });

const createUser = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users`, {
    data: { attributes: data }
  });

const vetProfile = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_update_status`, {
    data: { attributes: { onboarding_profile_ids: data, trigger: 'vet_profile' } }
  });

const declineProfiles = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_update_status`, {
    data: { attributes: { onboarding_profile_ids: data, trigger: 'decline_profile' } }
  });

const extendVetting = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_extend_stop_date`, {
    data: { attributes: { onboarding_profile_ids: data, trigger: 'extend_vetting' } }
  });

const getAdminOnboardingProfiles = (data = {}) => {
  const {
    active_onboarding = false,
    active_users = false,
    agent_name = '',
    badge_number = '',
    by_campaign_name = '',
    by_client_name = '',
    by_marketing_company_name = '',
    by_state = '',
    incomplete_profile_only = false,
    mc_pin = '',
    page = TABLE_CONFIG.PAGE,
    per_page = TABLE_CONFIG.ROWS
  } = data;
  return Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
      // eslint-disable-next-line
    }/onboarding_profiles?page=${page}&per_page=${per_page}&by_badge_number=${badge_number}&by_user_full_name=${agent_name}&active=${active_onboarding}&by_marketing_company_pin=${mc_pin}&active_users=${active_users}&incomplete_profile_only=${incomplete_profile_only}&by_campaign_name=${by_campaign_name}&by_client_name=${by_client_name}&by_marketing_company_name=${by_marketing_company_name}&by_state=${by_state}&include=user,client,marketing_company,campaigns,badges,reonboarding_profile`
  );
};

const getMcPin = pin => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/marketing_companies?by_pin=${pin}`);

const reactivateProfiles = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/reactivate`, {
    data: { attributes: { onboarding_profile_ids: data } }
  });

const rejectVetting = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_update_status`, {
    data: { attributes: { onboarding_profile_ids: data, trigger: 'reject_profile' } }
  });

const getUserOnboardingProfile = id =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/onboarding_profiles/${id}?include=user,badges,campaigns,marketing_company,client,onboarding_profile_audit_trails`
  );

const updateOnboardingUserSection = (data, id) =>
  Api.patch(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/${id}`, data, false);

const updateOnboardingUserProfile = (data, id) =>
  Api.patch(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/users/${id}`, data, false);

const getOnboardingBlocks = id =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_PATH_PREFIX
      // eslint-disable-next-line
    }/blocks?by_onboarding_profile=${id}&include=onboarding_profile_documents%2Cdocument_signers&sortColumn=name&sortDirection=asc`
  );

const getClients = () => Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/clients/light_index?active=true`);

const getMarketingCompanies = () =>
  Api.get(
    `${Config.API_SERVER()}${Config.API_PATH_PREFIX}/marketing_companies/light_index?sortColumn=name&sortDirection=asc`
  );

const getCampaignsByClient = id =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/campaigns/light_index?sortColumn=name&sortDirection=asc&by_client=${id}`
  );

const updateOnboardingProfileDocuments = (data, id) =>
  Api.patch(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profile_documents/${id}`, data, false);

const releaseBlock = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/blocks/bulk_create`, data, false);

const updateUserDocument = data =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/attachments/upload_user_document`, data, false);

const deleteUserAttachment = id => Api.delete(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments/${id}`);

const sendMultipleWelcomeEmails = ({ custom_footer, selectedRowsIds }) =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/send_welcome_applicant_email`, {
    data: { attributes: { onboarding_profile_ids: selectedRowsIds, custom_footer } }
  });

const sendWelcomeEmail = ({ custom_footer, email, selectedRowsIds }) =>
  Api.post(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/onboarding_profiles/${selectedRowsIds}/update_and_send_welcome_email/`,
    { data: { attributes: { email, custom_footer } } }
  );

const sendWelcomeSms = ({ custom_footer, phone_number, profileId }) =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/${profileId}/send_welcome_sms/`, {
    data: { attributes: { phone_number, custom_footer } }
  });

const reOnboard = ({ data }) =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/reonboarding`, data, false);

const getReOnboardingCampaigns = id =>
  Api.get(
    `${Config.API_SERVER()}${
      Config.API_V3_PATH_PREFIX
    }/campaigns/light_index?sortColumn=name&sortDirection=asc&by_client=${id}`
  );

const getSigners = id =>
  Api.get(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/signer_marketing_companies?by_marketing_company=${id}`);

const checkVetting = ({ selectedRowsIds }) =>
  Api.post(`${Config.API_SERVER()}${Config.API_V3_PATH_PREFIX}/onboarding_profiles/bulk_update_status`, {
    data: { attributes: { onboarding_profile_ids: selectedRowsIds, trigger: 'check_vetting' } }
  });

const getCoach = (data = {}, queryParams = '') => {
  const { pins = '', searchValue = '' } = data;

  return Api.get(
    `${Config.API_SERVER()}${
      Config.API_PATH_PREFIX
    }/users/light_index?active=true${queryParams}&by_pin=${pins}&by_full_name=${searchValue}`
  );
};

const getRecruiter = (data = '') => {
  const { pins = '', searchValue = '', recruitmentType = '' } = data;

  return Api.get(
    `${Config.API_SERVER()}${
      Config.API_PATH_PREFIX
    }/users/light_index?active=true${recruitmentType}&by_pin=${pins}&by_full_name=${searchValue}`
  );
};

const removeAttachment = id => Api.delete(`${Config.API_SERVER()}${Config.API_PATH_PREFIX}/attachments/${id}`);

const updateBadgeNumber = data => {
  // TODO: Change prefix
  const { badgeId, updatedBadgeNumber } = data;
  return Api.patch(`${Config.API_SERVER()}/v2/badges/${badgeId}`, {
    data: { attributes: { number: updatedBadgeNumber } }
  });
};

export default {
  checkVetting,
  confirmVetting,
  createUser,
  declineProfiles,
  deleteUserAttachment,
  extendVetting,
  getAdminOnboardingProfiles,
  getCampaignsByClient,
  getClients,
  getCoach,
  getMarketingCompanies,
  getMcPin,
  getOnboardingBlocks,
  getRecruiter,
  getReOnboardingCampaigns,
  getSigners,
  getUserOnboardingProfile,
  reactivateProfiles,
  rejectVetting,
  releaseBlock,
  removeAttachment,
  reOnboard,
  sendMultipleWelcomeEmails,
  sendWelcomeEmail,
  sendWelcomeSms,
  updateBadgeNumber,
  updateOnboardingProfileDocuments,
  updateOnboardingUserProfile,
  updateOnboardingUserSection,
  updateUserDocument,
  vetProfile
};
