import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { LabelWarning, TextField } from 'Components';
import { ADMIN_ROLES, ONBOARDING_PROFILE_STATES } from 'Constants';
import { handleOnChangeWithValidations, validations, getNestedValue, phoneNumberSanitization } from 'Helpers';

import {
  getDateRange,
  getFormattedValue,
  handleChangeBankAccount,
  isDisabled,
  renderSelectOptions,
  updateUserChanges
} from '../../utils/helpers';

const onChangeHandlerMap = {
  handleChangeBankAccount: handleChangeBankAccount,
  handleOnChangeWithValidations: handleOnChangeWithValidations
};

const ProfileTextField = ({
  bankAccountCheckFR,
  bankAccountCheckUK,
  config,
  isOffline,
  isReadOnly,
  transient,
  updateOnboardingUserProfile,
  updateTransientProps,
  updateTransientPropWithValidations,
  userOnboardingProfile,
  userRole
}) => {
  const validationFuncs = config.validations.map(v => validations[v]);
  const hasError = transient?.[`${config.fieldName}_has_error`] || false;
  const fieldValue = transient?.[config.fieldName];
  const isFieldValueObject = typeof fieldValue === 'object' && fieldValue !== null;
  const isUK = config?.countrySpecificField?.some(field => field === 'UK');
  const bankAccountCheck = isUK ? bankAccountCheckUK : bankAccountCheckFR;
  const userValue = userOnboardingProfile?.user?.[config.fieldName];
  const profileId = transient.userOnboardingProfile?.user_id;
  const isFieldChanged =
    config.fieldName === 'account_number'
      ? userValue?.replace(/\s+/g, '') !== fieldValue?.replace(/\s+/g, '') && !hasError
      : userValue !== fieldValue && !hasError;
  const onChange = config.onChangeHandler
    ? onChangeHandlerMap[config.onChangeHandler].bind(null, bankAccountCheck, config.fieldName)
    : handleOnChangeWithValidations.bind(this, updateTransientPropWithValidations, config.fieldName, validationFuncs);
  const isRejected = userOnboardingProfile?.rejected_fields?.includes(config.fieldName);
  const isAdmin = userRole === ADMIN_ROLES.ADMIN;

  const adminDisabledStates = [ONBOARDING_PROFILE_STATES.ONBOARDED, ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL];

  const mcAdminDisabledStates = [
    ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL,
    ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW
  ];

  const isDisabledForAdmin = isAdmin && adminDisabledStates.includes(transient.state);
  const isDisabledForMCAdmin = !isAdmin && mcAdminDisabledStates.includes(transient.state);

  return (
    <TextField
      key={config.fieldName}
      label={
        <LabelWarning
          label={I18n.t(config.label)}
          warning={isRejected}
          isRequired={config.validations.includes('isRequired')}
        />
      }
      disabled={isDisabled(config.isReadOnly, isReadOnly) || isOffline || isDisabledForAdmin || isDisabledForMCAdmin}
      placeholder={config.placeholder ? I18n.t(config.placeholder) : undefined}
      type={config.type}
      value={
        config.fieldName === 'ibanValidationStatus'
          ? transient.account_number_has_error || transient.error
            ? I18n.t('admin:accountNumberStatus.invalid')
            : I18n.t('admin:accountNumberStatus.valid')
          : config.value && isFieldValueObject
          ? getNestedValue(transient, config.value, '')
          : getFormattedValue(config, fieldValue, transient)
      }
      valueProperty={config.valueProperty ? getNestedValue(transient, config.fieldName, '') : undefined}
      validate={true}
      onInput={config.sanitization && phoneNumberSanitization}
      validateOnBlur={true}
      onChange={onChange}
      onBlur={
        isFieldChanged
          ? updateUserChanges.bind(null, {
              profileId,
              transient,
              updateOnboardingUserProfile,
              updateTransientProps,
              fieldName: config.fieldName
            })
          : undefined
      }
      inputId={config.inputId ?? undefined}
      errorMessageForce={hasError}
      errorMessage={transient?.[`${config.fieldName}_error_message`]}
      max={config.type === 'date' ? getDateRange(config).max : undefined}
      min={config.type === 'date' ? getDateRange(config).min : undefined}>
      {config.type === 'select' &&
        config.fieldName === 'gender' &&
        renderSelectOptions(
          transient?.userOnboardingProfile?.user?.gender_options || [],
          config.selectTranslationKey,
          config.fieldName
        )}
      {config.type === 'select' &&
        config.fieldName === 'company_registration_number_status' &&
        renderSelectOptions(
          transient?.userOnboardingProfile?.user?.company_registration_number_status_options || [],
          config.selectTranslationKey
        )}
    </TextField>
  );
};

ProfileTextField.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  config: PropTypes.object.isRequired,
  isOffline: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRejectedField: PropTypes.func,
  transient: PropTypes.object,
  updateOnboardingUserSection: PropTypes.func,
  updateOnboardingUserProfile: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  user: PropTypes.object,
  userOnboardingProfile: PropTypes.object,
  userRole: PropTypes.string
};

export default ProfileTextField;
