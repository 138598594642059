export const onboardingProfileTextFieldsConfig = {
  details: [
    {
      capitalizeValue: true,
      fieldName: 'client_name',
      isReadOnly: true,
      label: 'admin:onboardingProfile.client',
      type: 'text',
      validations: []
    },
    {
      capitalizeValue: true,
      fieldName: 'mc_pin',
      isReadOnly: true,
      label: 'admin:onboardingProfile.mcPin',
      type: 'text',
      validations: []
    },
    {
      capitalizeValue: true,
      fieldName: 'created_at',
      isReadOnly: true,
      label: 'admin:onboardingProfile.createdOn',
      type: 'text',
      validations: []
    },
    {
      capitalizeValue: true,
      fieldName: 'updated_at',
      isReadOnly: true,
      label: 'admin:onboardingProfile.updatedOn',
      type: 'text',
      validations: []
    },
    {
      capitalizeValue: false,
      fieldName: 'state',
      isReadOnly: true,
      label: 'admin:onboardingProfile.state',
      translateValue: true,
      type: 'text',
      validations: []
    },
    {
      capitalizeValue: false,
      fieldName: 'origin',
      isReadOnly: true,
      label: 'admin:onboardingProfile.origin',
      translateValue: true,
      type: 'text',
      validations: []
    }
  ],
  basicProfile: [
    {
      generalDetails: [
        {
          capitalizeValue: true,
          fieldName: 'first_name',
          label: 'admin:onboardingProfile.firstAndMiddleName',
          type: 'text',
          validations: ['isRequired', 'lettersOnly']
        },
        {
          capitalizeValue: true,
          fieldName: 'last_name',
          label: 'admin:onboardingProfile.lastName',
          type: 'text',
          validations: ['isRequired', 'lettersOnly']
        },
        {
          capitalizeValue: false,
          fieldName: 'email',
          label: 'admin:onboardingProfile.email',
          type: 'text',
          validations: ['isRequired', 'emailValidation']
        },
        {
          capitalizeValue: true,
          fieldName: 'phone_number',
          sanitization: true,
          label: 'admin:onboardingProfile.phoneNumber',
          type: 'text',
          validations: ['isRequired', 'phoneNumber']
        },
        {
          capitalizeValue: true,
          fieldName: 'ssn',
          countrySpecificField: ['BE'],
          label: 'admin:onboardingProfile.ssn',
          type: 'text',
          validations: ['isRequired']
        },
        {
          capitalizeValue: true,
          fieldName: 'ssn',
          countrySpecificField: ['DE', 'FR'],
          label: 'admin:onboardingProfile.ssn',
          type: 'text',
          validations: ['isRequired', 'fixed15']
        },
        {
          capitalizeValue: true,
          fieldName: 'birth_location',
          label: 'admin:onboardingProfile.birthLocation',
          type: 'text',
          validations: ['isRequired', 'lettersOnly']
        },
        {
          capitalizeValue: true,
          countrySpecificField: ['BE', 'DE', 'FR'],
          fieldName: 'cpr_number',
          label: 'admin:onboardingProfile.identityCardNumber',
          type: 'text',
          validations: ['isRequired']
        },
        {
          capitalizeValue: true,
          fieldName: 'id_expiry_date',
          countrySpecificField: ['BE', 'DE', 'FR'],
          label: 'admin:onboardingProfile.idExpiry',
          type: 'date',
          dateRange: { min: 'today', max: null },
          validations: ['isRequired', 'minDateValidation']
        },
        {
          capitalizeValue: true,
          fieldName: 'date_of_birth',
          label: 'admin:onboardingProfile.dateOfBirth',
          type: 'date',
          dateRange: { min: null, max: 'today' },
          validations: ['isRequired', 'maxDateValidation']
        },
        {
          capitalizeValue: true,
          fieldName: 'nationality',
          inputId: 'userNationality-dropdown',
          label: 'admin:onboardingProfile.nationality',
          placeholder: 'profile:identityTab.nationalityPlaceholder',
          type: 'text',
          validations: ['isRequired', 'isObjectRequired'],
          value: 'nationality.translated_nationality',
          valueProperty: true
        },
        {
          capitalizeValue: true,
          countrySpecificField: ['IE', 'UK'],
          fieldName: 'country_of_citizenship',
          inputId: 'countryOfCitizenship-dropdown',
          label: 'profile:identityTab.countryOfCitizenship',
          placeholder: 'profile:identityTab.countryOfCitizenshipPlaceholder',
          type: 'text',
          validations: ['isRequired', 'isObjectRequired'],
          value: 'country_of_citizenship.translated_name',
          valueProperty: true
        },
        {
          capitalizeValue: true,
          countrySpecificField: ['BE', 'DE', 'FR'],
          fieldName: 'birth_department',
          label: 'admin:onboardingProfile.birthDepartment',
          type: 'text',
          validations: ['birthDepartment']
        },
        {
          capitalizeValue: true,
          fieldName: 'country_of_birth',
          inputId: 'birthCountry-dropdown',
          label: 'profile:identityTab.birthCountry',
          placeholder: 'profile:identityTab.birthCountryPlaceholder',
          type: 'text',
          validations: ['isRequired', 'isObjectRequired'],
          value: 'country_of_birth.translated_name',
          valueProperty: true
        },
        {
          capitalizeValue: false,
          fieldName: 'gender',
          label: 'admin:onboardingProfile.gender',
          type: 'select',
          selectTranslationKey: 'profile:identityTab',
          validations: ['isRequired']
        },
        {
          capitalizeValue: false,
          countrySpecificField: ['DE', 'FR'],
          fieldName: 'siret_number',
          label: 'admin:onboardingProfile.siretNumber',
          selectTranslationKey: 'profile:identityTab',
          type: 'text',
          validations: []
        },
        {
          capitalizeValue: false,
          countrySpecificField: ['BE'],
          fieldName: 'company_registration_number_status',
          selectTranslationKey: 'profile:identityTab',
          label: 'admin:onboardingProfile.companyRegistrationNumberStatus',
          type: 'select',
          validations: []
        },
        {
          capitalizeValue: false,
          countrySpecificField: ['BE'],
          fieldName: 'siret_number',
          label: 'admin:onboardingProfile.btw',
          type: 'text',
          validations: []
        },
        {
          capitalizeValue: false,
          countrySpecificField: ['IE', 'UK'],
          fieldName: 'national_insurance_number',
          label: 'admin:onboardingProfile.nationalInsuranceNumber',
          type: 'text',
          validations: ['nationalInsuranceNumber']
        }
      ]
    },
    {
      homeAddress: [
        {
          capitalizeValue: true,
          fieldName: 'address_line1',
          label: 'admin:onboardingProfile.line1',
          type: 'text',
          validations: ['isRequired']
        },
        {
          capitalizeValue: true,
          fieldName: 'address_line2',
          label: 'admin:onboardingProfile.line2',
          type: 'text',
          validations: []
        },
        {
          capitalizeValue: true,
          fieldName: 'address_line3',
          label: 'admin:onboardingProfile.line3',
          type: 'text',
          validations: []
        },
        {
          capitalizeValue: true,
          fieldName: 'postcode',
          label: 'admin:onboardingProfile.postCode',
          type: 'text',
          validations: ['isRequired']
        },
        {
          capitalizeValue: true,
          fieldName: 'city',
          label: 'admin:onboardingProfile.city',
          type: 'text',
          validations: ['isRequired']
        },
        {
          capitalizeValue: true,
          fieldName: 'country',
          label: 'admin:onboardingProfile.country',
          type: 'text',
          isReadOnly: true,
          translateValue: true,
          validations: []
        },
        {
          capitalizeValue: true,
          countrySpecificField: ['BE'],
          fieldName: 'regions',
          isReadOnly: true,
          label: 'admin:onboardingProfile.pitchingRegions',
          selectTranslationKey: 'profile:contactTab',
          translateValue: true,
          type: 'text',
          validations: []
        }
      ]
    },
    {
      bankDetails: [
        {
          capitalizeValue: true,
          fieldName: 'account_number',
          label: 'admin:onboardingProfile.iban',
          countrySpecificField: ['IE', 'BE', 'DE', 'FR'],
          type: 'text',
          onChangeHandler: 'handleChangeBankAccount',
          validations: ['isRequired', 'accountNumberFormat']
        },
        {
          fieldName: 'account_number',
          label: 'admin:onboardingProfile.accountNumber',
          type: 'text',
          validations: ['isRequired'],
          onChangeHandler: 'handleChangeBankAccount',
          countrySpecificField: ['UK']
        },
        {
          fieldName: 'bank_sort_code',
          label: 'admin:onboardingProfile.sortCode',
          type: 'text',
          validations: ['isRequired'],
          onChangeHandler: 'handleChangeBankAccount',
          countrySpecificField: ['UK']
        },
        {
          capitalizeValue: true,
          fieldName: 'bic',
          countrySpecificField: ['IE', 'BE', 'DE', 'FR'],
          label: 'admin:onboardingProfile.bic',
          type: 'text',
          validations: [],
          isReadOnly: true
        },
        {
          capitalizeValue: true,
          isReadOnly: true,
          fieldName: 'ibanValidationStatus',
          label: 'admin:onboardingProfile.validationStatus',
          type: 'text',
          validations: []
        },
        {
          capitalizeValue: true,
          fieldName: 'bank_name',
          label: 'admin:onboardingProfile.bankName',
          type: 'text',
          validations: [],
          isReadOnly: true
        },
        {
          capitalizeValue: true,
          fieldName: 'account_holder_name',
          label: 'admin:onboardingProfile.accountHolderName',
          type: 'text',
          validations: ['isRequired']
        }
      ]
    },
    {
      emergency: [
        {
          capitalizeValue: true,
          fieldName: 'emergency_name',
          label: 'admin:onboardingProfile.emergencyFullName',
          type: 'text',
          validations: ['isRequired', 'lettersOnly']
        },
        {
          capitalizeValue: true,
          fieldName: 'emergency_phone_number',
          sanitization: true,
          label: 'admin:onboardingProfile.emergencyPhone',
          type: 'text',
          validations: ['isRequired', 'phoneNumber']
        }
      ]
    }
  ]
};
