import PropTypes from 'prop-types';
import { Checkbox, List } from 'framework7-react';
import React, { useEffect, useState } from 'react';

import { I18n } from 'Locales';
import { RowView } from 'Containers';
import { mockFunction } from 'Helpers';
import { CLIENT_TYPE, REGIONS } from 'Constants';
import { SearchAndSelect, TextField } from 'Components';

const handleSelect = ({ callback, items, key, setSelectedCampaign, updateTransientProps }, e) => {
  const selectedIndex = e.target.value;
  const selectedOption = items[selectedIndex];

  updateTransientProps({ [key]: selectedOption.id });
  setSelectedCampaign && setSelectedCampaign(selectedOption);
  callback && callback(selectedOption.id);
};

const handleSelectSigner = (updateTransientProps, key, e) => {
  updateTransientProps({ [key]: e.target.value });
};

const renderOptions = ({ options, type }) => {
  const selectableOptions = options.map((option, index) => (
    <option key={option.id || index} value={index}>
      {option.name}
    </option>
  ));
  selectableOptions.unshift(
    <option key="default" value="" disabled selected={true}>
      {I18n.t(`admin:reOnboardModal.${type}`)}
    </option>
  );

  return selectableOptions;
};

const renderSigners = ({ signers }) => {
  const selectableOptions = signers.map((signer = {}, index) => (
    <option key={index} value={signer?.signer?.id} select={index[0]}>
      {signer?.signer?.full_name}
    </option>
  ));

  return selectableOptions;
};

const ReonboardModal = ({
  campaigns = [],
  clients = [],
  getReOnboardingCampaigns = mockFunction,
  reuse_existing_badge_number = true,
  selected_client = '',
  selectedRows = [],
  signers = [],
  updateTransientProps = mockFunction
}) => {
  const selectedUserCountry = selectedRows.every(selectedUser => REGIONS.UK.includes(selectedUser.user.country));
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const showReuseBadgeCheckbox = selectedUserCountry && selectedCampaign.client_type === CLIENT_TYPE.COMMERCIAL;

  useEffect(() => {
    updateTransientProps({ selected_signer: signers[0]?.signer?.id });
  }, [signers]);

  return (
    <>
      <RowView>
        <div className="title-md font-bold">{I18n.t('admin:reOnboardModal.reOnboardBA')}</div>
      </RowView>

      <List noHairlines className="w-full">
        <TextField
          label={I18n.t('admin:reOnboardModal.client')}
          onChange={handleSelect.bind(null, {
            callback: getReOnboardingCampaigns,
            items: clients,
            key: 'selected_client',
            setSelectedCampaign,
            updateTransientProps
          })}
          type="select">
          {renderOptions({ options: clients, type: 'clientPlaceholder' })}
        </TextField>

        <SearchAndSelect
          displayProperty="name"
          fieldName="selectedCampaigns"
          label={I18n.t('admin:reOnboardModal.campaign')}
          multiple={true}
          options={campaigns}
          restKey={selected_client}
          selectedProperty="id"
          updateTransientProps={updateTransientProps}
        />

        <TextField
          label={I18n.t('admin:reOnboardModal.signers')}
          onChange={handleSelectSigner.bind(null, updateTransientProps, 'selected_signer')}
          type="select">
          {renderSigners({ signers })}
        </TextField>

        {showReuseBadgeCheckbox && (
          <RowView justifyContent={'flex-start'} paddingHorizontal={16}>
            <Checkbox
              checked={reuse_existing_badge_number}
              onChange={updateTransientProps.bind(null, { reuse_existing_badge_number: !reuse_existing_badge_number })}
            />
            <div className="pl-8 pt-2">{I18n.t('admin:reOnboardModal.reuseBadge')}</div>
          </RowView>
        )}
      </List>

      <div className="names-container">
        {selectedRows.map((profile, index) => (
          <span key={index} className="text-sm font-bold mr-4">
            {profile.name}
            {index < selectedRows.length && ','}
          </span>
        ))}
        <span>{I18n.t('admin:reOnboardModal.willBeReOnboarded')}</span>
      </div>
    </>
  );
};

ReonboardModal.propTypes = {
  campaigns: PropTypes.array,
  clients: PropTypes.array,
  getReOnboardingCampaigns: PropTypes.func,
  reuse_existing_badge_number: PropTypes.bool,
  selected_client: PropTypes.string,
  selectedRows: PropTypes.array,
  signers: PropTypes.array,
  updateTransientProps: PropTypes.func
};

export default ReonboardModal;
