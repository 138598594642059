import React from 'react';
import PropTypes from 'prop-types';

import { mockFunction } from 'Helpers';

import './style.scss';
import { shouldHideToolbar } from './utils/helpers';
import { CheckInBottomBar, DefaultBottomBar, FlowBottomBar } from './subviews';

const AppBottomBar = ({
  application,
  checkIn,
  currentRouteName,
  flow,
  isOffline,
  logout,
  mode,
  offlineResults,
  resetOnboardingProfilePages,
  resetTransient,
  session = false,
  setSelectedPostcodes,
  summaryFlowPage,
  transient,
  updateApplicationProp,
  updateFlowProps,
  updateTransientProps,
  user
}) => {
  if (shouldHideToolbar(currentRouteName) || (!session && currentRouteName !== 'TermsAndConditions')) {
    return null;
  }

  let toolbarContent;

  switch (currentRouteName) {
    case 'Flow':
      toolbarContent = <FlowBottomBar {...{ mode, flow, isOffline, logout, summaryFlowPage, updateFlowProps }} />;
      break;
    case 'CheckInList':
      toolbarContent = <CheckInBottomBar {...{ checkIn, setSelectedPostcodes, transient, updateTransientProps }} />;
      break;
    case 'TermsAndConditions':
    case 'Birthday':
    case 'Carousel':
    case 'Finish':
    case 'FullName':
    case 'Login':
    case 'SignUp':
    case 'VersionUpdate':
      break;
    default:
      toolbarContent = (
        <DefaultBottomBar
          {...{ application, offlineResults, resetOnboardingProfilePages, resetTransient, updateApplicationProp, user }}
        />
      );
      break;
  }

  return toolbarContent;
};

AppBottomBar.propTypes = {
  currentRouteName: PropTypes.string,
  flow: PropTypes.object,
  isOffline: PropTypes.bool,
  isSubmittedOffline: PropTypes.bool,
  logout: PropTypes.func,
  mode: PropTypes.string,
  resetOnboardingProfilePages: PropTypes.func,
  resetTransient: PropTypes.func,
  session: PropTypes.bool,
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

AppBottomBar.defaultProps = {
  currentRouteName: '',
  flow: {},
  isOffline: false,
  isSubmittedOffline: false,
  logout: mockFunction,
  mode: '',
  resetOnboardingProfilePages: mockFunction,
  resetTransient: mockFunction,
  transient: {},
  updateTransientProps: mockFunction
};

export default AppBottomBar;
