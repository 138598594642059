import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Page, Searchbar } from 'framework7-react';

import { I18n } from 'Locales';
import { User } from 'Repositories';
import { RowView } from 'Containers';
import { AppHeader } from 'Components';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { debounce, mockFunction } from 'Helpers';

import './style.scss';

const handleSearch = debounce((getPostCodes, updateTransientProps, searchSuccess, filteredAddressByPostCodes, e) => {
  const { value } = e.target;
  const trimmedValue = value.trim();

  if (trimmedValue.length < 4) {
    return;
  }

  if (
    searchSuccess !== value.substring(0, searchSuccess.length) ||
    searchSuccess.length === 0 ||
    filteredAddressByPostCodes?.length === 0
  ) {
    getPostCodes(value);
    updateTransientProps({ searchSuccess: value });
  }
  updateTransientProps({ search: value });
}, 500);

const handleUKSearch = debounce((getUKAddress, updateTransientProps, e) => {
  const text = e.target.value.trim();
  if (text.length === 0) {
    updateTransientProps({
      uk_address: []
    });
  } else {
    const data = { text };
    getUKAddress(data);
  }
}, 500);

const filteredAddressByFullName = (filteredAddressByPostCodes, search = '', searchSuccess = '') =>
  filteredAddressByPostCodes?.filter(element =>
    element.full_address?.includes(search.substring(searchSuccess.length, search.length))
  );

const onClickHandler = (
  updateTransientProps,
  updateUser,
  address_line1,
  address_line2,
  address_line3,
  city,
  postcode
) => {
  const city_has_error = city.length === 0;
  const postcode_has_error = postcode.length === 0;
  const address_line1_has_error = address_line1.length === 0;

  updateTransientProps({
    address_line1_has_error,
    address_line1,
    address_line2,
    address_line3,
    city_has_error,
    city,
    filteredAddressByPostCodes: [],
    postcode_has_error,
    postcode
  });

  const newUser = {
    data: {
      attributes: {
        address_line1,
        city,
        postcode,
        address_line2,
        address_line3
      }
    }
  };
  updateUser(newUser);

  NavigationService.goBack({ name: PAGE_NAMES.PROFILE });
};

const onClickUKHandler = ({ updateTransientProps, updateUser, item }) => {
  User.getUKAddress(item).then(cb => {
    if (cb.status === 200) {
      const address_uk_find = cb.data[0];
      const city_has_error = address_uk_find.town === 0;
      const postcode_has_error = address_uk_find.postcode === 0;
      const address_line1_has_error = address_uk_find.address_line1 === 0;

      const newUser = {
        data: {
          attributes: {
            city: address_uk_find.town,
            postcode: address_uk_find.postcode,
            address_line1: address_uk_find.address_line1,
            address_line2: address_uk_find.address_line2,
            address_line3: address_uk_find.address_line3
          }
        }
      };
      updateUser(newUser);

      updateTransientProps({
        address_line1_has_error,
        city_has_error,
        postcode_has_error,
        uk_address: []
      });

      NavigationService.goBack({ name: PAGE_NAMES.PROFILE });
    }
  });
};

const PostCodesList = ({
  country,
  filteredAddressByPostCodes,
  getPostCodes,
  getUKAddress,
  search,
  searchSuccess,
  uk_address,
  updateTransientProps,
  updateUser
}) => (
  <Page id="profile-postcodes">
    <AppHeader
      user
      goBack
      title={I18n.t('profile:contactTab.addressFinder')}
      handleClick={NavigationService.goBack.bind(null, { name: PAGE_NAMES.PROFILE })}
    />
    <RowView>
      <Searchbar
        onChange={
          country === 'UK'
            ? handleUKSearch.bind(null, getUKAddress, updateTransientProps)
            : handleSearch.bind(null, getPostCodes, updateTransientProps, searchSuccess, filteredAddressByPostCodes)
        }
        onClickClear={
          country === 'UK'
            ? updateTransientProps.bind(null, { uk_address: [] })
            : updateTransientProps.bind(null, { search: '' })
        }
        placeholder={I18n.t(`profile:contactTab.${country === 'UK' ? 'searchUK' : 'search'}`)}
      />
    </RowView>
    {uk_address && !!uk_address.length ? (
      <List className="mt-0">
        {uk_address.map((item, index) => {
          const { id, type, full_address } = item;
          const address_params = {
            id,
            type,
            text: full_address.split('(More Addresses)')[0].trim()
          };

          return (
            <ListItem
              link
              key={index}
              onClick={
                item.type === 'Address'
                  ? onClickUKHandler.bind(null, { updateTransientProps, updateUser, item })
                  : getUKAddress.bind(null, address_params)
              }>
              {item.full_address}
            </ListItem>
          );
        })}
      </List>
    ) : (
      <List className="mt-0">
        {filteredAddressByFullName(filteredAddressByPostCodes, search, searchSuccess)?.map((addressDetails, index) => (
          <ListItem
            link
            key={index}
            onClick={onClickHandler.bind(
              null,
              updateTransientProps,
              updateUser,
              addressDetails.address_line1,
              addressDetails.address_line2,
              addressDetails.address_line3,
              addressDetails.town,
              addressDetails.postcode,
              filteredAddressByPostCodes
            )}>
            {addressDetails.full_address}
          </ListItem>
        ))}
      </List>
    )}
  </Page>
);

PostCodesList.propTypes = {
  country: PropTypes.string,
  filteredAddressByPostCodes: PropTypes.array,
  getPostCodes: PropTypes.func,
  getUKAddress: PropTypes.func,
  search: PropTypes.string,
  searchSuccess: PropTypes.string,
  uk_address: PropTypes.array,
  updateTransientProps: PropTypes.func,
  updateUser: PropTypes.func
};

PostCodesList.defaultProps = {
  country: '',
  filteredAddressByPostCodes: [],
  getPostCodes: mockFunction,
  getUKAddress: mockFunction,
  search: '',
  searchSuccess: '',
  uk_address: [],
  updateTransientProps: mockFunction,
  updateUser: mockFunction
};

export default PostCodesList;
