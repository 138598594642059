import React from 'react';

const PlayArrow = props => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <mask id="a" width="20" height="20" x="0" y="0" maskUnits="userSpaceOnUse" mask-type="alpha">
      <path fill="#D9D9D9" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        //eslint-disable-next-line no-useless-escape, max-len
        d="M8.234 14.348a.684.684 0 0 1-.763.027.722.722 0 0 1-.388-.672V6.296c0-.301.13-.525.388-.671a.684.684 0 0 1 .763.027l5.83 3.712a.72.72 0 0 1 .343.636.72.72 0 0 1-.343.636l-5.83 3.712Z"
      />
    </g>
  </svg>
);

export default PlayArrow;
