import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView } from 'Containers';
import { FormattedLabel } from 'Helpers';
import { COUNTRIES, SHORT_CODE_COUNTRIES } from 'Constants';

import '../style.scss';
import { getFieldProp, getIconColor } from '../../../shared';

const AddressFields = props => {
  const { country, fields, isOffline, required, setValues, shouldShowError, transient, value } = props;
  const { address_field_disabled = true } = transient;
  const fieldDisabled = country === SHORT_CODE_COUNTRIES.UK && address_field_disabled && !isOffline;

  let addressValues = value;
  if (!addressValues) {
    addressValues = {
      City: '',
      AddressLine1: '',
      AddressLine2: '',
      AddressLine3: '',
      PostCode: '',
      search_address: ''
    };
  }

  const cityProp = getFieldProp(fields, 'City');
  const line1Prop = getFieldProp(fields, 'AddressLine1');
  const line2Prop = getFieldProp(fields, 'AddressLine2');
  const line3Prop = getFieldProp(fields, 'AddressLine3');
  const postCodeProp = getFieldProp(fields, 'PostCode');

  const { City, AddressLine1, AddressLine2, AddressLine3, PostCode } = addressValues;
  return (
    <ColumnView>
      <div className="w-full">
        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.line1') })}
          type={'text'}
          placeholder={line1Prop?.placeholder}
          value={AddressLine1}
          onChange={e => setValues(value, { AddressLine1: e.target.value })}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && AddressLine1 === '', AddressLine1)}
        />

        <TextField
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line2')}
          type={'text'}
          placeholder={line2Prop?.placeholder}
          value={AddressLine2}
          onChange={e => setValues(value, { AddressLine2: e.target.value })}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(country === COUNTRIES.UK && shouldShowError && AddressLine2 === '', AddressLine2)}
        />

        <TextField
          disabled={fieldDisabled}
          label={I18n.t('flow:components.address.line3')}
          type={'text'}
          placeholder={line3Prop?.placeholder}
          value={AddressLine3}
          onChange={e => setValues(value, { AddressLine3: e.target.value })}
          errorMessageForce={shouldShowError && AddressLine1 === '' && AddressLine2 === '' && AddressLine3 === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(country === COUNTRIES.UK && shouldShowError && AddressLine3 === '', AddressLine3)}
        />

        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.postalCodeLabel') })}
          type={'text'}
          placeholder={postCodeProp?.placeholder}
          value={PostCode}
          onChange={e => setValues(value, { PostCode: e.target.value })}
          errorMessageForce={shouldShowError && PostCode === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && PostCode === '', PostCode)}
        />

        <TextField
          disabled={fieldDisabled}
          label={FormattedLabel({ required, name: I18n.t('flow:components.address.cityLabel') })}
          type={'text'}
          placeholder={cityProp?.placeholder}
          value={City}
          onChange={e => setValues(value, { City: e.target.value })}
          errorMessageForce={shouldShowError && City === ''}
          errorMessage={I18n.t('flow:generalError')}
          icon="material:check"
          iconColor={getIconColor(shouldShowError && City === '', City)}
        />
      </div>
    </ColumnView>
  );
};

AddressFields.propTypes = {
  component_key: PropTypes.any,
  country: PropTypes.string,
  currentCheckInAdress: PropTypes.array,
  fields: PropTypes.array,
  isOffline: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  setValues: PropTypes.func,
  shouldShowError: PropTypes.bool,
  transient: PropTypes.object,
  updateValue: PropTypes.func,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.object)
};

export default AddressFields;
