import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Page } from 'framework7-react';

import { I18n } from 'Locales';
import { mockFunction } from 'Helpers';
import { PAGE_NAMES } from 'Constants';
import { NavigationService } from 'Services';
import { AppHeader, Button } from 'Components';
import { ColumnView, RowView } from 'Containers';
import { onOnline } from 'Sagas/application/onOnline';

import './style.scss';
import BlockedLogoutModal from './BlockedLogoutModal';

const hasOfflineResults = ({ setOpenPopup }) => {
  setOpenPopup(false);
  onOnline();
};

const handleClick = ({ logout, offlineResults, setOpenPopup }) => {
  if (offlineResults?.length) {
    setOpenPopup(true);
  } else {
    logout();
  }
};

const Tools = ({ isOffline, logout, offlineResults }) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <Page id="tools">
      <AppHeader title={I18n.t('general:tools')} />
      <ColumnView minHeight={'calc(100% - 74px)'} justifyContent="space-between" paddingHorizontal={20}>
        <div className="w-full mt-32">
          <Button.Outline
            className="line-height-normal mb-24 h-48"
            onClick={NavigationService.navigate.bind(null, {
              name: PAGE_NAMES.REPORTS
            })}>
            <RowView justifyContent="center" marginLeft={20}>
              {I18n.t('general:reports')}
            </RowView>
            <RowView justifyContent={'flex-end'} width={30}>
              {!!offlineResults.length && <div className="circle-info text-sm">{offlineResults.length}</div>}
            </RowView>
          </Button.Outline>
          <Button.Outline
            className="line-height-normal mb-24 h-48"
            disabled={isOffline}
            onClick={NavigationService.navigate.bind(null, {
              name: PAGE_NAMES.SETTINGS
            })}>
            {I18n.t('general:settings')}
          </Button.Outline>
          <Button.Outline
            className="line-height-normal mb-24 h-48"
            disabled={isOffline}
            onClick={NavigationService.navigate.bind(null, {
              name: PAGE_NAMES.FEEDBACK
            })}>
            {I18n.t('general:feedback')}
          </Button.Outline>
        </div>
        <ColumnView marginBottom={10} justifyContent={'flex-end'}>
          <div className="w-full">
            <Button.Primary
              className=" line-height-normal h-48"
              disabled={isOffline}
              onClick={handleClick.bind(null, { logout, offlineResults, setOpenPopup })}>
              {I18n.t('general:logout')}
            </Button.Primary>
          </div>
        </ColumnView>
      </ColumnView>

      <BlockedLogoutModal hasOfflineResults={hasOfflineResults} openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </Page>
  );
};

Tools.propTypes = {
  isOffline: PropTypes.bool,
  logout: PropTypes.func,
  offlineResults: PropTypes.array
};

Tools.defaultProps = {
  isOffline: false,
  logout: mockFunction,
  offlineResults: []
};

export default Tools;
