import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { f7, Page, Tab, Tabs, Toolbar as ToolbarF7 } from 'framework7-react';

import { I18n } from 'Locales';
import { ColumnView } from 'Containers';
import { ALERT_TYPES, TABS } from 'Constants';
import { AppHeader, Banner } from 'Components';
import { handleOnChangeWithValidations, isObjEmpty, mockFunction, validations } from 'Helpers';

import './style.scss';
import ProfileTab from './subviews/ProfileTab';
import ContactTab from './subviews/ContactTab';
import IdentityTab from './subviews/IdentityTab';
import DocumentsTab from './subviews/DocumentsTab';
import ProfileTabSegment from './ProfileTabSegment';
import BankDetailsTab from './subviews/BankDetailsTab';
import { handleChangeBankAccount, renderProfileNavButtons } from './utils/helpers';

const pages = {
  bank_details: BankDetailsTab,
  contact: ContactTab,
  documents: DocumentsTab,
  identity: IdentityTab,
  profile: ProfileTab
};

const findMatchingCountry = (user, prop, country) => country.short_code === user[prop];
const findMatchingNationality = (user, prop, country) => country.nationality === user[prop];

const removeDropDown = autocompleteDropdownAll => autocompleteDropdownAll.current?.destroy();

const initDropDown = (
  { autocompleteDropdownAll = {}, updateTransientPropWithValidations, countries, updateUser },
  page
) => {
  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#userNationality-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'nationality',
    textProperty: 'translated_nationality',
    source(query, render) {
      const results = [];
      const uniqueNationalities = new Set();

      for (let i = 0; i < countries?.length; i += 1) {
        const country = countries[i];
        const { translated_nationality, nationality } = country;

        if (translated_nationality.toLowerCase().includes(query.toLowerCase())) {
          if (!uniqueNationalities.has(nationality)) {
            uniqueNationalities.add(nationality);
            results.push({ translated_nationality, nationality });
          }
        }
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'nationality',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUser({}, null, 'nationality');
      }
    }
  });

  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#countryOfCitizenship-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'short_code',
    textProperty: 'translated_name',
    source(query, render) {
      const results = [];
      for (let i = 0; i < countries?.length; i += 1) {
        if (countries[i].translated_name.toLowerCase().indexOf(query.toLowerCase()) >= 0)
          results.push({
            translated_name: countries[i].translated_name,
            short_code: countries[i].short_code
          });
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'country_of_citizenship',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUser({}, null, 'country_of_citizenship');
      }
    }
  });

  autocompleteDropdownAll.current = f7.autocomplete.create({
    inputEl: page.$el.find('#birthCountry-dropdown'),
    openIn: 'dropdown',
    valueProperty: 'short_code',
    textProperty: 'translated_name',
    source(query, render) {
      const results = [];
      for (let i = 0; i < countries?.length; i += 1) {
        if (countries[i].translated_name.toLowerCase().indexOf(query.toLowerCase()) >= 0)
          results.push({
            translated_name: countries[i].translated_name,
            short_code: countries[i].short_code
          });
      }

      render(results);
    },
    on: {
      change: countries =>
        handleOnChangeWithValidations(
          updateTransientPropWithValidations,
          'country_of_birth',
          [validations.isObjectRequired],
          {
            target: { value: countries[0] }
          }
        ),
      closed: () => {
        updateUser({}, null, 'country_of_birth');
      }
    }
  });
};

const Profile = ({
  bankAccountCheckFR,
  bankAccountCheckUK,
  campaign,
  currentPage,
  getOnboardingProfiles,
  getRoles,
  getUser,
  isOffline,
  onboarding_profile,
  profilePages,
  transient,
  updateProp,
  updateTransientProps,
  updateTransientPropWithValidations,
  updateUser,
  updateUserAvatar,
  user
}) => {
  const {
    account_holder_name,
    account_number,
    address_line2,
    bank_name,
    bank_sort_code,
    bic,
    birth_department,
    birth_location,
    city,
    company_registration_number_status,
    countries,
    country_of_birth,
    country_of_citizenship,
    country,
    cpr_number,
    date_of_birth,
    email,
    emergency_name,
    emergency_phone_number,
    filteredAddressByPostCodes,
    first_name,
    gender,
    id_expiry_date,
    last_name,
    national_insurance_number,
    nationality,
    phone_number,
    postcode,
    regions,
    siret_number,
    ssn
  } = user;

  const matchingNationality = user?.countries?.find(findMatchingNationality.bind(null, user, 'nationality'));
  const matchingBirthCountry = user?.countries?.find(findMatchingCountry.bind(null, user, 'country_of_birth'));
  const matchingCitizenship = user?.countries?.find(findMatchingCountry.bind(null, user, 'country_of_citizenship'));

  useEffect(() => {
    updateTransientProps({
      account_holder_name: user.account_holder_name,
      account_number: user.account_number,
      address_line1: user.address_line1,
      address_line2: user.address_line2,
      address_line3: user.address_line3,
      bank_name: user.bank_name,
      bank_sort_code: user.bank_sort_code,
      bic: user.bic,
      birth_department: user.birth_department,
      birth_location: user.birth_location,
      city: user.city,
      company_registration_number_status: user.company_registration_number_status,
      countries: user.countries,
      country_of_birth: matchingBirthCountry,
      country_of_citizenship: matchingCitizenship,
      country: user.country,
      cpr_number: user.cpr_number,
      date_of_birth: user.date_of_birth,
      email: user.email,
      emergency_name: user.emergency_name,
      emergency_phone_number: user.emergency_phone_number,
      filteredAddressByPostCodes: user.filteredAddressByPostCodes,
      first_name: user.first_name,
      gender: user.gender,
      id_expiry_date: user.id_expiry_date,
      last_name: user.last_name,
      national_insurance_number: user.national_insurance_number,
      nationality: matchingNationality,
      phone_number: user.phone_number,
      postcode: user.postcode,
      regions: user.regions,
      showProfileRejectionModal: transient.showProfileRejectionModal,
      showBankRejectionModal: transient.showBankRejectionModal,
      showIdentityRejectionModal: transient.showIdentityRejectionModal,
      showContactRejectionModal: transient.showContactRejectionModal,
      siret_number: user.siret_number,
      ssn: user.ssn
    });
  }, [
    account_holder_name,
    account_number,
    address_line2,
    bank_name,
    bank_sort_code,
    bic,
    birth_department,
    birth_location,
    city,
    company_registration_number_status,
    country_of_birth,
    country_of_citizenship,
    country,
    cpr_number,
    date_of_birth,
    email,
    emergency_name,
    emergency_phone_number,
    filteredAddressByPostCodes,
    first_name,
    gender,
    id_expiry_date,
    last_name,
    national_insurance_number,
    nationality,
    phone_number,
    postcode,
    regions,
    siret_number,
    ssn
  ]);

  const { id: userId } = user;

  useEffect(() => {
    getOnboardingProfiles(userId);
    getUser(userId);
    getRoles();
  }, []);

  const autocompleteDropdownAll = useRef(null);

  const currentIndex = profilePages.indexOf(currentPage);

  const isFirstPage = currentIndex === 0;
  const isLastPage = currentIndex === profilePages.length - 1;

  const prevPage = isFirstPage ? null : profilePages[currentIndex - 1];
  const nextPage = isLastPage ? null : profilePages[currentIndex + 1];

  const renderBanner = () => {
    const isUserCancelled = !user?.active;
    const isUserUnderReview =
      user?.blank_fields?.length === 0 && !user?.onboarding_profile?.has_rejected_fields && user.badges.length === 0;

    const offlineBannerTitle = I18n.t('general:isOfflineBanner:title');
    const offlineBannerContent = I18n.t('general:isOfflineBanner:description');
    const inactiveBannerTitle = I18n.t('general:inactiveAccountBanner:title');
    const inactiveBannerContent = I18n.t('general:inactiveAccountBanner:description');
    const underReviewBannerTitle = I18n.t('general:underReviewBanner:title');
    const underReviewBannerContent = I18n.t('general:underReviewBanner:description');

    return (
      <>
        <Banner type={ALERT_TYPES.INFO} title={offlineBannerTitle} content={offlineBannerContent} show={isOffline} />
        <Banner
          type={ALERT_TYPES.INFO}
          title={isUserCancelled ? inactiveBannerTitle : underReviewBannerTitle}
          content={isUserCancelled ? inactiveBannerContent : underReviewBannerContent}
          show={!isOffline && (isUserCancelled || isUserUnderReview)}
        />
      </>
    );
  };

  useEffect(() => {
    document.querySelector('.page-current > .page-content').scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  return (
    <Page
      id={'profile'}
      onPageAfterIn={initDropDown.bind(null, {
        autocompleteDropdownAll,
        updateTransientPropWithValidations,
        countries,
        updateUser
      })}
      onPageBeforeRemove={removeDropDown.bind(null, autocompleteDropdownAll)}>
      <AppHeader title={I18n.t('general:profile')} user banner={renderBanner()}>
        {!isObjEmpty(user) && (
          <ToolbarF7 className="navbar-style" position={'top'} tabbar scrollable noShadow noHairLine>
            {profilePages?.map((page, index) => (
              <ProfileTabSegment
                currentPage={currentPage}
                updateProp={updateProp}
                user={user}
                page={page}
                key={index}
              />
            ))}
          </ToolbarF7>
        )}
      </AppHeader>
      <ColumnView>
        {!isObjEmpty(user) && (
          <Tabs className="w-full">
            {profilePages.map((page, index) => {
              const PageComp = pages[page] || pages[TABS.PROFILE];
              return (
                <Tab id={page} key={index} tabActive={page === currentPage}>
                  <PageComp
                    bankAccountCheckFR={bankAccountCheckFR}
                    bankAccountCheckUK={bankAccountCheckUK}
                    campaign={campaign}
                    handleChangeBankAccount={handleChangeBankAccount}
                    handleOnChangeWithValidations={handleOnChangeWithValidations}
                    onboarding_profile={onboarding_profile}
                    getRoles={getRoles}
                    isOffline={isOffline}
                    transient={transient}
                    updateTransientProps={updateTransientProps}
                    updateTransientPropWithValidations={updateTransientPropWithValidations}
                    updateUserAvatar={updateUserAvatar}
                    updateUser={updateUser}
                    user={user}
                  />
                  {renderProfileNavButtons(isFirstPage, isLastPage, prevPage, nextPage, updateProp)}
                </Tab>
              );
            })}
          </Tabs>
        )}
      </ColumnView>
    </Page>
  );
};

Profile.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  campaign: PropTypes.object,
  currentPage: PropTypes.string,
  getOnboardingProfiles: PropTypes.func,
  getRoles: PropTypes.func,
  getUser: PropTypes.func,
  isOffline: PropTypes.bool,
  onboarding_profile: PropTypes.object,
  profilePages: PropTypes.array,
  transient: PropTypes.object,
  updateProp: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUser: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  user: PropTypes.object
};

Profile.defaultProps = {
  bankAccountCheckFR: mockFunction,
  bankAccountCheckUK: mockFunction,
  campaign: {},
  currentPage: '',
  getOnboardingProfiles: mockFunction,
  getRoles: mockFunction,
  getUser: mockFunction,
  isOffline: false,
  onboarding_profile: {},
  profilePages: [],
  transient: {},
  updateProp: mockFunction,
  updateTransientProps: mockFunction,
  updateTransientPropWithValidations: mockFunction,
  updateUser: mockFunction,
  updateUserAvatar: mockFunction,
  user: {}
};

export default Profile;
