export const createUserFields = [
  {
    capitalizeValue: true,
    fieldName: 'first_name',
    label: 'admin:createUserFields.first_name',
    type: 'text',
    validations: ['isRequired']
  },
  {
    capitalizeValue: true,
    fieldName: 'last_name',
    label: 'admin:createUserFields.last_name',
    type: 'text',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    fieldName: 'email',
    label: 'admin:createUserFields.email',
    type: 'text',
    validations: ['isRequired', 'emailValidation']
  },
  {
    capitalizeValue: true,
    fieldName: 'phone_number',
    sanitization: true,
    label: 'admin:createUserFields.phone_number',
    type: 'text',
    validations: ['phoneNumber']
  },
  {
    capitalizeValue: false,
    fieldName: 'country',
    displayIfCanSimpleCreateUsers: false,
    label: 'admin:createUserFields.country',
    type: 'select',
    selectTranslationKey: 'admin:createUserFields.translatedKeys',
    validations: ['isRequired']
  },
  {
    capitalizeValue: true,
    fieldName: 'regions',
    countrySpecificField: ['BE'],
    roleSpecificField: ['Applicant'],
    multiple: true,
    searchAndSelect: true,
    label: 'admin:createUserFields.pitchingRegions',
    selectTranslationKey: 'admin:createUserFields.translatedKeys',
    type: 'select',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    fieldName: 'role',
    label: 'admin:createUserFields.role',
    type: 'select',
    selectTranslationKey: 'admin:createUserFields.translatedKeys',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    roleSpecificField: ['Applicant', 'MC Admin', 'MC Bookkeeper', 'MC Recruiter', 'Owner'],
    fieldName: 'pins',
    multiple: true,
    searchAndSelect: true,
    label: 'admin:createUserFields.mc_pin',
    type: 'select',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    fieldName: 'marketing_companies',
    isReadOnly: true,
    roleSpecificField: ['Applicant', 'MC Admin', 'MC Bookkeeper', 'MC Recruiter', 'Owner'],
    displayIfCanSimpleCreateUsers: false,
    label: 'admin:createUserFields.marketing_companies',
    type: 'text',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    roleSpecificField: ['Applicant'],
    fieldName: 'recruitment_source',
    label: 'admin:createUserFields.recruitment_source',
    type: 'select',
    validations: ['isRequired']
  },
  {
    capitalizeValue: false,
    roleSpecificField: ['Applicant'],
    fieldName: 'recruiter',
    label: 'admin:createUserFields.recruiter',
    searchAndSelect: true,
    displayProperty: 'full_name',
    type: 'select',
    validations: ['']
  },
  {
    capitalizeValue: false,
    fieldName: 'coach',
    roleSpecificField: ['Applicant'],
    label: 'admin:createUserFields.coach',
    searchAndSelect: true,
    displayProperty: 'full_name',
    type: 'select',
    validations: ['']
  },
  {
    capitalizeValue: false,
    fieldName: 'language',
    label: 'admin:createUserFields.language',
    type: 'select',
    selectTranslationKey: 'admin:createUserFields.translatedKeys',
    validations: ['isRequired']
  }
];
