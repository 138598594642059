import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Link, Page, Tab, Tabs, Toolbar as ToolbarF7 } from 'framework7-react';

import Config from 'Config';
import { Exit } from 'Icons';
import { I18n } from 'Locales';
import { ColumnView } from 'Containers';
import { NavigationService } from 'Services';
import { AppHeader, Banner } from 'Components';
import { handleOnChangeWithValidations, mockFunction } from 'Helpers';
import { ADMIN_ROLES, ALERT_TYPES, ONBOARDING_PROFILE_STATES, ONBOARDING_PROFILE_TABS, PAGE_NAMES } from 'Constants';

import './style.scss';
import Block from './subviews/Block';
import Badges from './subviews/Badges';
import Details from './subviews/Details';
import Activity from './subviews/Activity';
import BasicProfile from './subviews/BasicProfile';
import { DocumentDetails, ReleaseBlock, SimpleModal } from './subviews/Components';
import {
  initDropDown,
  renderOnboardingProfileNavButtons,
  renderReleaseNextBlock,
  renderVetProfile,
  updateUserChanges
} from './utils/helpers';

const pages = {
  activity: Activity,
  badges: Badges,
  basic: BasicProfile,
  block1: Block,
  block2: Block,
  block3: Block,
  block4: Block,
  details: Details
};

const findMatchingCountry = (userOnboardingProfile, prop, country) =>
  country?.short_code === userOnboardingProfile?.[prop];
const findMatchingNationality = (userOnboardingProfile, prop, country) =>
  country?.nationality === userOnboardingProfile?.[prop];

const removeDropDown = autocompleteDropdownAll => autocompleteDropdownAll.current?.destroy();

const handleDeclineVetting = ({
  declineOnboardingProfile,
  hasRejectedDocuments,
  hasRejectedFields,
  updateGrowlProps,
  userOnboardingProfile
}) => {
  if (!hasRejectedDocuments && !hasRejectedFields) {
    updateGrowlProps({
      visible: true,
      title: I18n.t('growl:error.declineVettingInvalid.title'),
      body: I18n.t('growl:error.declineVettingInvalid.body'),
      kind: 'error'
    });

    setTimeout(() => {
      updateGrowlProps({
        visible: false
      });
    }, Config.GROWL_AUTOHIDE);
  } else {
    declineOnboardingProfile(userOnboardingProfile.id);
  }
};

const OnboardingProfile = ({
  bankAccountCheckFR = mockFunction,
  bankAccountCheckUK = mockFunction,
  blocks = [],
  checkOnboardingProfileVetting = mockFunction,
  cleanUpTransientProps = mockFunction,
  confirmOnboardingProfileVetting = mockFunction,
  currentPage = '',
  declineOnboardingProfile = mockFunction,
  deleteUserAttachment = mockFunction,
  extendOnboardingProfileVetting = mockFunction,
  getAdminOnboardingProfiles = mockFunction,
  getCampaignsByClient = mockFunction,
  getClients = mockFunction,
  getReOnboardingCampaigns = mockFunction,
  getSigners = mockFunction,
  getUserOnboardingProfile = mockFunction,
  isOffline = false,
  onboardingProfilePages = [],
  onboardings = {},
  reactivateOnboardingProfile = mockFunction,
  readAttachment = mockFunction,
  rejectOnboardingProfileVetting = mockFunction,
  releaseBlock = mockFunction,
  removeAttachment = mockFunction,
  reOnboardProfile = mockFunction,
  resetOnboardingProfilePages = mockFunction,
  sendWelcomeEmail = mockFunction,
  sendWelcomeSms = mockFunction,
  transient = {},
  updateAdminProps = mockFunction,
  updateBadgeNumber = mockFunction,
  updateGrowlProps = mockFunction,
  updateOnboardingProfileDocuments = mockFunction,
  updateOnboardingUserProfile = mockFunction,
  updateOnboardingUserSection = mockFunction,
  updateTransientProps = mockFunction,
  updateTransientPropWithValidations = mockFunction,
  updateUserAvatar = mockFunction,
  updateUserDocument = mockFunction,
  user = {},
  user_role = '',
  userOnboardingProfile = {},
  vetProfile = mockFunction
}) => {
  const profileNotActivated = [
    ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE,
    ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS
  ];
  const currentIndex = onboardingProfilePages.indexOf(currentPage);

  const isFirstPage = currentIndex === 0;
  const isLastPage = currentIndex === onboardingProfilePages.length - 1;

  const prevPage = isFirstPage ? null : onboardingProfilePages[currentIndex - 1];
  const nextPage = isLastPage ? null : onboardingProfilePages[currentIndex + 1];

  const detailsPageOnly = onboardingProfilePages.filter(page => page === ONBOARDING_PROFILE_TABS.DETAILS);
  const displayablePages = profileNotActivated.includes(transient.state) ? detailsPageOnly : onboardingProfilePages;

  const hasRejectedFields = userOnboardingProfile?.rejected_fields?.length > 0;
  const hasRejectedDocuments = blocks.some(block =>
    block.onboarding_profile_documents.some(doc => doc.state === 'rejected')
  );

  const { countries, id, role_name } = user;
  const {
    block = '',
    checkVettingBannerContent = '',
    extendVettingBannerContent = '',
    filters = {},
    reactivateProfileBannerContent = '',
    rejectVettingBannerContent = '',
    releaseBlockBannerContent = '',
    reOnboardBannerContent = '',
    showCheckVettingBanner = false,
    showExtendVettingBanner = false,
    showReactivateProfileBanner = false,
    showRejectVettingBanner = false,
    showReleaseBlockBanner = false,
    showReOnbaordBanner = false,
    showWelcomeEmailBanner = false,
    showWelcomeSmsBanner = false,
    state = '',
    welcomeEmailBannerContent = '',
    welcomeSmsBannerContent = ''
  } = transient;

  const autocompleteDropdownAll = useRef(null);
  const isReadyForVetting = state === ONBOARDING_PROFILE_STATES.READY_FOR_VETTING;
  const isBlock4Released = onboardingProfilePages.includes(ONBOARDING_PROFILE_TABS.BLOCK_4);
  const showVetProfile =
    user_role !== ADMIN_ROLES.ADMIN &&
    ((isBlock4Released && currentPage === ONBOARDING_PROFILE_TABS.BLOCK_4) ||
      (!isBlock4Released && currentPage === ONBOARDING_PROFILE_TABS.BLOCK_3));

  const matchingNationality = user?.countries?.find(
    findMatchingNationality.bind(null, userOnboardingProfile?.user, 'nationality')
  );
  const matchingBirthCountry = user?.countries?.find(
    findMatchingCountry.bind(null, userOnboardingProfile?.user, 'country_of_birth')
  );
  const matchingCitizenship = user?.countries?.find(
    findMatchingCountry.bind(null, userOnboardingProfile?.user, 'country_of_citizenship')
  );

  useEffect(() => {
    getSigners(userOnboardingProfile.marketing_company.id);
    getClients();
  }, []);

  useEffect(() => {
    updateTransientProps({
      ...userOnboardingProfile?.user,
      country_of_birth: matchingBirthCountry,
      country_of_citizenship: matchingCitizenship,
      nationality: matchingNationality
    });
  }, []);

  useEffect(
    () => () => {
      updateAdminProps({ currentPage: ONBOARDING_PROFILE_TABS.DETAILS });
    },
    []
  );

  const renderBanner = () => {
    const banners = [
      {
        content: I18n.t('admin:successBannerMessages.welcomeEmail', {
          welcomeEmailBannerContent
        }),
        show: showWelcomeEmailBanner,
        onClose: updateTransientProps.bind(null, { showWelcomeEmailBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.welcomeSms', {
          welcomeSmsBannerContent
        }),
        show: showWelcomeSmsBanner,
        onClose: updateTransientProps.bind(null, { showWelcomeSmsBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.extendVetting', {
          extendVettingBannerContent
        }),
        show: showExtendVettingBanner,
        onClose: updateTransientProps.bind(null, { showExtendVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.checkVetting', {
          checkVettingBannerContent
        }),
        show: showCheckVettingBanner,
        onClose: updateTransientProps.bind(null, { showCheckVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.rejectVetting', {
          rejectVettingBannerContent
        }),
        show: showRejectVettingBanner,
        onClose: updateTransientProps.bind(null, { showRejectVettingBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.reactivateProfile', {
          reactivateProfileBannerContent
        }),
        show: showReactivateProfileBanner,
        onClose: updateTransientProps.bind(null, { showReactivateProfileBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.reOnboard', {
          reOnboardBannerContent
        }),
        show: showReOnbaordBanner,
        onClose: updateTransientProps.bind(null, { showReOnbaordBanner: false })
      },
      {
        content: I18n.t('admin:successBannerMessages.blockRelease', {
          releaseBlockBannerContent,
          blockNumber: block
        }),
        show: showReleaseBlockBanner,
        onClose: updateTransientProps.bind(null, { showReleaseBlockBanner: false })
      }
    ];

    const activeBanner = banners.find(banner => banner.show);

    const offlineBanner = {
      content: I18n.t('general:isOfflineBanner:descriptionAdmin'),
      show: isOffline,
      type: ALERT_TYPES.INFO,
      title: I18n.t('general:isOfflineBanner:title')
    };

    const onboardingBanner = {
      content: userOnboardingProfile?.info_message,
      show: !isOffline && !!userOnboardingProfile?.info_message,
      type: ALERT_TYPES.INFO
    };

    return (
      <>
        {activeBanner && (
          <Banner
            type={activeBanner.type || ALERT_TYPES.SUCCESS}
            title={activeBanner.title}
            content={activeBanner.content}
            show={activeBanner.show}
            timer={Config.GROWL_AUTOHIDE}
            onClose={activeBanner.onClose}
          />
        )}
        {!activeBanner && offlineBanner.show && (
          <Banner
            type={offlineBanner.type}
            title={offlineBanner.title}
            content={offlineBanner.content}
            show={offlineBanner.show}
            timer={Config.GROWL_AUTOHIDE}
          />
        )}
        {!activeBanner && onboardingBanner.show && (
          <Banner
            type={onboardingBanner.type}
            content={onboardingBanner.content}
            show={onboardingBanner.show}
            timer={Config.GROWL_AUTOHIDE}
          />
        )}
      </>
    );
  };

  const isAdmin = user?.role_name === ADMIN_ROLES.ADMIN;

  const bubbleActions = [
    {
      label: I18n.t('admin:menuItems.sendWelcomeEmail'),
      disabled:
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE &&
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS,
      onClick: updateTransientProps.bind(null, { showSendWelcomeMessageModal: true, type: 'email' })
    },
    {
      label: I18n.t('admin:menuItems.sendWelcomeSms'),
      disabled:
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_TO_SEND_WELCOME_MESSAGE &&
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.WAITING_FOR_TERMS,
      onClick: updateTransientProps.bind(null, { showSendWelcomeMessageModal: true, type: 'phone_number' })
    },
    {
      label: I18n.t('admin:menuItems.checkVetting'),
      hide: !isAdmin,
      disabled: userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL,
      onClick: checkOnboardingProfileVetting.bind(null, userOnboardingProfile.id)
    },
    {
      label: I18n.t('admin:menuItems.confirmVetting'),
      hide: !isAdmin,
      onClick: updateTransientProps.bind(null, { showConfirmVettingModal: true }),
      disabled:
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL &&
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW
    },
    {
      label: I18n.t('admin:menuItems.vetProfile'),
      hide: isAdmin,
      onClick: vetProfile.bind(null, userOnboardingProfile.id),
      disabled: !isReadyForVetting
    },
    {
      label: I18n.t('admin:menuItems.declineVetting'),
      onClick: handleDeclineVetting.bind(null, {
        declineOnboardingProfile,
        hasRejectedDocuments,
        hasRejectedFields,
        updateGrowlProps,
        userOnboardingProfile
      }),
      disabled: isAdmin
        ? userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL &&
          userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW
        : userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.PROFILE_IN_PROGRESS &&
          userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.READY_FOR_VETTING
    },
    {
      label: I18n.t('admin:menuItems.extendVetting'),
      onClick: extendOnboardingProfileVetting.bind(null, userOnboardingProfile.id)
    },
    {
      label: I18n.t('admin:menuItems.rejectVetting'),
      disabled:
        (!isAdmin &&
          (userOnboardingProfile.state == ONBOARDING_PROFILE_STATES.READY_FOR_APPROVAL ||
            userOnboardingProfile.state == ONBOARDING_PROFILE_STATES.VETTING_UNDER_REVIEW)) ||
        userOnboardingProfile.state == ONBOARDING_PROFILE_STATES.ONBOARDED ||
        userOnboardingProfile.state == ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED,
      onClick: rejectOnboardingProfileVetting.bind(null, userOnboardingProfile.id)
    },
    {
      label: I18n.t('admin:menuItems.reOnboard'),
      disabled: !userOnboardingProfile?.campaigns?.length,
      onClick: updateTransientProps.bind(null, { showProfileReonboardModal: true })
    },
    {
      label: I18n.t('admin:menuItems.reActivate'),
      disabled:
        userOnboardingProfile.state !== ONBOARDING_PROFILE_STATES.PROFILE_CANCELLED ||
        userOnboardingProfile.user.anonymized_at !== null,
      onClick: reactivateOnboardingProfile.bind(null, userOnboardingProfile.id)
    },
    {
      label: I18n.t('admin:menuItems.releaseBlock'),
      onClick: NavigationService.navigate.bind(null, {
        name: PAGE_NAMES.BULK_RELEASE_BLOCK
      })
    }
  ];

  const handleClick = ({
    cleanUpTransientProps = mockFunction,
    filters = {},
    getAdminOnboardingProfiles = mockFunction,
    onboardings = {},
    resetOnboardingProfilePages = mockFunction,
    updateAdminProps = mockFunction,
    updateTransientProps = mockFunction
  }) => {
    getAdminOnboardingProfiles({ ...filters, ...onboardings?.meta });
    NavigationService.goBack({ name: PAGE_NAMES.DASHBOARD });
    updateAdminProps({ currentPage: ONBOARDING_PROFILE_TABS.DETAILS, selectedUser: {} });
    updateTransientProps({ userOnboardingProfile: {}, client_name: '', campaign_name: '' });
    //pass the keys you want to keep on transient
    cleanUpTransientProps([
      'document_confirmation',
      'filteredAddressByPostCodes',
      'filters',
      'getAdminOnboardingProfilesLoading',
      'selectedMcPin',
      'selectedRows'
    ]);
    resetOnboardingProfilePages();
  };

  const { showDocumentDetails = false, selectedDocument = {}, nextBlock = '' } = transient;
  const blockNumber = parseInt(currentPage.replace('block', ''), 10);

  return (
    <Page
      className="w-full h-full"
      id={'onboarding-profile'}
      onPageAfterIn={initDropDown.bind(null, {
        autocompleteDropdownAll,
        updateTransientPropWithValidations,
        countries,
        userOnboardingProfile,
        transient,
        updateOnboardingUserProfile,
        updateTransientProps,
        updateUserChanges
      })}
      onPageBeforeRemove={removeDropDown.bind(null, autocompleteDropdownAll)}>
      <AppHeader
        hideAvatar={true}
        showMcPins={false}
        user
        actions={bubbleActions}
        goBackIcon={Exit}
        goBack
        childrenPaddingHorizontal={0}
        handleClick={handleClick.bind(null, {
          cleanUpTransientProps,
          getAdminOnboardingProfiles,
          filters,
          onboardings,
          resetOnboardingProfilePages,
          updateAdminProps,
          updateTransientProps
        })}
        title={userOnboardingProfile.user_name}
        banner={renderBanner()}>
        <ToolbarF7 className="h-30" position={'top'} tabbar scrollable noShadow noHairLine>
          {displayablePages?.map((page, index) => (
            <Link
              key={index}
              tabLink={currentPage}
              tabLinkActive={page === currentPage}
              onClick={updateAdminProps.bind(null, { currentPage: page })}>
              <span className={`tab-label ${page === currentPage && 'active'}`}>
                {I18n.t(`admin:onboardingProfilePages.${page}`)}
              </span>
            </Link>
          ))}
        </ToolbarF7>
      </AppHeader>
      <ColumnView>
        <Tabs className="w-full h-full">
          {displayablePages.map((page, index) => {
            const PageComp = pages[page] || pages[ONBOARDING_PROFILE_TABS.DETAILS];
            return (
              <Tab id={currentPage} key={index} tabActive={page === currentPage}>
                <PageComp
                  bankAccountCheckFR={bankAccountCheckFR}
                  bankAccountCheckUK={bankAccountCheckUK}
                  blocks={blocks}
                  currentPage={currentPage}
                  deleteUserAttachment={deleteUserAttachment}
                  getCampaignsByClient={getCampaignsByClient}
                  handleOnChangeWithValidations={handleOnChangeWithValidations}
                  isOffline={isOffline}
                  readAttachment={readAttachment}
                  releaseBlock={releaseBlock}
                  removeAttachment={removeAttachment}
                  role_name={role_name}
                  transient={transient}
                  updateBadgeNumber={updateBadgeNumber}
                  updateGrowlProps={updateGrowlProps}
                  updateOnboardingProfileDocuments={updateOnboardingProfileDocuments}
                  updateOnboardingUserProfile={updateOnboardingUserProfile}
                  updateOnboardingUserSection={updateOnboardingUserSection}
                  updateTransientProps={updateTransientProps}
                  updateTransientPropWithValidations={updateTransientPropWithValidations}
                  updateUserAvatar={updateUserAvatar}
                  updateUserDocument={updateUserDocument}
                  user_role={user_role}
                  userId={id}
                  userOnboardingProfile={userOnboardingProfile}
                />
              </Tab>
            );
          })}
        </Tabs>

        <ReleaseBlock
          blockNumber={currentPage}
          getCampaignsByClient={getCampaignsByClient}
          isOpen={transient?.[transient.modalKey] && transient?.releaseNextBlock}
          onClose={updateTransientProps.bind(null, {
            [transient.modalKey]: false,
            block: '',
            modalKey: '',
            nextBlock: '',
            releaseNextBlock: false
          })}
          releaseBlock={releaseBlock}
          showCampaign={(nextBlock !== '' && nextBlock == 3) || (blockNumber == 3 && nextBlock !== 4)}
          showClients={(nextBlock !== '' && nextBlock == 3) || (blockNumber == 3 && nextBlock !== 4)}
          showOptions={(nextBlock !== '' && nextBlock == 3) || (blockNumber == 3 && nextBlock !== 4)}
          showSigner={true}
          subtitle={`${I18n.t('admin:documentsWillBeSendTo')} ${transient?.userOnboardingProfile?.user_name}`}
          title={I18n.t('admin:releaseBlockNumber', { block: transient.block })}
          transient={transient}
          updateTransientProps={updateTransientProps}
        />

        <DocumentDetails
          popupKey={'showDocumentDetails'}
          selectedDocument={selectedDocument}
          showPopup={showDocumentDetails}
          updateTransientProps={updateTransientProps}
        />

        <SimpleModal
          confirmOnboardingProfileVetting={confirmOnboardingProfileVetting}
          getReOnboardingCampaigns={getReOnboardingCampaigns}
          getUserOnboardingProfile={getUserOnboardingProfile}
          reOnboardProfile={reOnboardProfile}
          sendWelcomeEmail={sendWelcomeEmail}
          sendWelcomeSms={sendWelcomeSms}
          transient={transient}
          updateTransientProps={updateTransientProps}
          updateTransientPropWithValidations={updateTransientPropWithValidations}
        />

        {showVetProfile && renderVetProfile(!isReadyForVetting, vetProfile.bind(null, userOnboardingProfile.id))}
        {renderReleaseNextBlock(currentPage, blocks, userOnboardingProfile, updateTransientProps)}
        {!profileNotActivated.includes(transient.state) &&
          renderOnboardingProfileNavButtons(isFirstPage, isLastPage, prevPage, nextPage, updateAdminProps)}
      </ColumnView>
    </Page>
  );
};

OnboardingProfile.propTypes = {
  bankAccountCheckFR: PropTypes.func,
  bankAccountCheckUK: PropTypes.func,
  blocks: PropTypes.array,
  checkOnboardingProfileVetting: PropTypes.func,
  cleanUpTransientProps: PropTypes.func,
  confirmOnboardingProfileVetting: PropTypes.func,
  currentPage: PropTypes.string,
  declineOnboardingProfile: PropTypes.func,
  deleteUserAttachment: PropTypes.func,
  extendOnboardingProfileVetting: PropTypes.func,
  getAdminOnboardingProfiles: PropTypes.func,
  getCampaignsByClient: PropTypes.func,
  getClients: PropTypes.func,
  getReOnboardingCampaigns: PropTypes.func,
  getSigners: PropTypes.func,
  getUserOnboardingProfile: PropTypes.func,
  isOffline: PropTypes.bool,
  onboardingProfilePages: PropTypes.array,
  onboardings: PropTypes.object,
  reactivateOnboardingProfile: PropTypes.func,
  readAttachment: PropTypes.func,
  rejectOnboardingProfileVetting: PropTypes.func,
  releaseBlock: PropTypes.func,
  removeAttachment: PropTypes.func,
  reOnboardProfile: PropTypes.func,
  resetOnboardingProfilePages: PropTypes.func,
  sendWelcomeEmail: PropTypes.func,
  sendWelcomeSms: PropTypes.func,
  transient: PropTypes.object,
  updateAdminProps: PropTypes.func,
  updateBadgeNumber: PropTypes.func,
  updateGrowlProps: PropTypes.func,
  updateOnboardingProfileDocuments: PropTypes.func,
  updateOnboardingUserProfile: PropTypes.func,
  updateOnboardingUserSection: PropTypes.func,
  updateTransientProps: PropTypes.func,
  updateTransientPropWithValidations: PropTypes.func,
  updateUserAvatar: PropTypes.func,
  updateUserDocument: PropTypes.func,
  user_role: PropTypes.string,
  user: PropTypes.object,
  userOnboardingProfile: PropTypes.object,
  vetProfile: PropTypes.func
};

export default OnboardingProfile;
