import { connect } from 'react-redux';

import { Creators as transientActions } from 'Reducers/transient';

import Table from './view';

export default connect(
  state => ({
    ...state.transient
  }),
  {
    updateTransientProps: transientActions.updateProps
  }
)(Table);
