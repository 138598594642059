import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'framework7-react';

import { I18n } from 'Locales';
import { TextField } from 'Components';
import { ColumnView, RowView } from 'Containers';

const ConfirmVetting = ({ transient = {} }) => {
  const uniqueCampaigns = transient?.campaigns?.filter((value, index, self) => self.indexOf(value) === index);

  return (
    <>
      <ColumnView justifyContent="flex-start">
        <RowView>
          <div className="title-md font-bold">{I18n.t('admin:actions.confirmVetting')}</div>
        </RowView>
        <ColumnView justifyContent="flex-start" paddingTop={16}>
          <List noHairlines className="w-full">
            <TextField
              className="w-full"
              value={transient.client_name}
              label={I18n.t('admin:reOnboardModal.client')}
              disabled={true}
              type="text"
            />

            <RowView justifyContent={'flex-start'} gap={4} paddingTop={4} paddingBottom={16} flexWrap={'wrap'}>
              {uniqueCampaigns?.map((option, index) => (
                <div key={index} className="tag">
                  {option?.name}
                </div>
              ))}
            </RowView>
          </List>
        </ColumnView>
      </ColumnView>
    </>
  );
};

ConfirmVetting.propTypes = {
  transient: PropTypes.object
};

export default ConfirmVetting;
