import PropTypes from 'prop-types';
import { getDevice } from 'framework7';
import { Page } from 'framework7-react';
import React, { useEffect } from 'react';

import { I18n } from 'Locales';
import { FinanceGuy } from 'Icons';
import { Button } from 'Components';
import { mockFunction } from 'Helpers';
import { NavigationService } from 'Services';
import { ColumnView, RowView } from 'Containers';

import './style.scss';
import InstallModal from './InstallModal';

const Finish = ({ transient = {}, updateTransientProps = mockFunction }) => {
  const device = getDevice();
  const { install_modal = false } = transient;

  const determineInstallModal = (userDevice, updateTransientProps) => {
    if (userDevice.android) {
      updateTransientProps({ install_modal: true });
    }
    if (userDevice.ios || userDevice.ipad) {
      alert(I18n.t('general:installIos'));
    }
  };

  useEffect(() => {
    determineInstallModal(device, updateTransientProps);
  }, []);

  return (
    <Page>
      <ColumnView height={'100%'} justifyContent={'center'} paddingHorizontal={20}>
        <ColumnView height={'70%'} paddingBottom={32}>
          <RowView>
            <ColumnView>
              <img src={FinanceGuy} className="mb-32" />
              <div className="swiper-title">{I18n.t('general:readyToRock')}</div>
              <div className="swiper-subtitle pt-24">{I18n.t('general:subtitleReadyToRock')}</div>
            </ColumnView>
          </RowView>
        </ColumnView>
        <RowView>
          <Button.Primary width={'100%'} onClick={NavigationService.clearURL}>
            {I18n.t('general:goToProfile')}
          </Button.Primary>
        </RowView>
      </ColumnView>

      <InstallModal install_modal={install_modal} updateTransientProps={updateTransientProps} />
    </Page>
  );
};

Finish.propTypes = {
  transient: PropTypes.object,
  updateTransientProps: PropTypes.func
};

export default Finish;
