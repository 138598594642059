import React from 'react';
import PropTypes from 'prop-types';

const Admin = ({ fillColor = '' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill={fillColor}
      stroke="#747474"
      strokeWidth="0.2"
      //eslint-disable-next-line no-useless-escape, max-len
      d="M17 17c.417 0 .771-.146 1.063-.437.292-.291.438-.646.437-1.063 0-.417-.146-.77-.437-1.062A1.45 1.45 0 0 0 17 14c-.417 0-.77.146-1.062.438A1.452 1.452 0 0 0 15.5 15.5c0 .417.146.771.438 1.063.292.292.646.438 1.062.437Zm0 3c.53 0 1.008-.118 1.435-.353.427-.235.78-.553 1.06-.953a4.82 4.82 0 0 0-1.195-.519A4.802 4.802 0 0 0 17 18c-.45 0-.883.058-1.3.175a4.78 4.78 0 0 0-1.194.52c.28.4.633.717 1.06.952.426.235.904.353 1.434.353Zm-4.809.891a.61.61 0 0 1-.386.01c-1.922-.615-3.526-1.812-4.812-3.59C5.664 15.473 5 13.403 5 11.1V6.386a1 1 0 0 1 .65-.937l6-2.241a1 1 0 0 1 .7 0l6 2.241a1 1 0 0 1 .65.937v4.445c0 .25-.251.426-.494.367a.665.665 0 0 1-.506-.644V7.076a1 1 0 0 0-.651-.937l-5-1.86a1 1 0 0 0-.697 0l-5 1.859A1 1 0 0 0 6 7.075V11.1c0 .989.146 1.935.438 2.84.291.905.689 1.738 1.192 2.498a9.047 9.047 0 0 0 1.785 1.99c.682.563 1.41.994 2.182 1.293.01.004.022.004.032 0a.047.047 0 0 1 .059.027 3.688 3.688 0 0 0 .56.92c.063.075.035.191-.057.223ZM17 21c-1.114 0-2.06-.388-2.836-1.164C13.387 19.06 12.999 18.115 13 17c0-1.114.388-2.06 1.164-2.836.776-.777 1.721-1.165 2.836-1.164 1.114 0 2.06.388 2.836 1.164.777.776 1.165 1.721 1.164 2.836 0 1.114-.388 2.06-1.164 2.836-.776.777-1.721 1.165-2.836 1.164Z"
    />
  </svg>
);

Admin.propTypes = {
  fillColor: PropTypes.string
};

export default Admin;
