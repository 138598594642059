import { connect } from 'react-redux';

import { Creators as userActions } from 'Reducers/user';
import { Creators as adminActions } from 'Reducers/admin';
import { Creators as transientActions } from 'Reducers/transient';

import SearchAddressList from './view';

export default connect(
  state => ({
    ...state.transient
  }),
  {
    getPostCodes: userActions.getPostCodes,
    getUKAddress: userActions.getUKAddress,
    updateOnboardingUserProfile: adminActions.updateOnboardingUserProfile,
    updateTransientProps: transientActions.updateProps,
    updateUser: userActions.updateApiModel
  }
)(SearchAddressList);
